import React, { ChangeEvent } from 'react';
import { Field } from 'formik';
import styles from './styles.module.css';
import { CheckboxType } from './types';
import { FieldRenderProps } from '../types';

export const Checkbox: React.FC<CheckboxType> = ({
  label,
  name,
  validate,
  onChange,
  defaultChecked = false,
  ...inputProps
}) => {
  const renderField: React.FC<FieldRenderProps> = ({ field }) => (
    <div className={styles.checkbox} data-testid={field.name}>
      <input
        {...field}
        {...inputProps}
        data-testid={`${field.name}_input`}
        type="checkbox"
        id={field.name}
        className={styles.input}
        defaultChecked={defaultChecked}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          field.onChange(e);
          if (onChange) {
            onChange(e);
          }
        }}
      />
      <label
        data-testid={`${field.name}_label`}
        htmlFor={field.name}
        className={styles.label}
      >
        <div className={styles.icon}>
          <div className={styles.box} />
          <div className={styles.check} />
        </div>
        {label}
      </label>
    </div>
  );

  return (
    <div className={styles.group} data-testid="checkbox">
      <Field name={name} validate={validate}>
        {renderField}
      </Field>
    </div>
  );
};
