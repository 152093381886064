import React from 'react';

export const Choice = () => (
  <svg
    width="81"
    height="31"
    viewBox="0 0 81 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M38.4171 15.4604V20.5932H36.3739V15.4604C36.3739 14.9017 36.1734 14.4219 35.7725 14.0218C35.3715 13.6218 34.8866 13.4218 34.3165 13.4218C33.7465 13.4218 33.2605 13.6218 32.8605 14.0218C32.4596 14.4219 32.2591 14.9017 32.2591 15.4604V20.5932H30.2017V8.55157H32.2591V11.9186C32.9061 11.5519 33.5926 11.369 34.3165 11.369C35.4465 11.369 36.4134 11.7691 37.2153 12.5692C38.0172 13.3692 38.4182 14.333 38.4182 15.4614L38.4171 15.4604Z"
      fill="#4D5258"
    />
    <path
      d="M47.8243 19.2416C46.9201 20.1427 45.8317 20.5933 44.556 20.5933C43.2802 20.5933 42.1918 20.1427 41.2886 19.2416C40.3855 18.3405 39.9339 17.2535 39.9339 15.9817C39.9339 14.7099 40.3855 13.6229 41.2886 12.7208C42.1918 11.8197 43.2812 11.3691 44.556 11.3691C45.8307 11.3691 46.9201 11.8197 47.8243 12.7208C48.7275 13.6219 49.179 14.7088 49.179 15.9817C49.179 17.2546 48.7275 18.3405 47.8243 19.2416ZM42.7446 17.789C43.2468 18.29 43.8502 18.5405 44.556 18.5405C45.2617 18.5405 45.8651 18.29 46.3673 17.789C46.8695 17.2879 47.1206 16.6858 47.1206 15.9817C47.1206 15.2776 46.8695 14.6614 46.3673 14.1603C45.8651 13.6592 45.2617 13.4087 44.556 13.4087C43.8502 13.4087 43.2468 13.6592 42.7446 14.1603C42.2424 14.6614 41.9913 15.2685 41.9913 15.9817C41.9913 16.6949 42.2424 17.2879 42.7446 17.789Z"
      fill="#4D5258"
    />
    <path
      d="M50.7558 11.4869H53.0015V20.5545H52.6856C51.6204 20.5545 50.7558 19.6918 50.7558 18.6291V11.4869Z"
      fill="#4D5258"
    />
    <path
      d="M26.1668 17.5857C25.3649 18.6879 23.8188 18.9323 22.7142 18.1323C21.6095 17.3322 21.3645 15.7896 22.1664 14.6875C22.9683 13.5853 24.5144 13.3409 25.619 14.1409C25.8296 14.2935 26.0139 14.4784 26.1668 14.6875H28.5877C27.7858 12.2519 25.1563 10.9265 22.7152 11.7266C20.274 12.5266 18.9456 15.1501 19.7475 17.5857C20.5494 20.0213 23.1789 21.3467 25.62 20.5466C27.0244 20.086 28.126 18.9869 28.5877 17.5857H26.1668Z"
      fill="#4D5258"
    />
    <path
      d="M61.3961 17.5857C60.5942 18.6879 59.0481 18.9323 57.9434 18.1323C56.8388 17.3322 56.5938 15.7896 57.3957 14.6875C58.1976 13.5853 59.7437 13.3409 60.8483 14.1409C61.0589 14.2935 61.2432 14.4784 61.3961 14.6875H63.8169C63.015 12.2519 60.3856 10.9265 57.9445 11.7266C55.5033 12.5266 54.1749 15.1501 54.9768 17.5857C55.7787 20.0213 58.4082 21.3467 60.8493 20.5466C62.2537 20.086 63.3552 18.9869 63.8169 17.5857H61.3961Z"
      fill="#4D5258"
    />
    <path
      d="M74.5484 16.0751C74.5484 13.6476 72.6428 11.4929 69.9374 11.4929C67.232 11.4929 65.3083 13.5395 65.3083 16.1297C65.3083 18.7199 67.3232 20.7675 70.0103 20.7675C72.1163 20.7675 73.8589 19.6078 74.4218 17.8511H71.8075C71.4805 18.4309 70.791 18.6845 69.9921 18.6845C68.8672 18.7007 67.9013 17.8905 67.7231 16.7833H74.4947C74.5322 16.5499 74.5504 16.3136 74.5494 16.0762L74.5484 16.0751ZM67.8122 15.0609C68.0481 14.1184 68.9199 13.5032 69.9364 13.5032C70.953 13.5032 71.8065 14.1376 72.0424 15.0609H67.8122Z"
      fill="#4D5258"
    />
    <path
      d="M13.2008 14.0428C12.0405 14.0428 10.952 14.6449 10.3324 15.6208H10.3303L10.2726 15.7167C9.92331 16.3006 9.28443 16.6623 8.60605 16.6623C7.53584 16.6623 6.66509 15.7935 6.66509 14.7257C6.66509 13.6579 7.53584 12.7892 8.60605 12.7892C9.24089 12.7892 9.83725 13.1013 10.2007 13.6246L10.2463 13.6892H11.844L11.762 13.4822C11.2467 12.181 10.0084 11.3395 8.60605 11.3395C6.73495 11.3395 5.21317 12.8578 5.21317 14.7247C5.21317 16.5916 6.73495 18.1099 8.60605 18.1099C9.80384 18.1099 10.9166 17.4745 11.5261 16.4481L11.5565 16.3986C11.9149 15.8289 12.5295 15.4894 13.2008 15.4894C14.271 15.4894 15.1417 16.3582 15.1417 17.426C15.1417 18.4938 14.271 19.3625 13.2008 19.3625C12.5659 19.3625 11.9696 19.0504 11.6061 18.5271L11.5605 18.4625H9.9628L10.0448 18.6695C10.5602 19.9707 11.7985 20.8122 13.2008 20.8122C15.0719 20.8122 16.5937 19.2939 16.5937 17.427C16.5937 15.5601 15.0719 14.0418 13.2008 14.0418V14.0428Z"
      fill="#4D5258"
    />
  </svg>
);
