import React, { ChangeEvent, useState, useEffect, useContext } from 'react';
import styles from './styles.module.css';
import { Optional } from 'V2/Types';
import { PaymentContext } from '../PaymentContext';
import { PiiIgnore } from 'V2/Shared/PiiIgnore';
import { FieldError } from 'V2/Shared/FieldError';
import amex from 'V2/Shared/Assets/amex.svg';
import discover from 'V2/Shared/Assets/discover.svg';
import mastercard from 'V2/Shared/Assets/mastercard.svg';
import visa from 'V2/Shared/Assets/visa.svg';

export type PaymentInputProps = {
  autoFocus?: boolean;
  autoComplete?: string;
  pattern?: string;
  detailKey: string;
  value: Optional<string>;
  small?: boolean;
  mustMatch?: any;
  normalize?: (value: string) => Optional<string>;
  validate?: (value: string) => Optional<string>;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  icon?: string;
  stepName?: string;
};

export const PaymentInput: React.FC<PaymentInputProps> = ({
  autoFocus,
  validate,
  autoComplete = 'on',
  onChange,
  detailKey,
  value,
  mustMatch,
  icon,
  stepName,
}) => {
  const [error, setError] = useState<Optional<string>>();

  const { isSubmitting } = useContext(PaymentContext);

  // Whenever the matching payment input changes
  // we will run validations on this payment input
  // this is because the visible errors on this input
  // need to be affected by a separate payment input.
  // For example if this input is verifyAccountNumber and
  // the user makes a change to the actual accountNumber input
  // we want to update validations here as well.
  useEffect(() => {
    if ((mustMatch || isSubmitting) && validate) {
      setError(validate(value || ''));
    }
  }, [mustMatch, validate, value, isSubmitting]);

  const labelMap = (key: string): string => {
    switch (key) {
      case 'holderName':
        return 'Name on Card';

      case 'number':
        return 'Card Number';

      case 'expiryMonth':
        return 'Expiration Month';

      case 'expiryYear':
        return 'Expiration Year';

      case 'cvc':
        return 'CVC';

      case 'cvv':
        return 'CVV';

      case 'card_holder_name':
        return 'Card Holder Name';

      case 'address_zip':
        return 'Zip Code';

      case 'expiration':
        return 'Expiration';

      case 'card_number':
        return 'Card Number';

      case 'ownerName':
      case 'account_holder_name':
        return 'Name on Account';

      case 'bankLocationId':
      case 'routing_number':
        return 'Routing Number';

      case 'bankAccountNumber':
      case 'account_number':
        return 'Account Number';

      case 'verifyAccountNumber':
        return 'Verify Account Number';

      default:
        return '';
    }
  };

  const inputTypeMap = (key: string): string => {
    switch (key) {
      case 'number':
      case 'cvc':
      case 'cvv':
      case 'postalCode':
      case 'address_zip':
        return 'number';

      case 'card_number':
      case 'bankLocationId':
      case 'bankAccountNumber':
        return 'tel';

      default:
        return 'text';
    }
  };

  const cardMap: {
    [key: string]: string;
  } = {
    visa: visa,
    discover: discover,
    'master-card': mastercard,
    'american-express': amex,
  };

  return (
    <div className={styles.inputContainer}>
      <div className={styles.group}>
        <PiiIgnore>
          <input
            type={inputTypeMap(detailKey)}
            value={value || ''}
            data-testid={`${detailKey}-input`}
            autoComplete={autoComplete}
            onFocus={() => setError(undefined)}
            pattern={inputTypeMap(detailKey) === 'tel' ? '[0-9*]' : ''}
            onBlur={(e) => {
              if (validate) {
                setError(validate(e.target.value));
              }
            }}
            className={styles.input}
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
              const invalidChars = ['-', '+', 'e']; // <-- These are allowed in number inputs by default.
              if (
                inputTypeMap(detailKey) === 'number' &&
                invalidChars.includes(e.key.toLowerCase())
              ) {
                e.preventDefault();
              }
            }}
            onChange={onChange}
            placeholder=" "
            autoFocus={autoFocus}
          />
          <label className={styles.paymentInputLabel}>
            {labelMap(detailKey)}
          </label>
          {icon && (
            <img
              src={cardMap[icon]}
              alt={icon}
              className={styles.icon}
              data-testid="card-icon"
            />
          )}
        </PiiIgnore>
      </div>
      {error && (
        <div data-testid={`${detailKey}-error`} className={styles.error}>
          <FieldError field={detailKey} message={error}>
            {error}
          </FieldError>
        </div>
      )}
    </div>
  );
};
