import React, { FC } from 'react';
import styles from './styles.module.css';
import classNames from 'classnames';

export type DesktopSubmitButtonWrapperType = {
  isVisibleOverride?: boolean;
  'data-testid'?: string;
  className?: string;
};

export const DesktopSubmitButtonWrapper: FC<DesktopSubmitButtonWrapperType> = ({
  children,
  isVisibleOverride,
  className,
  'data-testid': dataTestid = 'step-submit',
}) => {
  const potentialOverride =
    isVisibleOverride !== undefined
      ? isVisibleOverride
        ? styles.visible
        : styles.hidden
      : null;

  return (
    <div
      data-testid={dataTestid}
      className={classNames(styles.base, className, potentialOverride)}
    >
      {children}
    </div>
  );
};
