import React from 'react';
import styles from './styles.module.css';

type TextType = {
  className?: string;
};

export const Text: React.FC<TextType> = ({ children, className }) => (
  <div className={className ? className : styles.text}>{children}</div>
);
