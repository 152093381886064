declare global {
  interface Window {
    env: EnvRaw;
  }
}

export type EnvRaw = {
  HOST: string;
  NODE_ENV: string;
  PORT: string;
  REACT_APP_API_HOST: string;
  REACT_APP_FULLSTORY_ID: string;
  REACT_APP_FULLSTORY_ENABLED: string;
  REACT_APP_GOOGLE_ANALYTICS_ID: string;
  REACT_APP_HEAP_ID: string;
  REACT_APP_HEAP_ENABLED: string;
  REACT_APP_DATA_DOG_ID: string;
  REACT_APP_DATA_DOG_CLIENT_TOKEN: string;
  REACT_APP_CLEARCOVER_ENV: string;
  REACT_APP_TM_ENABLED: string;
  REACT_APP_TM_ORG_ID: string;
  REACT_APP_TM_PROFILING_DOMAIN: string;
  REACT_APP_WEBSITE_HOST: string;
  REACT_APP_GOOGLE_TAG_MANAGER_ID: string;
  REACT_APP_AUTH0_DOMAIN: string;
  REACT_APP_AUTH0_CLIENT_ID: string;
  REACT_APP_AUTH0_REDIRECT_URL: string;
  REACT_APP_AUTH0_AUDIENCE: string;
  REACT_APP_PORTAL_HOST: string;
  REACT_APP_AGENT_AUTH: 'enabled' | 'disabled';
  REACT_APP_ADYEN_ENVIRONMENT: string;
  REACT_APP_ADYEN_VERSION: string;
  REACT_APP_ADYEN_CLIENT_KEY: string;
  REACT_APP_ADYEN_CARD_AVS_MODE: 'none' | 'partial' | 'full';
  REACT_APP_ADYEN_CSS_HASH: string;
  REACT_APP_ADYEN_SCRIPT_HASH: string;
  REACT_APP_OPTIMIZELY_ID: string;
  REACT_APP_OPTIMIZELY_SDK_KEY: string;
  REACT_APP_PROCESS_ONE_BASE_URL: string;
  REACT_APP_RECAPTCHA_SITEKEY: string;
  REACT_APP_RECAPTCHA: 'enabled' | 'disabled';
  REACT_APP_SPUR_MONOCLE_TOKEN: string;
  REACT_APP_PARTNER_AUTH_TOKEN: string;
  REACT_APP_TOYOTA_GOOGLE_TAG_MANAGER_ID: string;
  REACT_APP_FORMOTIV_API_HOST: string;
  REACT_APP_FORMOTIV_USER_ID: string;
  REACT_APP_FORMOTIV_ENABLED: string;
  REACT_APP_ALLOWED_PHONE_NUMBERS: string;
  REACT_APP_SMARTYSTREETS_ID: string;
};

type AdyenEnv = {
  adyenEnvironment: string;
  adyenVersion: string;
  adyenClientKey: string;
  adyenCssHash: string;
  adyenScriptHash: string;
  adyenCardAvsMode: 'none' | 'partial' | 'full';
};

export type Env = {
  host: string;
  nodeEnv: string;
  port: string;
  apiHost: string;
  fullstoryId: string;
  fullstoryEnabled: string;
  googleAnalyticsId: string;
  heapId: string;
  heapEnabled: string;
  dataDogId: string;
  dataDogClientToken: string;
  clearcoverEnv: string;
  tmEnabled: string;
  tmOrgId: string;
  tmProfilingDomain: string;
  websiteHost: string;
  googleTagManagerId: string;
  auth0Domain: string;
  auth0ClientId: string;
  auth0RedirectUrl: string;
  auth0Audience: string;
  portalHost: string;
  agentAuth: 'enabled' | 'disabled';
  optimizelyId: string;
  optimizelySdkKey: string;
  partnerAuthToken?: string;
  processOneBaseUrl: string;
  recaptchaSiteKey?: string;
  recaptcha: 'enabled' | 'disabled';
  spurMonocleToken: string;
  toyotaGoogleTagManagerId: string;
  formotivUserId: string;
  formotivApiHost: string;
  formotivEnabled: string;
  allowedPhoneNumbers?: string;
  smartystreetsId?: string;
} & AdyenEnv;

let defaultAdyenTestConfig: AdyenEnv = {
  adyenEnvironment: 'test',
  adyenCardAvsMode: 'none',
  adyenClientKey: '',
  adyenCssHash:
    'sha384-k6qYnmoHaucEm97eQQAs3MK5e44JQ2sksrue2tfdDOnnedKzc0VYQwxzdYWZu8Mj',
  adyenScriptHash:
    'sha384-FJWX32BY0zx3KKed9gdiWxoEAEsA3uh1ixchmdkflgtcDo+SoYg5ZD6uqvDmnafO',
  adyenVersion: '5.51.0',
};

export const defaultEnvs: Env = {
  host: 'app.localhost',
  nodeEnv: 'development',
  port: '3001',
  apiHost: 'http://api.localhost:3000',
  fullstoryId: 'JEECH',
  fullstoryEnabled: '',
  googleAnalyticsId: 'UA-98123156-2',
  heapId: '998809699',
  heapEnabled: '',
  dataDogId: '6d49ee3a-3417-4f69-8fc0-0e4948cc7700',
  dataDogClientToken: 'pube40929926d01844695cecd943e80ae4c',
  clearcoverEnv: 'development',
  tmEnabled: '',
  tmOrgId: 'THREATMETRIX_ORG_ID',
  tmProfilingDomain: 'THREATMETRIX',
  websiteHost: 'https://clearcoverstaging.com',
  googleTagManagerId: 'GTM-WQHRHHJ',
  auth0Domain: 'clearcover-dev.auth0.com',
  auth0ClientId: 'PvymEEzPLjAMXvEWmvQYqPIzjY52uBCn',
  auth0RedirectUrl: 'http://app.localhost:3001/agent/apply',
  auth0Audience: 'http://api.localhost:3000',
  portalHost: 'http://app.localhost:3002',
  agentAuth: 'disabled',
  ...defaultAdyenTestConfig,
  optimizelyId: '18786673271',
  optimizelySdkKey: 'YWwzPankWfZKxARuuoujpJ',
  partnerAuthToken:
    'eyJhbGciOiJIUzI1NiJ9.eyJ0b2tlbiI6IjZjNGQ0OWY1LWRkMWEtNGJiMy1iNzIzLWVhZjdiZDkzNTY0MCJ9.appK2qABJYlSB0aSOgvXFJKAdFRjrcg838iIAarGfi4',
  processOneBaseUrl: 'https://testportalone.processonepayments.com',
  recaptchaSiteKey: '6LdDHA8pAAAAABDTdnc6lY7YISbhuDZFrcCQMgkE',
  recaptcha: 'disabled',
  spurMonocleToken: '1234',
  toyotaGoogleTagManagerId: 'G-BGMS6GLS9M',
  formotivApiHost: 'https://api.test.formotiv.com',
  formotivUserId: '7f02c33e-8afe-45d8-ac4c-5397e7e56201',
  formotivEnabled: '0',
  smartystreetsId: '',
};

export const defaults: { [envName: string]: Env } = {
  local: { ...defaultEnvs },
  staging: {
    ...defaultEnvs,
    apiHost: 'https://api.clearcoverstaging.com',
    clearcoverEnv: 'staging',
    partnerAuthToken:
      'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbiI6IjE5MTU5YjI5LTVkYWUtNGMxZC05YTJlLTcyMjE2NWMzOTgwYSJ9.sHq0EvM5M7aDdo9j1tjjB5q4GZZRhkoYcsX7saTXVWs',
  },
  uat: {
    ...defaultEnvs,
    apiHost: 'https://api.clearcoveruat.com',
    clearcoverEnv: 'uat',
    partnerAuthToken:
      'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbiI6IjZjNGQ0OWY1LWRkMWEtNGJiMy1iNzIzLWVhZjdiZDkzNTY0MCJ9.3WBPko46fSCV9tmJU2YG_jl0lcoqopatAi707whngQw',
  },
  partner: {
    ...defaultEnvs,
    apiHost: 'https://api.clearcoverpartner.com',
    clearcoverEnv: 'partner',
    partnerAuthToken:
      'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbiI6IjQ1ZmRjM2E1LTJlMGUtNDYzOS04Nzc2LTJhODNkOGUzZmY2MiJ9.dlfB4UlvY0TK6h7McTn-H5Ccb30PGMLTq-hbL8Rh-qI',
  },
};

const ephemeralBaseEnvironment = process.env.REACT_APP_EPHEMERAL_BASE_ENV
  ? defaults[process.env.REACT_APP_EPHEMERAL_BASE_ENV]
  : defaults.staging;

defaults.ephemeral = {
  ...ephemeralBaseEnvironment,
  apiHost: `${process.env.REACT_APP_REZNOR_URL}`,
  clearcoverEnv: 'ephemeral',
  partnerAuthToken:
    'eyJhbGciOiJIUzI1NiJ9.eyJ0b2tlbiI6IjZjNGQ0OWY1LWRkMWEtNGJiMy1iNzIzLWVhZjdiZDkzNTY0MCJ9.uoAdr8FtdtrZ0R0-Ix8ykcqEDqzUe2QLKKPDN3ZTJNw',
};

// Allows npm commands to determine which backend to use
// npm start -> runs against local backend
// npm run start:staging -> runs against staging backend
// npm run start:uat -> runs against uat backend
// npm run start:partner -> runs against partner backend
// npm run start:ephemeral -> runs against ephemeral backend
const activeEnvironment =
  process.env.REACT_APP_BACKEND &&
  defaults.hasOwnProperty(process.env.REACT_APP_BACKEND)
    ? defaults[process.env.REACT_APP_BACKEND]
    : defaults.local;

export const env: Env = window.env
  ? {
      port: window.env.PORT,
      host: window.env.HOST,
      nodeEnv: window.env.NODE_ENV,
      apiHost: window.env.REACT_APP_API_HOST,
      fullstoryId: window.env.REACT_APP_FULLSTORY_ID,
      fullstoryEnabled: window.env.REACT_APP_FULLSTORY_ENABLED,
      googleAnalyticsId: window.env.REACT_APP_GOOGLE_ANALYTICS_ID,
      heapId: window.env.REACT_APP_HEAP_ID,
      heapEnabled: window.env.REACT_APP_HEAP_ENABLED,
      dataDogId: window.env.REACT_APP_DATA_DOG_ID,
      dataDogClientToken: window.env.REACT_APP_DATA_DOG_CLIENT_TOKEN,
      clearcoverEnv: window.env.REACT_APP_CLEARCOVER_ENV,
      tmEnabled: window.env.REACT_APP_TM_ENABLED,
      tmOrgId: window.env.REACT_APP_TM_ORG_ID,
      tmProfilingDomain: window.env.REACT_APP_TM_PROFILING_DOMAIN,
      websiteHost: window.env.REACT_APP_WEBSITE_HOST,
      googleTagManagerId: window.env.REACT_APP_GOOGLE_TAG_MANAGER_ID,
      auth0Domain: window.env.REACT_APP_AUTH0_DOMAIN,
      auth0ClientId: window.env.REACT_APP_AUTH0_CLIENT_ID,
      auth0RedirectUrl: window.env.REACT_APP_AUTH0_REDIRECT_URL,
      auth0Audience: window.env.REACT_APP_AUTH0_AUDIENCE,
      portalHost: window.env.REACT_APP_PORTAL_HOST,
      agentAuth: window.env.REACT_APP_AGENT_AUTH,
      adyenEnvironment: window.env.REACT_APP_ADYEN_ENVIRONMENT,
      adyenVersion: window.env.REACT_APP_ADYEN_VERSION,
      adyenClientKey: window.env.REACT_APP_ADYEN_CLIENT_KEY ?? '',
      adyenCardAvsMode: window.env.REACT_APP_ADYEN_CARD_AVS_MODE || 'none',
      adyenCssHash: window.env.REACT_APP_ADYEN_CSS_HASH,
      adyenScriptHash: window.env.REACT_APP_ADYEN_SCRIPT_HASH,
      optimizelyId: window.env.REACT_APP_OPTIMIZELY_ID,
      optimizelySdkKey: window.env.REACT_APP_OPTIMIZELY_SDK_KEY,
      processOneBaseUrl: window.env.REACT_APP_PROCESS_ONE_BASE_URL,
      recaptchaSiteKey: window.env.REACT_APP_RECAPTCHA_SITEKEY,
      recaptcha: window.env.REACT_APP_RECAPTCHA,
      spurMonocleToken: window.env.REACT_APP_SPUR_MONOCLE_TOKEN,
      partnerAuthToken: window.env.REACT_APP_PARTNER_AUTH_TOKEN,
      toyotaGoogleTagManagerId:
        window.env.REACT_APP_TOYOTA_GOOGLE_TAG_MANAGER_ID,
      formotivApiHost: window.env.REACT_APP_FORMOTIV_API_HOST || '',
      formotivUserId: window.env.REACT_APP_FORMOTIV_USER_ID || '',
      formotivEnabled: window.env.REACT_APP_FORMOTIV_ENABLED || '',
      allowedPhoneNumbers: window.env.REACT_APP_ALLOWED_PHONE_NUMBERS,
      smartystreetsId: window.env.REACT_APP_SMARTYSTREETS_ID,
    }
  : activeEnvironment;
