import React, { useState } from 'react';

import { TextInputProps } from './types';
import styles from './styles.module.css';

export const BaseInput: React.FC<TextInputProps> = ({
  value,
  label,
  name,
  autoFocus,
  placeholder,
  pattern,
  type = 'text',
  autoComplete = 'on',
  leftIcon,
  onChange,
  onFocus,
  onBlur,
  disabled,
}) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <div className={styles.group}>
      <div className={styles.inputWrapper} data-testid={name}>
        {leftIcon}
        <input
          id={name}
          data-testid="input"
          disabled={disabled}
          type={type}
          autoComplete={autoComplete}
          value={value}
          onBlur={() => {
            setIsFocused(false);

            if (onBlur) {
              onBlur();
            }
          }}
          onFocus={(e) => {
            if (onFocus) {
              onFocus(e);
            }

            setIsFocused(true);
          }}
          className={styles.input}
          pattern={pattern}
          onChange={onChange}
          placeholder={isFocused ? placeholder : ' '}
          autoFocus={autoFocus}
        />
        <label htmlFor={name} data-testid="label">
          {label}
        </label>
      </div>
    </div>
  );
};
