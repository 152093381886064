export const vehicle_vin_match = {
  policy: {
    term_in_months: 6,
    coverages: {
      bodily_injury: {
        limit_key: 'twenty_five_fifty',
      },
      property_damage: {
        limit_key: 'five_thousand',
      },
      uninsured_motorist_bodily_injury: {
        limit_key: 'twenty_five_fifty',
      },
      underinsured_motorist_bodily_injury: {
        limit_key: 'twenty_five_fifty',
      },
      first_party_benefits: {
        package_key: 'opt_ee',
      },
      limited_tort: {
        waived: false,
      },
      extraordinary_medical_benefit: {
        limit_key: 'one_million_one_hundred_thousand',
      },
    },
    mailing_address: {
      line_one: '526 Eagle Dr',
      city: 'East Stroudsburg',
      state: 'PA',
      zip_code: '18302',
    },
  },
  quote_metadata: {
    campaign_medium: 'Lorem ad exercitation',
    campaign_name: 'ABC123',
    quote_identifier: '54asdf54asd5f4a5s4df',
    quote_name: 'Basic',
    partner_unstructured_metadata: {},
  },
  vehicles: [
    {
      vehicle_identification: {
        partial_vin: '1HGCR2F83E',
      },
      mileage: {
        annual: 18000,
      },
      ownership_type: 'owned',
      vehicle_usage: 'commute',
      is_used_for_rideshare: true,
      anti_theft: ['none'],
      vehicle_coverages: {
        comprehensive: {
          deductible_key: 'one_hundred',
        },
        collision: {
          deductible_key: 'one_hundred',
        },
        rental: {
          limit_key: 'fifty_fifteen_hundred',
        },
        roadside: {
          waived: false,
        },
        rideshare: {
          waived: false,
        },
        uninsured_motorist_property_damage: {
          waived: true,
        },
      },
    },
  ],
  current_policy: {
    proof_of_insurance: true,
    carrier_name: 'state_farm',
    state: 'CO',
    policy_start_date: '2020-01-01',
    policy_end_date: '2020-01-01',
    term_in_months: 6,
    customer_loyalty_in_months: 120,
    policy: {
      coverages: {
        bodily_injury: {
          limit_enum: 'fifty_one_hundred',
        },
      },
    },
    insurance_status: {
      is_currently_insured: true,
    },
    drivers: [
      {
        driver_information: {
          first_name: 'Reymundo',
          middle_name: 'QAMock',
          last_name: 'Ruecker',
          relationship_to_applicant: 'applicant',
        },
      },
    ],
  },
  drivers: [
    {
      exclude_from_quote: false,
      contact_information: {
        email_address: 'acceptance_test+{{$guid}}@clearcover.com',
        phone_numbers: [
          {
            number: '5555555555',
            type: 'home',
          },
        ],
      },
      confirmed_no_accidents: true,
      confirmed_no_violations: true,
      driver_information: {
        first_name: 'Reymundo',
        middle_name: 'QAMock',
        last_name: 'Ruecker',
        date_of_birth: '1971-04-13',
        gender: 'male',
        marital_status: 'single',
        relationship_to_applicant: 'applicant',
      },
      current_education: {
        education_level_clearcover_key: 'bachelors_degree',
      },
      license: {
        region: 'usa',
        age_first_licensed: 16,
        status: 'active',
        state: 'WI',
        number: 'D2224217041801',
        source: 'user_reported',
        has_previous_suspension: false,
      },
      homeownership: {
        homeowner: true,
        months_owned: 16,
      },
      traffic_school: {
        course_taken: false,
      },
      occupation: {
        occupation_clearcover_key:
          'Nursing Instructors and Teachers, Postsecondary',
      },
      military_service: {
        is_deployed: false,
        service_member: true,
        military_grade: 'O-4',
      },
    },
  ],
};

export const tx_complete_lead_no_choice_quotes = {
  policy: {
    term_in_months: 6,
    mailing_address: {
      line_one: '937 MANOR DR',
      city: 'DESOTO',
      state: 'TX',
      zip_code: '75115',
    },
  },
  vehicles: [
    {
      vehicle_identification: {
        vin: '3HGGK5G52HM705903',
      },
      mileage: {
        annual: 10000,
      },
      vehicle_usage: 'pleasure',
      ownership_type: 'owned',
      is_used_for_rideshare: false,
      anti_theft: ['none'],
    },
    {
      vehicle_identification: {
        vin: '1G2JC14H5P7540709',
      },
      mileage: {
        annual: 10000,
      },
      vehicle_usage: 'pleasure',
      ownership_type: 'owned',
      is_used_for_rideshare: false,
      anti_theft: ['none'],
    },
    {
      vehicle_identification: {
        vin: 'SCEDT26T8BD005261',
      },
      mileage: {
        annual: 10000,
      },
      vehicle_usage: 'pleasure',
      ownership_type: 'owned',
      is_used_for_rideshare: false,
      anti_theft: ['none'],
    },
  ],
  drivers: [
    {
      contact_information: {
        email_address: `acceptance_test+tx.${Date.now()}@clearcover.com`,
      },
      confirmed_no_accidents: true,
      confirmed_no_violations: true,
      driver_information: {
        first_name: 'David',
        last_name: 'Woods',
        date_of_birth: '1971-04-13',
        gender: 'male',
        marital_status: 'single',
        relationship_to_applicant: 'applicant',
      },
      current_education: {
        education_level_clearcover_key: 'bachelors_degree',
      },
      license: {
        region: 'usa',
        age_first_licensed: 16,
        state: 'WI',
        number: 'D2224217041801',
        source: 'user_reported',
        has_previous_suspension: false,
      },
      homeownership: {
        homeowner: true,
        months_owned: 16,
      },
      traffic_school: {
        course_taken: true,
        completion_date: '2017-01-08',
      },
      occupation: {
        occupation_clearcover_key: 'Actuary',
      },
      military_service: {
        service_member: true,
        is_deployed: false,
        military_grade: 'O-4',
      },
    },
  ],
};

export const la_uw_stop = {
  policy: {
    term_in_months: 6,
    coverages: {
      bodily_injury: {
        limit_key: 'fifty_one_hundred',
      },
      property_damage: {
        limit_key: 'one_hundred_thousand',
      },
      personal_injury_protection: {
        package_key: 'mand_min',
      },
      medical_payments: {
        limit_key: 'one_thousand',
      },
      uninsured_motorist_bodily_injury: {
        limit_key: 'fifty_one_hundred',
      },
      underinsured_motorist_bodily_injury: {
        limit_key: 'fifty_one_hundred',
      },
    },
    mailing_address: {
      line_one: '7530 CHAD DR',
      line_two: 'APT D',
      city: 'ADDIS',
      state: 'LA',
      zip_code: '70710',
    },
  },
  quote_metadata: {
    campaign_medium: 'Lorem ad exercitation',
    campaign_name: 'ABC123',
    quote_identifier: '54asdf54asd5f4a5s4df',
    quote_name: 'Basic',
    partner_unstructured_metadata: {},
  },
  vehicles: [
    {
      vehicle_identification: {
        vin: '5FNRL5H64GB111411',
      },
      mileage: {
        annual: 18000,
      },
      ownership_type: 'owned',
      vehicle_usage: 'commute',
      is_used_for_rideshare: false,
      anti_theft: ['none'],
      vehicle_coverages: {
        comprehensive: {
          deductible_key: 'one_hundred',
        },
        collision: {
          deductible_key: 'one_hundred',
        },
        rental: {
          limit_key: 'fifty_fifteen_hundred',
        },
        roadside: {
          waived: false,
        },
        rideshare: {
          waived: true,
        },
        uninsured_motorist_property_damage: {
          waived: true,
        },
      },
    },
  ],
  current_policy: {
    proof_of_insurance: true,
    carrier_name: 'state_farm',
    state: 'CO',
    policy_start_date: '2017-09-01',
    policy_end_date: '2018-05-01',
    term_in_months: 6,
    customer_loyalty_in_months: 120,
    policy: {
      coverages: {
        bodily_injury: {
          limit_enum: 'fifty_one_hundred',
        },
      },
    },
    insurance_status: {
      is_currently_insured: true,
    },
    drivers: [
      {
        driver_information: {
          first_name: 'Linda',
          last_name: 'Campbell',
          relationship_to_applicant: 'applicant',
        },
      },
    ],
  },
  drivers: [
    {
      exclude_from_quote: false,
      contact_information: {
        email_address: 'acceptance_test.la.hardstop.{{$guid}}@clearcover.com',
        phone_numbers: [
          {
            number: '5555555555',
            type: 'home',
          },
        ],
      },
      confirmed_no_accidents: true,
      confirmed_no_violations: true,
      driver_information: {
        first_name: 'Linda',
        middle_name: 'K',
        last_name: 'Campbell',
        date_of_birth: '1952-06-02',
        gender: 'female',
        marital_status: 'single',
        relationship_to_applicant: 'applicant',
      },
      current_education: {
        education_level_clearcover_key: 'bachelors_degree',
      },
      license: {
        region: 'usa',
        age_first_licensed: 16,
        status: 'active',
        state: 'LA',
        number: '592646',
        source: 'user_reported',
        has_previous_suspension: false,
      },
      homeownership: {
        homeowner: true,
        months_owned: 16,
      },
      traffic_school: {
        course_taken: false,
      },
      occupation: {
        occupation_clearcover_key:
          'Nursing Instructors and Teachers, Postsecondary',
      },

      military_service: {
        is_deployed: false,
        service_member: true,
        military_grade: 'O-4',
      },
    },
  ],
};
