import React, { useState, useMemo } from 'react';
import { DescriptionType, StepConfigType } from 'V2/Types';
import { Modal, Text, Controls } from 'V2/Shared/Modal';
import { Help } from 'V2/Shared/Icons/Help';
import styles from './styles.module.css';
import { Button } from 'V2/Shared/Button';
import classNames from 'classnames';
import informationIcon from 'V2/Shared/Assets/icon-information.svg';

// TODO: find a less abstract way to handle step
// Title and SubTitle text; its too configurable
// for 1% of the time; we are using Title/SubTitle
// directly for the PhoneNumber step since we know their
// aren't any special configurations for title/sub-title
export const Title: React.FC = ({ children }) => (
  <div className={styles.item}>
    <div className={styles.title}>{children}</div>
  </div>
);

export const SubTitle: React.FC = ({ children }) => (
  <div className={styles.subTitle}>{children}</div>
);

export const InfoSection: React.FC<{
  config: StepConfigType;
  cssOverrides?: {
    infoButton?: string;
  };
}> = ({
  config: { title_text: title, step_descriptions: descriptions },
  cssOverrides = {},
}) => {
  const warnings = useMemo(
    () => descriptions.filter((d) => d.type.id === 'warning'),
    [descriptions]
  );
  const info = useMemo(
    () => descriptions.filter((d) => d.type.id === 'info'),
    [descriptions]
  );

  return (
    <div data-testid="info-section">
      <h3 className={styles.title} data-testid="nq-title">
        {title}
      </h3>
      <div className={styles.item}>
        {info.map((item, i) => {
          const warning = warnings.find((d) => d.type.sub_type === 'info');

          if (item.type.sub_type === 'info_modal') {
            return (
              <RenderInfoModal
                key={`info-modal-${i}`}
                index={i}
                cssOverrides={cssOverrides}
                item={item}
              />
            );
          } else if (warning) {
            return (
              <RenderInfoWarningModal
                key={`info-modal-${i}`}
                index={i}
                warning={warning}
                cssOverrides={cssOverrides}
                item={item}
              />
            );
          } else {
            return renderInfo(i, item);
          }
        })}
      </div>
    </div>
  );
};

function renderInfo(i: number, item: DescriptionType): React.JSX.Element {
  return (
    <p className={styles.subTitle} key={i} data-testid="nq-subtitle">
      {item.label.replace(/\\n/g, '\n')}
    </p>
  );
}

const RenderInfoWarningModal: React.FC<{
  index: number;
  warning: DescriptionType;
  cssOverrides?: {
    infoButton?: string;
  };
  item: DescriptionType;
}> = ({ warning, index, cssOverrides, item }) => {
  const [showModal, setShowModal] = useState(false);
  const chat = warning.ctas.find((c) => c.type.id === 'chat_contact');

  return (
    <div key={index} data-testid={`info-section-${index}`}>
      <button
        className={classNames(styles.subTitle, cssOverrides?.infoButton)}
        type="button"
        onClick={() => setShowModal(true)}
        data-testid={`info-section-open-modal-${index}`}
      >
        {item.label}
        <Help alt="More Info" className="ml-8" testId="info" />
      </button>
      <Modal
        title={warning.label}
        showModal={showModal}
        closeAction={() => setShowModal(false)}
      >
        <Text>{warning.description}</Text>
        <Controls>
          <Button
            variant={chat ? 'outlined' : 'contained'}
            size="md"
            onClick={() => setShowModal(false)}
          >
            {chat ? 'Cancel' : 'Got It'}
          </Button>
        </Controls>
      </Modal>
    </div>
  );
};

const RenderInfoModal: React.FC<{
  index: number;
  cssOverrides?: {
    infoButton?: string;
  };
  item: DescriptionType;
}> = ({ index, cssOverrides, item }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <div key={index} data-testid={`info-section-${index}`}>
      <button
        className={classNames(
          styles.subTitle,
          cssOverrides?.infoButton,
          styles.customInfoModal
        )}
        type="button"
        onClick={() => setShowModal(true)}
        data-testid={`info-section-open-modal-${index}`}
      >
        <div className={classNames(styles.centerContent)}>
          <img
            src={informationIcon}
            alt="Information"
            data-testid="Information"
            className={classNames(styles.customIcon)}
          />
          {item.label}
        </div>
      </button>
      <Modal
        title="Who should be added to your policy?"
        showModal={showModal}
        closeAction={() => setShowModal(false)}
        width="600px"
      >
        <Text>
          <p>
            To make sure you have the right coverage when you need it, add the
            following people to you policy:
          </p>
          <ul>
            <li>
              <b>Household members</b> - anyone who lives with you that drives
              the vehicle(s) you wish to insure.
            </li>
            <li>
              <b>Registered owners</b> - anyone who is registered as an owner or
              co-owner of the vehicles you wish to insure.
            </li>
            <li>
              <b>Regular drivers</b> - anyone who uses your vehicle(s) for 30
              days or more in a 12-month period, even if they don't live with
              you.
            </li>
            <li>
              <b>Drivers with vehicle access</b> - anyone who has possession of
              (or access to) a set of keys to your vehicle(s), with the ability
              to use the vehicle(s) at their discretion.
            </li>
          </ul>
          <p>
            <b>Examples to consider:</b> relatives, caregivers, partners,
            friends.
          </p>
          <p>
            <i>Not sure if you should add someone?</i> Start the process, and
            we'll guide you through a few questions and assign each driver
            accordingly. We may give some drivers a "list-only" status, which
            provides them coverage on your policy without impacting your premium
            (policy cost).
          </p>
        </Text>
        <Controls>
          <Button size="md" onClick={() => setShowModal(false)}>
            Continue
          </Button>
        </Controls>
      </Modal>
    </div>
  );
};
