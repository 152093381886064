import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import close from 'V2/Shared/Assets/times-white.svg';
import styles from './styles.module.css';

declare global {
  interface Document {
    documentMode?: any;
  }
}

const isIE11 = () => !!window.MSInputMethodContext && !!document.documentMode;

export const IEBanner: React.FC = () => {
  const [isActive, setIsActive] = useState(
    isIE11() && localStorage.getItem('ieBannerDismissed') !== 'true'
  );

  return (
    <AnimatePresence>
      {isActive && (
        <motion.div
          key="banner"
          className={styles.banner}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          The internet browser you’re using is out of date. For the best
          experience, we recommend a modern browser like Chrome or Firefox.
          <button
            data-testid="ie-banner"
            className={styles.close}
            onClick={() => {
              setIsActive(false);
              localStorage.setItem('ieBannerDismissed', 'true');
            }}
          >
            <img src={close} alt="close" />
          </button>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
