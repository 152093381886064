import React, { useState, useEffect, ChangeEvent, useMemo } from 'react';
import Fuse from 'fuse.js';
import styles from './styles.module.css';
import { BaseInput } from 'V2/Shared/Form/BaseInput';
import { Card } from 'V2/Shared/Card';
import { OptionType } from 'V2/Types';
import { TypeaheadOptionsType, TypeaheadPropsType } from './types';
import { TextHighlighter } from 'V2/Shared/TextHighlighter';

const DEFAULT_MIN_INPUT_LENGTH = 3;

export const Typeahead: React.FC<TypeaheadPropsType> = ({
  name,
  label,
  options,
  autoFocus,
  customOption,
  currentValue,
  leftIcon,
  showUseOption = true,
  minInputLength = DEFAULT_MIN_INPUT_LENGTH,
  onSelect,
  onChange,
  setError,
  ...inputProps
}) => {
  const [index, setIndex] = useState<number>(-1);
  const [inputValue, setInputValue] = useState<string>('');
  const [isActive, setIsActive] = useState<boolean>(false);
  const [results, setResults] = useState<TypeaheadOptionsType>([]);

  const hasCustomOption = !!customOption;

  const transformedOptions = useMemo(() => {
    return options.map((option) => ({
      ...option,
      label: option.label,
      value: option.label,
    }));
  }, [options]);

  const fuse = new Fuse(transformedOptions, {
    shouldSort: true,
    threshold: 0.6,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    keys: ['label'],
  });

  const findOptionLabelByOptionValue = (optionValue: string) => {
    const option = options.find((option) => option.value === optionValue);

    return option?.label as string;
  };

  const findOptionValueByOptionLabel = (optionLabel: string) => {
    const option = options.find((option) => option.label === optionLabel);

    return option?.value as string;
  };

  const onCustomOptionSelect = (option: string) => {
    setIsActive(false);
    onSelect({
      label: option,
      value: option,
      isCustomValue: true,
    });
    setInputValue(customOption?.label ?? option);
  };

  const onSelectSearchValue = (option: OptionType) => {
    setIsActive(false);
    onSelect({
      label: option.label,
      value: findOptionValueByOptionLabel(option.value as string),
      isCustomValue: false,
    });
    setInputValue(option.label);
  };

  useEffect(() => setIndex(-1), [isActive, results]);

  useEffect(() => {
    if (currentValue) {
      const optionLabel = findOptionLabelByOptionValue(currentValue as string);

      const isCustomOptionSelected =
        hasCustomOption && currentValue === customOption?.value;

      if (hasCustomOption && isCustomOptionSelected && !!customOption?.label) {
        setInputValue(customOption.label);
        return;
      }

      setInputValue(optionLabel || (currentValue as string));
    }
  }, [currentValue, hasCustomOption]);

  useEffect(() => {
    // Every time the input value changes, we update the results
    // the use of the fuse library allows us to search for the input value
    // in the list of options and return the results that match
    setResults(
      inputValue.length >= minInputLength
        ? fuse.search(inputValue).slice(0, 4)
        : []
    );
  }, [inputValue, transformedOptions]);

  const onKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const key: string = e.key;

    if (
      isActive &&
      (key === 'Enter' || key === 'ArrowUp' || key === 'ArrowDown')
    ) {
      e.preventDefault();
      switch (key) {
        case 'ArrowUp':
          setIndex((index) => (index <= 0 ? 0 : index - 1));
          break;

        case 'ArrowDown':
          setIndex((index) => (index === results.length ? index : index + 1));
          break;

        case 'Enter':
          if (index === results.length) {
            setIsActive(false);
            onCustomOptionSelect(
              !!hasCustomOption ? (customOption?.value as string) : inputValue
            );
          } else if (index !== -1) {
            setIsActive(false);
            onSelectSearchValue(results[index]);
          }
          break;

        default:
          break;
      }
    }
  };

  const renderCustomOption = (isActive: boolean) => {
    if (hasCustomOption === false) return null;

    return (
      <li className={isActive ? styles.active : ''} data-testid="default">
        <button
          type="button"
          data-testid="button"
          onClick={() => onCustomOptionSelect(customOption?.value as string)}
        >
          {customOption?.label}
        </button>
      </li>
    );
  };

  const renderUseOption = (isActive: boolean) => {
    return (
      <li className={isActive ? styles.active : ''} data-testid="use">
        <button
          type="button"
          data-testid="button"
          onClick={() => onCustomOptionSelect(inputValue)}
        >
          <span>+</span>
          Use {inputValue}
        </button>
      </li>
    );
  };

  // Current styles are coupled to the 'Use' option.
  // To prevent breaking the layout, we render a hidden option
  // when the 'Use' option is not available.
  const renderHiddenOption = () => {
    return (
      <li hidden aria-hidden="true">
        <button type="button">
          <span></span>
        </button>
      </li>
    );
  };

  return (
    <div
      onKeyDown={onKeyPress}
      className={styles.typeahead}
      data-testid="typeahead"
    >
      <input
        {...inputProps}
        hidden
        readOnly
        name={name}
        value={(currentValue as string) || ''}
      />
      <BaseInput
        {...inputProps}
        label={label}
        name={`${name}-input`}
        value={inputValue}
        autoComplete="off"
        autoFocus={autoFocus}
        leftIcon={leftIcon}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          const val = e.target.value;
          setInputValue(val);
          setIsActive(val.length >= minInputLength);

          if (onChange) {
            onChange(e);
          }

          if (setError && !!currentValue && val?.length === 0) {
            setError('This field is required');
          }
        }}
      />
      {isActive && (
        <div className={styles.results}>
          <Card>
            <ul>
              {results.length > 0 &&
                results.map((result: OptionType, resultIndex: number) => {
                  return (
                    <li
                      key={resultIndex}
                      className={index === resultIndex ? styles.active : ''}
                      data-testid={resultIndex}
                    >
                      <button
                        type="button"
                        data-testid="button"
                        onClick={() => onSelectSearchValue(result)}
                      >
                        <TextHighlighter
                          hightlight={inputValue}
                          fromText={result.description ?? result.label}
                          renderHighlightedText={(text) => (
                            <span className={styles.matched}>{text}</span>
                          )}
                        />
                      </button>
                    </li>
                  );
                })}
              {hasCustomOption && renderCustomOption(index === results.length)}
              {!hasCustomOption &&
                showUseOption &&
                renderUseOption(index === results.length)}
              {!hasCustomOption && !showUseOption && renderHiddenOption()}
            </ul>
          </Card>
        </div>
      )}
    </div>
  );
};
