import React from 'react';
import { useQuery } from 'V2/Hooks';
import { useHistory } from 'react-router-dom';
import { Button } from 'V2/Shared/Button';
import styles from './styles.module.css';
import { Close } from 'V2/Shared/Icons/Close';

export const Header: React.FC = () => {
  const query = useQuery();
  const history = useHistory();

  return (
    <div className={styles.container}>
      <div className={styles.right} />
      <div className={styles.text}>You’re currently in edit mode.</div>
      <div className={styles.cta}>
        {query.get('stepName') ? (
          <button
            data-testid="edit-close"
            onClick={() => {
              query.delete('stepName');
              history.push({ search: query.toString() });
            }}
            className={`cc-edit__close-button ${styles.iconButton}`}
          >
            <Close fillClassName="fill-brand-03" />
          </button>
        ) : (
          <Button
            className="cc-edit__button"
            variant="contained"
            color="secondary"
            isOnDarkBackground={true}
            size="sm"
            data-testid="edit-button"
            onClick={() => {
              history.push({ search: '' });
            }}
          >
            Done
          </Button>
        )}
      </div>
    </div>
  );
};
