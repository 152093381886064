import React, { useEffect, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormikHelpers } from 'formik';
import { useApi } from 'V2/Api';
import {
  ConsentDisclosureSummariesType,
  DetailType,
  StatusType,
} from 'V2/Types';
import { useQuery } from 'V2/Hooks';
import { buildFields, buildFieldDataPayload } from 'V2/Step';
import {
  useSummary,
  useApplyProvider,
  useSubmit,
  OnSubmitStepType,
  FormValuesType,
} from 'V2/Apply';
import { FullPageLoading } from 'V2/Shared/FullPageLoading';
import {
  slideLeft,
  slideRight,
  TransitionDuration,
} from 'V2/Shared/Animations';
import { StepForm } from 'V2/Shared/StepForm';
import { DynamicStepLoader } from 'V2/Shared/DynamicStepLoader';
import { ConsentDisclosure } from 'V2/Shared/ConsentDisclosure';
import { EditContext } from '../EditProvider';
import { EditContextType } from '../types';
import styles from './styles.module.css';
import { ValidationModal } from '../../Shared/ValidationModal';

export const Step: React.FC = () => {
  const history = useHistory();
  const query = useQuery();
  const { isAnimating, setIsAnimating, app } = useApplyProvider();
  const { drivers, vehicles, coverages } = useSummary();
  const { getResource, postResource, getStatus, setError } = useApi();
  const entityId = query.get('entityId');
  const stepName = query.get('stepName');
  const {
    step: { status, config, fields },
    setStep,
    statuses,
    setStatuses,
    setAnimation,
  } = useContext<EditContextType>(EditContext);
  const consentDisclosures = config.features_by_key.consent_disclosures;

  const [formActions, setFormActions] = useState<
    FormikHelpers<FormValuesType> | undefined
  >();

  useEffect(() => {
    setAnimation(slideRight);

    return () => {
      setAnimation(slideLeft);
    };
  }, [setAnimation]);

  useEffect(() => {
    const entity = [...drivers, ...vehicles, ...coverages].find(
      (e) => e.id === entityId
    );

    if (statuses.length < 1 && entity && entity.detail_uri) {
      getResource({ url: `${entity.detail_uri}&include_details=true` }).then(
        (response) => {
          const status = response.data.groups.find(
            (s: StatusType) => s.id === stepName
          );

          setStep({
            status: status.step_status,
            config: status.step_details,
            fields: buildFields(
              status.step_status.field_data,
              status.step_details
            ),
          });

          setStatuses(response.data.groups);
        }
      );
    }
  }, [
    entityId,
    stepName,
    statuses,
    drivers,
    vehicles,
    coverages,
    setStep,
    setStatuses,
    getResource,
  ]);

  const {
    validate,
    validation,
    resetValidation,
    setStep: setApplyStep,
  } = useSubmit();

  const onSubmitStep: OnSubmitStepType = (values, formActions) => {
    const {
      validation_uri: validationUri,
      current_step_submit_uri: submitUri,
    } = status;
    const payload = buildFieldDataPayload(status.field_data, values);

    setFormActions(formActions);

    validate(validationUri, payload, formActions)
      .then(() =>
        postResource({
          url: submitUri,
          data: {
            field_data: payload,
          },
        })
          // always call latest of status to:
          // 1. check if the user needs to be kicked out of edit mode
          // 2. always set the latest step/config/data in the background
          .then(() =>
            getStatus().then(({ data: status }) => {
              const { step_details: config, summary } = status;

              // if apply flow has changed due to data, we want to
              // kick them out of edit and continue through the flow
              if (app.status.id !== status.id) {
                setIsAnimating(false);

                setApplyStep({
                  status,
                  config,
                  summary,
                });
              }
              // set apply step in the background, omit routing out of edit,
              // and close edit step and go back to summary
              else {
                setApplyStep(
                  {
                    status,
                    config,
                    summary,
                  },
                  true
                );

                const entity = [...drivers, ...vehicles].find(
                  (e) => e.id === entityId
                )!;

                if (entity && entity.detail_uri) {
                  getResource({
                    url: `${entity.detail_uri}&include_details=true`,
                  }).then((detailResponse: { data: DetailType }) => {
                    setStatuses(detailResponse.data.groups);
                    setIsAnimating(false);
                    setAnimation(slideRight);

                    query.delete('stepName');
                    history.push({
                      search: query.toString(),
                    });
                  });
                }
              }
            })
          )
          .catch((error) => {
            setIsAnimating(false);
            setError(error);
          })
      )
      .catch(() => {
        setIsAnimating(false);
      });
  };

  const loader =
    stepName === 'coverages' ? (
      <FullPageLoading />
    ) : (
      <StepForm>
        <div className={styles.loading1} />
        <div className={styles.loading2} />
        <div className={styles.loading2} />
      </StepForm>
    );

  return (
    <>
      {status.id ? (
        <DynamicStepLoader
          onSubmitStep={onSubmitStep}
          status={status}
          config={config}
          fields={fields}
          summary={app.summary}
          isSubmitting={isAnimating}
          hasConsentDisclosure={!!consentDisclosures}
        >
          {!!consentDisclosures &&
            Object.entries(consentDisclosures).map(([key, value]) => (
              <ConsentDisclosure
                key={`${key}`}
                className={`${key}`}
                disclosure={value as ConsentDisclosureSummariesType[]}
              />
            ))}
        </DynamicStepLoader>
      ) : (
        loader
      )}
      <ValidationModal
        validation={validation}
        resetValidation={resetValidation}
        handleClick={(action) => {
          return postResource({
            url: status.current_step_submit_uri,
            data: {
              field_data: action.field_data,
            },
          }).then(() => {
            getStatus()
              .then((response) => {
                const entity = [...drivers, ...vehicles].find(
                  (e) => e.id === entityId
                )!;

                if (entity && entity.detail_uri) {
                  // Please view Apply.tsx to understand the
                  // logic here. The logic is a little different
                  // than the apply flow. For example: values
                  // should never be null in edit mode.
                  if (
                    action.field_data.every((f) => f.value === null) &&
                    formActions
                  ) {
                    formActions.resetForm();
                    setIsAnimating(false);
                    formActions.validateForm();
                    return;
                  }

                  return getResource({ url: entity.detail_uri }).then(
                    (detailResponse: { data: DetailType }) => {
                      setStatuses(detailResponse.data.groups);
                      setIsAnimating(false);
                      setAnimation(slideRight);

                      setTimeout(() => {
                        query.delete('stepName');
                        history.push({
                          search: query.toString(),
                        });
                      }, TransitionDuration.milliseconds);
                    }
                  );
                }
              })
              .catch((error) => {
                setIsAnimating(false);
                setError(error);
              });
          });
        }}
      />
    </>
  );
};
