export const baseVehicle = {
  vehicle_identification: {
    vin: '5FNRL5H64GB111411',
  },
  mileage: {
    annual: 18000,
  },
  ownership_type: 'owned',
  vehicle_usage: 'commute',
  is_used_for_rideshare: true,
  anti_theft: ['none'],
};

export const partialVin = {
  vehicle_identification: {
    partial_vin: '1HGCR2F83E',
  },
  mileage: {
    annual: 18000,
  },
  ownership_type: 'owned',
  vehicle_usage: 'commute',
  is_used_for_rideshare: true,
  anti_theft: ['none'],
  vehicle_coverages: {
    comprehensive: {
      deductible_key: 'one_hundred',
    },
    collision: {
      deductible_key: 'one_hundred',
    },
    rental: {
      limit_key: 'fifty_fifteen_hundred',
    },
    roadside: {
      waived: false,
    },
    rideshare: {
      waived: false,
    },
    uninsured_motorist_property_damage: {
      waived: true,
    },
  },
};

export const commute = {
  vehicle_identification: {
    vin: '1G2JC14H5P7540709',
  },
  mileage: {
    annual: 10000,
  },
  vehicle_usage: 'commute',
  ownership_type: 'owned',
  is_used_for_rideshare: false,
  anti_theft: ['none'],
};

export const pleasure = {
  vehicle_identification: {
    vin: '3HGGK5G52HM705903',
  },
  mileage: {
    annual: 10000,
  },
  vehicle_usage: 'pleasure',
  ownership_type: 'owned',
  is_used_for_rideshare: false,
  anti_theft: ['none'],
};
