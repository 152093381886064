import { Heap, FullStory, Datadog, DataLayer } from 'V2/Analytics';

export const identify = (id: string) => {
  Heap.identify(id);
  FullStory.identify(id);
  Datadog.identify(id);

  DataLayer.push({
    event: 'marketing_id',
    marketing_id: id,
  });
};
