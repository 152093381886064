import React, { ChangeEvent } from 'react';
import { Field } from 'formik';
import styles from './styles.module.css';
import { FieldRenderProps } from '../types';
import { CheckboxButtonType } from './types';
import cx from 'classnames';

export const CheckboxButton: React.FC<CheckboxButtonType> = ({
  label,
  description,
  name,
  defaultChecked,
  onChange,
  'data-testid': dataTestId,
  className,
  ...inputProps
}) => (
  <div
    data-testid={`${name} checkbox-button ${dataTestId}`}
    className={cx(styles.checkbox, className)}
  >
    <Field name={name}>
      {({ field, form }: FieldRenderProps) => {
        return (
          <>
            <input
              {...field}
              {...inputProps}
              data-testid="checkbox"
              id={name}
              className={styles.input}
              type="checkbox"
              defaultChecked={defaultChecked}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                field.onChange(e);
                if (onChange) {
                  onChange(e);
                }
              }}
            />
            <label
              className={styles.labelContainer}
              htmlFor={name}
              data-testid="label"
            >
              <div className={styles.box} />
              <div className={styles.label}>
                {label}
                {description && (
                  <div className={styles.description}>{description}</div>
                )}
              </div>
            </label>
          </>
        );
      }}
    </Field>
  </div>
);
