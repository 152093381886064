import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';

import { Page, Title, Text } from 'V2/Shared/SplashPageAssets';
import { DesktopSubmitButtonWrapper } from 'V2/Shared/DesktopSubmitButtonWrapper';
import { MobileSubmitButtonWrapper } from 'V2/Shared/MobileSubmitButtonWrapper';
import { Button } from 'V2/Shared/Button';
import { Logo } from 'V2/Shared/Logo';
import { FullPageLoading } from '../FullPageLoading';
import { useFeature, useQuery } from 'V2/Hooks';
import { WhiteLabelStatusData } from 'V2/Types';
import { env } from 'constants/env';
import { useTheme } from '../ThemeProvider';
import { useIsMobile } from '../Helpers';

import styles from './styles.module.css';

const ButtonGroup = styled.div`
  margin-top: 64px;

  & > * {
    margin-bottom: 32px;
  }
  & > *:last-child {
    margin-bottom: 0;
  }
`;

const defaultPhoneLink = <a href="tel:1-855-444-1875">(855) 444-1875</a>;
const toyotaPhoneLink = <a href="tel:1-866-474-8855">(866) 474-8855</a>;

export const ErrorScreen = () => {
  const { setTheme } = useTheme();
  const isMobile = useIsMobile();
  const query = useQuery();
  const [showButtons, setShowButtons] = useState<boolean>(true);
  const [isEnabled, variables, isLoading] = useFeature(
    'error_screen_text_maintenance_screen'
  );

  useEffect(() => {
    const whiteLabel = JSON.parse(
      sessionStorage.getItem('white_label') ?? '{}'
    ) as WhiteLabelStatusData;

    setTheme(whiteLabel);

    if (whiteLabel.is_choice_flow) {
      setShowButtons(false);
    }
  }, [setTheme]);

  const onReturnHomeClicked = () => (window.location.href = env.websiteHost);

  const isRecaptchaError = ['recaptcha', 'monocle'].includes(
    String(query.get('errorType'))
  );
  const isToyotaDomain = window.location.href.includes('toyotainsurance');

  return isLoading ? (
    <FullPageLoading delay={1000} />
  ) : (
    <Page>
      <div className={styles.logo}>
        <Logo
          onErrorScreen={true}
          width={isMobile ? 112 : 180}
          height={isMobile ? 16 : 25}
        />
      </div>
      <Title>{isEnabled ? variables.errorTitle : 'We’re sorry.'}</Title>
      <Text>
        {isEnabled
          ? variables.errorBody
          : 'We’ve experienced a technical issue, but we’re working on it and expect to be up and running soon.'}
      </Text>
      {(isRecaptchaError || isToyotaDomain) && (
        <Text>
          Need to get a quote? Call{' '}
          {isToyotaDomain ? toyotaPhoneLink : defaultPhoneLink}
        </Text>
      )}
      {showButtons && (
        <ButtonGroup>
          <DesktopSubmitButtonWrapper>
            <Button width="block" onClick={onReturnHomeClicked}>
              Return to Home
            </Button>
          </DesktopSubmitButtonWrapper>
          <MobileSubmitButtonWrapper>
            <Button width="fullWidth" onClick={onReturnHomeClicked}>
              Return to Home
            </Button>
          </MobileSubmitButtonWrapper>
        </ButtonGroup>
      )}
    </Page>
  );
};
