import { FieldDataType } from 'V2/Types';

export type FieldErrorType = {
  [key: string]: {
    [key: string]: string;
  };
};

export const buildFieldErrors = (
  fieldData: FieldDataType[]
): FieldErrorType => {
  const errors = fieldData.reduce<FieldErrorType>((fields, field) => {
    if (!fields[field.entity_id]) {
      fields[field.entity_id] = {};
    }

    if (field.errors.length) {
      fields[field.entity_id][field.field_id] = field.errors.join(', ');
    }

    return fields;
  }, {});

  return errors;
};
