import React from 'react';
import cx from 'classnames';
import styles from './styles.module.css';

export const Page: React.FC<{ variant?: string }> = ({
  variant,
  children,
  ...props
}) => (
  <div
    className={cx(
      styles.main,
      { [styles[`${variant}`]]: variant },
      styles.className
    )}
    {...props}
  >
    {children}
  </div>
);
