export * from './types';
export * from './Presets';

// This is in milliseconds.
const DURATION_CONSTANT = 300;

// Allows us to only have to change duration
// in one spot if we decide to change the value,
// because this value is used in multiple files.
export const TransitionDuration = {
  milliseconds: DURATION_CONSTANT,
  seconds: DURATION_CONSTANT / 1000,
};
