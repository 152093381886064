import React from 'react';

export const Lock = () => (
  <svg
    height="12"
    viewBox="0 0 10 12"
    width="10"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    aria-labelledby="lockTitle"
  >
    <title id="lockTitle">Checkout</title>
    <path
      className="fill-brand-03"
      d="m8.617 3.998c.62 0 1.125.512 1.125 1.143v5.714c0 .631-.504 1.143-1.125 1.143h-6.75a1.134 1.134 0 0 1 -1.125-1.143v-5.714c0-.63.504-1.143 1.125-1.143h.562v-1.143c0-1.577 1.26-2.857 2.813-2.857s2.812 1.28 2.812 2.857v1.143zm-3.375-2.856c-.932 0-1.687.768-1.687 1.714v1.144h3.375v-1.144c0-.946-.756-1.714-1.688-1.714z"
    />
  </svg>
);
