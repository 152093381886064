import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styles from './styles.module.css';
import { TransitionDuration } from '../Animations';
import { Close } from '../Icons/Close';

type FullScreenModalType = {
  showModal: boolean;
  onCloseClick?: () => void;
};

export const FullScreenModal: React.FC<FullScreenModalType> = ({
  showModal,
  onCloseClick,
  children,
}) => (
  <AnimatePresence exitBeforeEnter={true}>
    {showModal && (
      <motion.div
        key="fullScreenModal"
        className={styles.content}
        transition={{
          duration: TransitionDuration.seconds,
          ease: [0.83, 0, 0.17, 1],
        }}
        initial={{ opacity: 0.01 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0.01 }}
      >
        {onCloseClick && (
          <button
            type="button"
            data-testid="close-modal"
            className={styles.button}
            onClick={onCloseClick}
          >
            <div className={styles.close}>Close</div>
            <div className={styles.icon}>
              <Close />
            </div>
          </button>
        )}
        {children}
      </motion.div>
    )}
  </AnimatePresence>
);
