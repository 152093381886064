import { AnimationConfigType } from 'V2/Shared/Animations';

export const slideLeft: AnimationConfigType = {
  initial: {
    x: 100,
    opacity: 0,
  },
  animate: {
    x: 0,
    opacity: 1,
  },
  exit: {
    x: -100,
    opacity: 0,
  },
};
