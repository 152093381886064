import React, { useContext, useState, useEffect, useMemo } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { env } from 'constants/env';
import {
  StepType,
  useApplyProvider,
  useFields,
  useUpdateEntityStatus,
} from 'V2/Apply';
import { Spinner } from 'V2/Shared/Spinner';
import { Nullable, OptionUriType, SignatureSummaryType } from 'V2/Types';
import { useApi } from 'V2/Api';
import { ErrorBannerRed } from 'V2/Shared/ErrorBannerRed';
import { PaymentContext } from './PaymentContext';
import styles from './styles.module.css';
import { Adyen } from './Forms';
import { AdyenConfig } from './types';
import { Button } from '../../Shared/Button';
import { css } from 'styled-components/macro';
import { TermsAndConditions } from './Terms/TermsAndConditions';
import { Logo } from 'V2/Shared/Logo';
import { ArrowLeft } from 'V2/Shared/Icons/ArrowLeft';
import { Lock } from 'V2/Shared/Icons/Lock';
import { ProcessOne } from './Forms/ProcessOne';
import { getAdyenConfig } from './getAdyenConfig';
import { FeeModal } from 'V2/Shared/FeeModal';
import { CircleInfoButton } from 'V2/Shared/CircleInfoButton';

export const PaymentDetails: React.FC<StepType> = ({
  fields,
  onSubmitStep,
  isSubmitting: isAnimating,
  status: {
    id: stepName,
    field_data: fieldData,
    insurance_application_id: insuranceApplicationId,
  },
  summary: { entities, commands },
  config,
}) => {
  const {
    options_uris: optionsUris,
    application_focus: {
      path: { identifier },
    },
    features_by_key: {
      signature_summaries: signatureSummaries,
      product_segment: productSegment,
      product_type: productType,
    },
    option_sets: optionSets,
  } = config;

  const {
    adyen,
    formLoaded,
    setFields,
    isDeactivating,
    setIsDeactivating,
    setFormValid,
    setFormLoaded,
    isSubmitting,
    setIsSubmitting,
  } = useContext(PaymentContext);

  const { getResource, error: apiError, setError: setApiError } = useApi();

  const {
    app: { summary },
  } = useApplyProvider();

  const { update } = useUpdateEntityStatus();

  const [errors, setErrors] = useState<string[]>([]);

  const [isTotalPremiumFeeModalVisible, setIsTotalPremiumFeeModalVisible] =
    useState(false);
  const [isTotalTodayFeeModalVisible, setIsTotalTodayFeeModalVisible] =
    useState(false);

  const showTotalPremiumFeeModal = () => {
    setIsTotalPremiumFeeModalVisible(true);
  };
  const showTotalTodayFeeModal = () => {
    setIsTotalTodayFeeModalVisible(true);
  };

  // policy
  const policy = entities.find(
    (e) =>
      e.type.id === 'policy' && e.parent_entity_id === insuranceApplicationId
  );

  // payment_plan
  const paymentPlan = entities.find(
    (e) => e.type.id === 'payment_plan' && e.parent_entity_id === identifier
  );
  const paymentPlanId = paymentPlan ? paymentPlan.id : null;

  // down_payment
  const downPayment = entities.find(
    (e) => e.type.id === 'down_payment' && e.parent_entity_id === paymentPlanId
  );

  // total premium and fees
  const totalPremiumAndFees = entities.find(
    (e) =>
      e.type.id === 'total_premium_and_fees' &&
      e.parent_entity_id === paymentPlanId
  );

  const selectedPaymentPlan = optionSets.selected_payment_plan;

  // we know this driver exists to pull out primary driver for cardholder name;
  const primaryDriver = summary.entities
    .filter((e) => e.type.id === 'driver')
    .find((e) => e.type.sub_type === 'named_insured')!;

  const signaturesRequired =
    fields.filter((f) => f.entity_type.id === 'signature').length > 0;

  useEffect(() => {
    setIsDeactivating(false);
    setFormLoaded(false);
    setFormValid(false);
  }, [setIsDeactivating, setFormValid, setFormLoaded]);

  const {
    payment_method: paymentMethodField,
    payment_plan_id: paymentPlanIdField,
    payment_token: paymentTokenField,
  } = useFields(fields, ['payment_method', 'payment_plan_id', 'payment_token']);
  const paymentMethodChosen: string = `${paymentMethodField.value}`;
  const paymentProvider: string = `${paymentPlanIdField.entity_type.sub_type}`;

  const isNonStandardAutoProduct =
    productSegment === 'nonStandardAuto' && productType === 'personal_auto';

  useEffect(() => {
    setErrors(paymentTokenField.errors);
  }, [paymentTokenField.errors]);

  const tokenUri: string = optionsUris.find(
    (o: OptionUriType) => o.field_id === 'payment_token'
  )!.uri;
  const [paymentConfig, setPaymentConfig] =
    useState<Nullable<AdyenConfig | any>>(null); // fix any

  // Due to how Adyen unloads from the DOM
  // It can cause errors if we don't do this
  useEffect(() => {
    if (stepName === 'payment_details') {
      setFields(() => fieldData);
    }
  }, [setFields, fieldData, stepName]);

  useEffect(() => {
    if (!isAnimating && !isSubmitting) {
      if (tokenUri && paymentPlanIdField && !paymentConfig) {
        getResource({ url: tokenUri }).then(({ data }) => {
          if (paymentProvider === 'process_one') {
            //needs to include entire response
            setPaymentConfig(data);
          } else {
            setPaymentConfig(
              getAdyenConfig({
                paymentMethod: paymentMethodChosen,
                environment: env.adyenEnvironment,
                formData: {
                  holderName: primaryDriver.label,
                },
              })
            );
          }
        });
      }
    }
  }, [
    paymentPlanId,
    paymentMethodChosen,
    optionsUris,
    isAnimating,
    isSubmitting,
    getResource,
    paymentPlanIdField,
    tokenUri,
    paymentConfig,
    paymentProvider,
    primaryDriver.label,
  ]);

  const paymentForm = useMemo(() => {
    // Process One payments are handled in a modal opened by clicking Checkout
    if (paymentProvider === 'process_one') {
      return (
        <div className={styles.purchaseSummaryTitle}>Purchase Summary</div>
      );
    }

    return paymentConfig && paymentPlanIdField ? (
      <Adyen fields={fields} config={paymentConfig} />
    ) : (
      <></>
    );
  }, [paymentProvider, paymentConfig, paymentPlanIdField, fields]);

  const formProps = useMemo(() => {
    let initialValues = {};
    let validationSchema = {};
    const summaries = signatureSummaries as SignatureSummaryType[];

    if (signaturesRequired) {
      validationSchema = Yup.object().shape(
        summaries.reduce(
          (values, document) => ({
            ...values,
            [document.group_id]: Yup.boolean().oneOf([true], 'Required'),
          }),
          {}
        )
      );

      initialValues = summaries.reduce(
        (formValues, document) => ({
          ...formValues,
          [document.group_id]: false,
        }),
        {}
      );
    }

    return {
      validationSchema,
      initialValues,
      onSubmit: () => {},
      enableReinitialize: true,
      validateOnMount: true,
    };
  }, [signatureSummaries, signaturesRequired]);

  return (
    <div className={styles.payment}>
      {!isDeactivating &&
        errors.map((error, i) => (
          <ErrorBannerRed key={i}>{error}</ErrorBannerRed>
        ))}
      <div className={styles.navigation}>
        <Button
          isSubmitting={isDeactivating}
          data-testid="back"
          color={'primary'}
          variant={'link'}
          size={'inline'}
          type={'button'}
          onClick={() => {
            const command = commands.find(
              (c) => c.id === 'delete_payment_plan'
            );
            if (command) {
              setIsDeactivating(true);
              update(command);
            } else {
              // Black bar error?
            }
          }}
        >
          <span className={styles.buttonContent}>
            <ArrowLeft className={styles.icon} />
            Back
          </span>
        </Button>
        <div className={styles.logo}>
          <Logo useImageTag />
        </div>
      </div>
      <Formik {...formProps}>
        {({ isValid }) => (
          <div className={styles.main}>
            <div className={styles.title}>Complete your purchase</div>
            {paymentForm}
            {paymentProvider !== 'process_one' &&
              (!formLoaded || isDeactivating) && (
                <div className={styles.loader}>
                  <Spinner color={css`var(--brand-01)`} />
                </div>
              )}
            <div className={styles.purchaseSummary}>
              {policy && policy.description && (
                <div className={styles.purchaseSummaryRow}>
                  <div>Effective Date</div>
                  <div>{policy.description.split(': ')[1]}</div>
                </div>
              )}
              {paymentPlan && (
                <div className={styles.purchaseSummaryRow}>
                  <div>Payment Plan Name</div>
                  <div className={styles.frequency}>
                    {paymentPlan.type.sub_type}
                  </div>
                </div>
              )}
              {totalPremiumAndFees && (
                <div className={styles.purchaseSummaryRow}>
                  <div className={styles.flex}>
                    {totalPremiumAndFees.description}
                    {isNonStandardAutoProduct && (
                      <CircleInfoButton onClick={showTotalPremiumFeeModal} />
                    )}
                  </div>
                  <div>{totalPremiumAndFees.label}</div>
                  {isNonStandardAutoProduct && (
                    <FeeModal
                      isModalVisible={isTotalPremiumFeeModalVisible}
                      setIsModalVisible={setIsTotalPremiumFeeModalVisible}
                      optionSet={selectedPaymentPlan || []}
                      isTotalDueModal={true}
                    />
                  )}
                </div>
              )}
            </div>
            {downPayment && (
              <div className={styles.dueTodayContainer}>
                <div className={styles.flex}>
                  Total due today
                  {isNonStandardAutoProduct && (
                    <CircleInfoButton onClick={showTotalTodayFeeModal} />
                  )}
                </div>
                <div>{downPayment.label}</div>
                {isNonStandardAutoProduct && (
                  <FeeModal
                    isModalVisible={isTotalTodayFeeModalVisible}
                    setIsModalVisible={setIsTotalTodayFeeModalVisible}
                    optionSet={selectedPaymentPlan || []}
                    isTotalDueModal={false}
                  />
                )}
              </div>
            )}
            {signaturesRequired && (
              <TermsAndConditions
                valid={isDeactivating || isValid}
                fields={fields}
                config={config}
              />
            )}
            {paymentProvider === 'process_one' ? (
              <ProcessOne
                isValid={isValid}
                paymentMethodChosen={paymentMethodChosen}
                paymentConfig={paymentConfig}
                setErrors={setErrors}
              />
            ) : (
              <div data-testid="actions" className={styles.action}>
                <Button
                  disabled={isDeactivating}
                  width="fullWidth"
                  size="lg"
                  isSubmitting={isSubmitting}
                  onClick={() => {
                    if (apiError) setApiError(null);

                    // adyen credit card and ach submit
                    if (adyen && paymentPlanIdField) {
                      setIsSubmitting(true);

                      if (isValid) {
                        adyen.submit();
                        !adyen.isValid &&
                          setTimeout(() => setIsSubmitting(false), 0);
                      } else {
                        // Due to the way react works, we need to add
                        // "setIsSubmitting(false)" to the event loop
                        // using a setTimeout: otherwise it will cancel
                        // out the "setIsSubmitting(true)" completely.
                        setTimeout(() => setIsSubmitting(false), 0);
                      }
                    }
                  }}
                  type="button"
                >
                  <span>
                    <Lock />
                    {'  '}
                    Check Out Securely
                  </span>
                </Button>
              </div>
            )}
          </div>
        )}
      </Formik>
    </div>
  );
};
