export const baseDriver = (state: string) => ({
  contact_information: {
    email_address: `${state}.${Date.now()}@clearcover.com`,
  },
  confirmed_no_accidents: true,
  confirmed_no_violations: true,
  driver_information: {
    first_name: 'David',
    last_name: 'Woods',
    date_of_birth: '1971-04-13',
    gender: 'male',
    marital_status: 'single',
    relationship_to_applicant: 'applicant',
  },
  current_education: {
    education_level_clearcover_key: 'bachelors_degree',
  },
  license: {
    region: 'usa',
    age_first_licensed: 16,
    state: 'WI',
    number: 'D2224217041801',
    source: 'user_reported',
    has_previous_suspension: false,
  },
  homeownership: {
    homeowner: true,
    months_owned: 16,
  },
  traffic_school: {
    course_taken: true,
    completion_date: '2017-01-08',
  },
  occupation: {
    occupation_clearcover_key: 'Actuary',
  },
  military_service: {
    service_member: true,
    is_deployed: false,
    military_grade: 'O-4',
  },
});
