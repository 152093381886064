import React, { useEffect, useContext } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useQuery } from 'V2/Hooks';
import { ApplyContext, ApplyContextType } from 'V2/Apply';
import { statusDefault } from 'V2/Step';
import { EditContext } from './EditProvider';
import { EditContextType } from './types';
import { Header } from './Header';
import { Summary } from './Summary';
import { Step } from './Step';
import { TransitionDuration, slideLeft } from 'V2/Shared/Animations';
import styles from './styles.module.css';

export const Edit: React.FC = () => {
  const query = useQuery();
  const { setApp } = useContext<ApplyContextType>(ApplyContext);
  const { animation, setStatuses, setAnimation, setStep } =
    useContext<EditContextType>(EditContext);

  const stepName = query.get('stepName');
  const step = stepName ? <Step /> : <Summary />;

  useEffect(
    () => () => {
      setStep((step) => ({
        ...step,
        status: statusDefault,
      }));
      setStatuses([]);
      setAnimation(slideLeft);
    },
    [setStep, setStatuses, setAnimation, setApp]
  );

  return (
    <div className={styles.container}>
      <AnimatePresence>
        <motion.div
          key="header"
          exit={{ opacity: 0 }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: TransitionDuration.seconds }}
        >
          {stepName !== 'coverages' && <Header />}
        </motion.div>
      </AnimatePresence>
      {stepName === 'coverages' ? (
        <AnimatePresence exitBeforeEnter>
          <motion.div
            key={`${stepName}`}
            exit={{ opacity: 0 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: TransitionDuration.seconds }}
          >
            {step}
          </motion.div>
        </AnimatePresence>
      ) : (
        <AnimatePresence exitBeforeEnter>
          <motion.div
            key={`${stepName}`}
            exit={animation.exit}
            initial={animation.initial}
            animate={animation.animate}
            transition={{ duration: TransitionDuration.seconds }}
          >
            {step}
          </motion.div>
        </AnimatePresence>
      )}
    </div>
  );
};
