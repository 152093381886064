import React, { useContext, createContext } from 'react';
import { useResources } from './ApiHooks';
import { ApiContextType } from './types';

export const ApiContext = createContext<ApiContextType>({
  error: null,
  setError: () => {},
  flowSequence: () => new Promise(() => {}),
  postResource: () => new Promise(() => {}),
  getResource: () => new Promise(() => {}),
  getStatus: () => new Promise(() => {}),
  getAnalytics: () => new Promise(() => {}),
});

export const ApiProvider: React.FC = ({ children }) => {
  const apiResources = useResources();

  return (
    <ApiContext.Provider value={apiResources}>{children}</ApiContext.Provider>
  );
};

export const useApi = (): ApiContextType => useContext(ApiContext);
