import { Controls, Modal, Text } from '../Modal';
import { Button } from '../Button';
import { ValidationChoiceType, ValidationType } from '../../Types';
import React, { FC, useEffect, useState } from 'react';

type ValidationModalType = {
  validation: ValidationType;
  resetValidation: () => void;
  handleClick: (
    action: ValidationChoiceType,
    setShowModal: (show: boolean) => void,
    i: number
  ) => Promise<void>;
};

export const ValidationModal: FC<ValidationModalType> = ({
  validation,
  resetValidation,
  handleClick,
}) => {
  const [controlClicked, setControlClicked] = useState<number>(-1);
  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    if (validation.type === 'warning') {
      setShowModal(true);
    }
  }, [validation]);

  return (
    <Modal
      showModal={showModal}
      title={validation.label}
      closeAction={() => {
        setShowModal(false);
        resetValidation();
      }}
    >
      <Text>{validation.description}</Text>
      <Controls>
        {validation.validation_choices.length > 0 ? (
          validation.validation_choices.map((action, i) => (
            <Button
              key={i}
              variant={
                i === validation.validation_choices.length - 1
                  ? 'contained'
                  : 'outlined'
              }
              size={'md'}
              isSubmitting={controlClicked === i}
              onClick={() => {
                setControlClicked(i);
                handleClick(action, setShowModal, i).finally(() => {
                  setShowModal(false);
                  resetValidation();
                  setControlClicked(-1);
                });
              }}
            >
              {action.label}
            </Button>
          ))
        ) : (
          <>
            <Button variant="link" size="md" onClick={resetValidation}>
              Cancel
            </Button>
          </>
        )}
      </Controls>
    </Modal>
  );
};
