import React from 'react';
import styles from './styles.module.css';

type ProgressBarType = {
  title: string;
  description?: string;
};

export const ProgressBar: React.FC<ProgressBarType> = ({
  title,
  description,
}) => (
  <div className={styles.container}>
    <div className={styles.title}>{title}</div>
    {description && <div className={styles.text}>{description}</div>}
    <div className={styles.progress}>
      <div className={styles.progressBar} />
    </div>
  </div>
);
