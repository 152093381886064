import { AxiosResponse } from 'axios';
import { http } from '../http';

export type PostPageViewType = {
  appId: string;
  url: string;
  data: object;
};

export function postPageView(
  payload: PostPageViewType
): Promise<AxiosResponse<any>> {
  return http.post({
    url: `/api/v1/insurance_applications/${payload.appId}/page_views`,
    data: {
      url: payload.url,
      data: payload.data,
    },
  });
}
