import React from 'react';

export const ChevronUp = ({
  alt = 'arrow up',
  className,
}: {
  alt?: string;
  className?: string;
}) => (
  <svg
    className={className}
    height="14"
    viewBox="1 0 16 14"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    aria-labelledby="chevronUpId"
  >
    <title id="chevronUpId">{alt}</title>
    <path
      className="fill-black"
      d="M14.7075 12.2925C15.0975 11.9025 15.0975 11.2725 14.7075 10.8825L10.1175 6.2925C9.7275 5.9025 9.0975 5.9025 8.7075 6.2925L4.1175 10.8825C3.7275 11.2725 3.7275 11.9025 4.1175 12.2925C4.5075 12.6825 5.1375 12.6825 5.5275 12.2925L9.4175 8.4125L13.2975 12.2925C13.6875 12.6825 14.3275 12.6725 14.7075 12.2925Z"
    />
  </svg>
);
