import { http } from '../http';
import { Nullable } from 'V2/Types';

type PayloadType = {
  url_slug: string;
};

export type AttributionLinkBySlugResponseType = {
  data: {
    clearcover_application_id: string;
    lead_metadata: {
      attribution: Nullable<string>;
      campaign_medium: Nullable<string>;
      lead_identifier: Nullable<string>;
      lead_name: Nullable<string>;
      partner_unstructured_metadata: Nullable<string>;
    };
    return_url: string;
    validation_errors: string[];
  };
};

export const postAttributionLinkBySlug = (payload: PayloadType) =>
  http.post({
    url: '/api/v1/lead/attribution_link_by_slug',
    data: payload,
  });
