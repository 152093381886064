import axios from 'axios';

import { CreateAxiosInstance } from './types';
import {
  createRequestInterceptor,
  createRequestErrorInterceptor,
  createResponseInterceptor,
  createResponseErrorInterceptor,
} from './interceptors';

export const createAxiosInstance: CreateAxiosInstance = (config) => {
  const {
    baseURL,
    onRequestFulfilled = createRequestInterceptor(),
    onRequestRejected = createRequestErrorInterceptor(),
    onResponseFulfilled = createResponseInterceptor(),
    onResponseRejected = createResponseErrorInterceptor(),
  } = config;

  const instance = axios.create({ baseURL });

  instance.interceptors.response.use(onResponseFulfilled, onResponseRejected);
  instance.interceptors.request.use(onRequestFulfilled, onRequestRejected);

  return instance;
};
