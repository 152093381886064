import React from 'react';

export const PhoneCircle = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="50"
    height="50"
    fill="none"
    viewBox="0 0 56 56"
  >
    <circle
      cx="28"
      cy="28"
      r="26.5"
      fill="#fff"
      stroke="#000"
      strokeWidth="3"
    />
    <mask id="a" width="40" height="40" x="9" y="8" maskUnits="userSpaceOnUse">
      <path fill="#D9D9D9" d="M9 8h40v40H9z" />
    </mask>
    <g mask="url(#a)">
      <path
        fill="#1C1B1F"
        d="M42.25 43c-3.583 0-7.076-.799-10.48-2.396a31.781 31.781 0 0 1-9.04-6.333 31.781 31.781 0 0 1-6.334-9.042C14.799 21.826 14 18.333 14 14.75c0-.5.167-.917.5-1.25.333-.333.75-.5 1.25-.5h6.75a1.6 1.6 0 0 1 1.042.375c.305.25.486.57.541.958l1.084 5.834c.055.389.048.743-.021 1.062-.07.32-.23.604-.48.854l-4.041 4.084c1.167 2 2.632 3.875 4.396 5.625a31.064 31.064 0 0 0 5.812 4.541l3.917-3.916c.25-.25.576-.438.98-.563.402-.125.798-.16 1.187-.104l5.75 1.167c.389.083.708.27.958.562.25.292.375.632.375 1.021v6.75c0 .5-.167.917-.5 1.25-.333.333-.75.5-1.25.5ZM19.042 23l2.75-2.75-.709-3.917h-3.708c.139 1.14.333 2.264.583 3.375.25 1.111.611 2.209 1.084 3.292Zm14.916 14.917c1.084.472 2.188.847 3.313 1.125 1.125.277 2.257.458 3.396.541v-3.666l-3.917-.792-2.792 2.792Z"
      />
    </g>
  </svg>
);
