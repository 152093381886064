import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { buildFields } from 'V2/Step';
import { useQuery } from 'V2/Hooks';
import { useSummary, useUpdateEntityStatus } from 'V2/Apply';
import { EditContext, EditContextType } from 'V2/Edit';
import { Spinner } from 'V2/Shared/Spinner';
import { slideLeft, slideRight } from 'V2/Shared/Animations';
import { PiiIgnore } from 'V2/Shared/PiiIgnore';
import { Nullable } from 'V2/Types';
import { useApi } from 'V2/Api';
import { Loading } from './Loading';
import styles from './styles.module.css';
import { Button } from 'V2/Shared/Button';
import { Pencil } from 'V2/Shared/Icons/Pencil';

// Ignore combined steps to not show duplicate fields
const excludedSteps = ['primary_driver_name_and_dob', 'driver_name_and_dob'];

export const Summary: React.FC = () => {
  const history = useHistory();
  const { statuses, setAnimation, setStatuses, setStep } =
    useContext<EditContextType>(EditContext);
  const { update, isUpdating } = useUpdateEntityStatus();
  const { appId, drivers, vehicles } = useSummary();
  const [isFetching, setIsFetching] = useState<Nullable<string>>(null);

  const query = useQuery();
  const entityId = query.get('entityId');
  const entity = [...drivers, ...vehicles].find((e) => e.id === entityId);
  const hasNoDetails = !!appId && !entity;

  const { getResource } = useApi();

  useEffect(() => {
    setAnimation(slideLeft);

    return () => {
      setAnimation(slideRight);
    };
  }, [setAnimation]);

  useEffect(() => {
    if (!statuses.length && entity && entity.detail_uri) {
      getResource({ url: `${entity.detail_uri}&include_details=true` }).then(
        (response) => {
          setTimeout(() => {
            if (response.data.groups.length > 0) {
              setStatuses(response.data.groups);
            } else {
              // no statuses found, show error
            }
          }, 500);
        }
      );
    }
  }, [entity, statuses, setStatuses, getResource]);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        {entity && statuses.length > 0 && (
          <div className={styles.title}>
            {entity.type.id === 'driver' ? 'Driver' : 'Vehicle'} Overview
          </div>
        )}
        <div className={hasNoDetails ? '' : styles.fields}>
          {statuses.length > 0 ? (
            statuses
              .filter(
                (status) =>
                  status.description && !excludedSteps.includes(status.id)
              )
              .map((status) => {
                if (
                  status.id === 'vehicle_parking_type' &&
                  status.description === 'unknown'
                ) {
                  return { ...status, description: '' };
                }
                return status;
              })
              .map((status, i) => (
                <div className={styles.field} key={i} data-testid={status.id}>
                  <div className={styles.displayField}>
                    <div className={styles.fieldContainer}>
                      <div className={styles.display}>{status.label}</div>
                      <PiiIgnore>
                        <div className={styles.value}>{status.description}</div>
                      </PiiIgnore>
                    </div>
                    <button
                      data-testid="edit"
                      className={styles.editIcon}
                      onClick={() => {
                        if (status.step_details && status.step_status) {
                          setStep({
                            status,
                            config: status.step_details,
                            fields: buildFields(
                              status.step_status.field_data,
                              status.step_details
                            ),
                          });

                          history.push({
                            search: `?edit=true&entityId=${query.get(
                              'entityId'
                            )}&stepName=${status.id}`,
                          });
                        } else {
                          setIsFetching(status.label);

                          getResource({ url: status.step_config_uri }).then(
                            (response) => {
                              setStep({
                                status,
                                config: response.data,
                                fields: buildFields(
                                  status.field_data,
                                  response.data
                                ),
                              });

                              history.push({
                                search: `?edit=true&entityId=${query.get(
                                  'entityId'
                                )}&stepName=${status.id}`,
                              });
                            }
                          );
                        }
                      }}
                    >
                      {isFetching === status.label ? (
                        <Spinner />
                      ) : (
                        status.editable && <Pencil />
                      )}
                    </button>
                  </div>
                </div>
              ))
          ) : hasNoDetails ? (
            <>
              This vehicle/driver no longer exists. Click "done" to return to
              your quote.
            </>
          ) : (
            <Loading />
          )}
        </div>
        <div className={styles.commands}>
          {statuses.length > 0 &&
            entity &&
            entity.commands
              .filter(
                (c) => c.id === 'remove_driver' || c.id === 'remove_vehicle'
              )
              .map((command, i) => (
                <Button
                  key={i}
                  onClick={() => update(command)}
                  isSubmitting={isUpdating === command.id}
                  color="danger"
                  variant="outlined"
                  width="block"
                >
                  Remove {entity.type.id} from policy
                </Button>
              ))}
        </div>
      </div>
    </div>
  );
};
