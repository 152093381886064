import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import { useQuery } from '../Hooks';
import { FormActionsType } from './types';
import {
  postAttributionLinkBySlug,
  AttributionLinkBySlugResponseType,
} from 'V2/Services/PostAttributionLinkBySlug';
import {
  postApplicationSearch,
  ApplicationSearchType,
  ApplicationSearchResponseType,
} from 'V2/Services/PostApplicationSearch';
import {
  getQueryParams,
  GetQueryParamsType,
} from 'V2/Utilities/GetQueryParams';
import { buildQueryString } from 'V2/Utilities/BuildQueryString';

export type SearchStateType = 'initial' | 'linkExpired' | 'quoteNotFound';

export const useSearch = () => {
  const history = useHistory();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const query = useQuery();
  const [searchStatus, setSearchStatus] = useState<SearchStateType>(
    query.get('errorType') === 'invalidLink' ? 'linkExpired' : 'initial'
  );
  const [isCreatingQuote, setIsCreatingQuote] = useState(false);

  const onNewSearchClick = () => {
    query.delete('errorType');
    history.replace({
      search: query.toString(),
    });
    setSearchStatus('initial');
  };

  const onFormSubmit = (
    values: ApplicationSearchType,
    actions: FormActionsType
  ) => {
    postApplicationSearch({
      ...values,
      date_of_birth: format(values.date_of_birth, 'YYYY-MM-DD'),
    })
      .then((response: ApplicationSearchResponseType) => {
        setIsSubmitted(true);
        actions.setSubmitting(false);
        actions.resetForm();
      })
      .catch((error: { response: { status: number } }) => {
        actions.setSubmitting(false);
        if (error.response && error.response.status === 404) {
          return setSearchStatus('quoteNotFound');
        }
        history.push('/error?errorType=search_failure');
      });
  };

  const onNewQuoteClick = () => {
    setIsCreatingQuote(true);

    const urlQueryParams: GetQueryParamsType = getQueryParams(
      history.location.search
    );
    const queryParams: GetQueryParamsType = {
      partner_referrer: document.referrer,
      partner_slug: 'quotestart',
      ...urlQueryParams,
      // this is to support legacy campaign names as we switch the param name
      ...(!urlQueryParams.campaign_name && urlQueryParams.utm_campaign
        ? { campaign_name: urlQueryParams.utm_campaign }
        : {}),
    };

    postAttributionLinkBySlug({
      url_slug: 'quotestart',
      ...queryParams,
    })
      .then((response: AttributionLinkBySlugResponseType) => {
        window.location.href = `${response.data.return_url}?${buildQueryString(
          queryParams
        )}`;
      })
      .catch(() => {
        history.push('/error?errorType=new_quote_failure');
      });
  };

  return {
    onFormSubmit,
    onNewQuoteClick,
    isSubmitted,
    searchStatus,
    isCreatingQuote,
    onNewSearchClick,
  };
};
