export const states = {
  AL: {
    line_one: '1933 Huntington Ln',
    city: 'Birmingham',
    state: 'AL',
    zip_code: '35214',
  },
  AK: {
    line_one: '4736 Grumman St',
    city: 'Anchorage',
    state: 'AK',
    zip_code: '99507',
  },
  AZ: {
    line_one: '4921 E Calle Del Medio',
    city: 'Phoenix',
    state: 'AZ',
    zip_code: '85018',
  },
  AR: {
    line_one: '4815 Lafayette Ave',
    city: 'Little Rock',
    state: 'AR',
    zip_code: '72205',
  },
  CA: {
    line_one: '318 N Glenwood Ave',
    city: 'Glendora',
    state: 'CA',
    zip_code: '91741',
  },
  CO: {
    line_one: '558 Simmental Loop',
    city: 'Castle Rock',
    state: 'CO',
    zip_code: '80104',
  },
  CT: {
    line_one: '194 Windmill Hl',
    city: 'Wethersfield',
    state: 'CT',
    zip_code: '06109',
  },
  DE: {
    line_one: '41 Oriole Ln',
    city: 'Smyrna',
    state: 'DE',
    zip_code: '19977',
  },
  FL: {
    line_one: '5524 Meander Ln',
    city: 'Lake Wales',
    state: 'FL',
    zip_code: '33898',
  },
  GA: {
    line_one: '5371 Tall Oak Dr',
    city: 'Marietta',
    state: 'GA',
    zip_code: '30068',
  },
  HI: {
    line_one: '92-728 Welo St',
    city: 'Kapolei',
    state: 'HI',
    zip_code: '96707',
  },
  ID: {
    line_one: '1006 N 19th St',
    city: 'Coeur D Alene',
    state: 'ID',
    zip_code: '83814',
  },
  IL: {
    line_one: '339 Duane St',
    city: 'Glen Ellyn',
    state: 'IL',
    zip_code: '60137',
  },
  IN: {
    line_one: '6148 E 43rd Pl',
    city: 'Indianapolis',
    state: 'IN',
    zip_code: '46226',
  },
  IA: {
    line_one: '3316 Stoneview Cir SW',
    city: 'Cedar Rapids',
    state: 'IA',
    zip_code: '52404',
  },
  KS: {
    line_one: '7053 S 143rd St E',
    city: 'Derby',
    state: 'KS',
    zip_code: '67037',
  },
  KY: {
    line_one: '11001 Park Rd',
    city: 'Anchorage',
    state: 'KY',
    zip_code: '40223',
  },
  LA: {
    line_one: '631 Unadilla St',
    city: 'Shreveport',
    state: 'LA',
    zip_code: '71106',
  },
  ME: {
    line_one: '73 Fairview Drive',
    city: 'Sanford',
    state: 'ME',
    zip_code: '04073',
  },
  MD: {
    line_one: '109 N Edgewood St',
    city: 'Baltimore',
    state: 'MD',
    zip_code: '21229',
  },
  MA: {
    line_one: '174 Forest St',
    city: 'West Bridgewater',
    state: 'MA',
    zip_code: '02379',
  },
  MI: {
    line_one: '5477 Benton Rd',
    city: 'Jackson',
    state: 'MI',
    zip_code: '49201',
  },
  MN: {
    line_one: '21507 7th St NE',
    city: 'East Bethel',
    state: 'MN',
    zip_code: '55011',
  },
  MS: {
    line_one: '5110 Sedgwick Dr',
    city: 'Jackson',
    state: 'MS',
    zip_code: '39211',
  },
  MO: {
    line_one: '31 Chestnut Hill Ln',
    city: 'Saint Louis',
    state: 'MO',
    zip_code: '63119',
  },
  MT: {
    line_one: '13345 Bridger Canyon Rd',
    city: 'Bozeman',
    state: 'MT',
    zip_code: '59715',
  },
  NE: {
    line_one: '103 S Delaware Ave',
    city: 'York',
    state: 'NE',
    zip_code: '68467',
  },
  NV: {
    line_one: '6716 Theus Cir',
    city: 'Las Vegas',
    state: 'NV',
    zip_code: '89107',
  },
  NH: {
    line_one: '3 Fieldstone Drive',
    city: 'Londonderry',
    state: 'NH',
    zip_code: '03053',
  },
  NJ: {
    line_one: '911 Walnut Ave',
    city: 'Lindenwold',
    state: 'NJ',
    zip_code: '08021',
  },
  NM: {
    line_one: '10 Mustang Rd',
    city: 'Edgewood',
    state: 'NM',
    zip_code: '87015',
  },
  NY: {
    line_one: '335 Lake Drive',
    city: 'Lake Peekskill',
    state: 'NY',
    zip_code: '10537',
  },
  NC: {
    line_one: '113 Burnham Ct',
    city: 'Garner',
    state: 'NC',
    zip_code: '27529',
  },
  ND: {
    line_one: '1400 S 3rd St',
    city: 'Bismarck',
    state: 'ND',
    zip_code: '58504',
  },
  OH: {
    line_one: '487 Dorchester Rd',
    city: 'Akron',
    state: 'OH',
    zip_code: '44320',
  },
  OK: {
    line_one: '910 W Lakeridge Ave',
    city: 'Stillwater',
    state: 'OK',
    zip_code: '74075',
  },
  OR: {
    line_one: '3344 Ridgeway Dr SE',
    city: 'Turner',
    state: 'OR',
    zip_code: '97392',
  },
  PA: {
    line_one: '311 Hawthorne Ave',
    city: 'Greensburg',
    state: 'PA',
    zip_code: '15601',
  },
  RI: {
    line_one: '11 Atlantic Ave',
    city: 'Warwick',
    state: 'RI',
    zip_code: '02888',
  },
  SC: {
    line_one: '3880 Laurel Point Ln',
    city: 'Johns Island',
    state: 'SC',
    zip_code: '29455',
  },
  SD: {
    line_one: '601 S Mable Ave',
    city: 'Sioux Falls',
    state: 'SD',
    zip_code: '57103',
  },
  TN: {
    line_one: '1341 Nicole Ln',
    city: 'La Vergne',
    state: 'TN',
    zip_code: '37086',
  },
  TX: {
    line_one: '7575 Saddleridge Dr',
    city: 'Dallas',
    state: 'TX',
    zip_code: '75249',
  },
  UT: {
    line_one: '204 E Southgate Ave',
    city: 'South Salt Lake',
    state: 'UT',
    zip_code: '84115',
  },
  VT: {
    line_one: '1213 Richardson Road',
    city: 'Corinth',
    state: 'VT',
    zip_code: '05039',
  },
  VA: {
    line_one: '2705 Stacie Rd',
    city: 'Richmond',
    state: 'VA',
    zip_code: '23224',
  },
  WA: {
    line_one: '762 Queen Avenue NE',
    city: 'Renton',
    state: 'WA',
    zip_code: '98056',
  },
  WV: {
    line_one: '1206 Salisbury Rd',
    city: 'Ivydale',
    state: 'WV',
    zip_code: '25113',
  },
  WI: {
    line_one: '7870 Wheeler Island Rd',
    city: 'Three Lakes',
    state: 'WI',
    zip_code: '54562',
  },
  WY: {
    line_one: '1427 Tumbleweed Dr',
    city: 'Casper',
    state: 'WY',
    zip_code: '82601',
  },
};
