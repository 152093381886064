import { useHistory, useLocation } from 'react-router-dom';
import { useFeature as useOptimizelyFeature } from '@optimizely/react-sdk';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

export const useQuery = () => new URLSearchParams(useLocation().search);

export const useFeature = (
  featureKey: string
): [boolean, { [key: string]: string }, boolean] => {
  const [isEnabled, setIsEnabled] = useState(false);
  const [variables, setVariables] = useState({});
  const location = useLocation();
  const history = useHistory();
  const query = useMemo(() => new URLSearchParams(location.search), [location]);
  const urlParam = query.get(featureKey);
  const uuid = useMemo(() => uuidv4(), []);
  const [optimizelyEnabled, optimizelyVariables, isReady, didTimeout] =
    useOptimizelyFeature(
      featureKey,
      { autoUpdate: true },
      { overrideUserId: `${featureKey}:${uuid}` }
    );
  const debug = useCallback(
    (...messages: any[]) =>
      console.debug(`[useFeature:${featureKey}]`, ...messages),
    [featureKey]
  );

  useEffect(() => {
    if (urlParam) {
      const isUrlParamEnabled = urlParam === 'true';
      debug('setting from URL:', isUrlParamEnabled);
      const variablesParam = query.get('variables');
      let urlVariables = {};

      if (variablesParam !== null) {
        try {
          urlVariables = JSON.parse(variablesParam);
          debug('URL variables:', urlVariables);
        } catch (e) {
          history.push('/error?errorType=feature_variables_malformed');
        }
      }

      setIsEnabled(isUrlParamEnabled);
      setVariables(urlVariables);
    } else {
      debug('setting from Optimizely:', optimizelyEnabled);
      setIsEnabled(optimizelyEnabled);
      setVariables(optimizelyVariables);
    }
  }, [optimizelyEnabled, optimizelyVariables, urlParam, history, debug, query]);

  return [isEnabled, variables, !isReady && !didTimeout];
};
