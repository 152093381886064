import React from 'react';
import styles from './styles.module.css';
import { useSearch } from './SearchHooks';
import { Form } from './Form';
import { NotFound } from './NotFound';
import { Logo } from 'V2/Shared/Logo';

export const Search: React.FC = () => {
  const {
    isSubmitted,
    searchStatus,
    isCreatingQuote,
    onFormSubmit,
    onNewQuoteClick,
    onNewSearchClick,
  } = useSearch();

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.logo}>
          <Logo useMarkOnMobile />
        </div>
        <div className={styles.title}>Quote Search</div>
        <div className={styles.spacer} />
      </div>
      {searchStatus === 'quoteNotFound' ? (
        <NotFound
          onNewQuote={onNewQuoteClick}
          isCreatingQuote={isCreatingQuote}
          onNewSearch={onNewSearchClick}
        />
      ) : (
        <Form
          isSubmitted={isSubmitted}
          onFormSubmit={onFormSubmit}
          isCreatingQuote={isCreatingQuote}
          onNewQuoteClick={onNewQuoteClick}
          searchStatus={searchStatus}
        />
      )}
    </div>
  );
};
