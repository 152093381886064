import React from 'react';
import { Formik, ErrorMessage } from 'formik';
import { monthDateYearNormalizer } from 'V2/Shared/FormNormalizers/MonthDateYear';
import { StepForm, Content, InfoSection } from 'V2/Shared/StepForm';
import { FieldError } from 'V2/Shared/FieldError';
import { Input } from 'V2/Shared/Form';
import { PiiIgnore } from 'V2/Shared/PiiIgnore';
import { configDefault } from 'V2/Step';
import { Schema } from './Schema';
import { FormType } from './types';
import styles from './styles.module.css';
import { env } from 'constants/env';
import { Button } from 'V2/Shared/Button';
import { useIsMobile } from '../Shared/Helpers';

const description = {
  label: '',
  description: null,
  type: {
    id: 'info',
    sub_type: null,
  },
  value: null,
  ctas: [],
  group_id: '',
};

type SearchFormContent = {
  title: string;
  body: string;
  button: string;
  second_option: string;
};

const defaultContent = {
  title: 'Retrieve your previous quote.',
  body: 'This information will allow you to continue where you left off.',
  button: 'Email Me A New Link',
  second_option: 'Start a New Quote',
};

const submittedContent = {
  title: 'Your new link has been sent.',
  // line breaks in body string for multiple line subtitle
  body: `We've sent an email with a link to your saved quote.

  Links to retrieve your quote are set to expire after 15 minutes.`,
  button: 'Start a New Quote',
  second_option: 'Try a New Search',
};

const expiredContent = {
  title: 'Oops! This link has expired.',
  // line breaks in body string for multiple line subtitle
  body: `To confirm your identity, please provide us with the following information and we will email you a new link.
  
  Links to retrieve your quote are set to expire after 15 minutes.`,
  button: 'Email Me A New Link',
  second_option: 'Start a New Quote',
};

const getContent = (
  isSubmitted: boolean,
  isExpired: boolean
): SearchFormContent => {
  if (isSubmitted) {
    return submittedContent;
  }

  if (isExpired) {
    return expiredContent;
  }

  return defaultContent;
};

export const Form: React.FC<FormType> = ({
  onFormSubmit,
  onNewQuoteClick,
  isCreatingQuote,
  isSubmitted,
  searchStatus,
}) => {
  const renderError = (fieldName: string) => (
    <ErrorMessage name={fieldName}>
      {(message) => (
        <FieldError field={fieldName} message={message}>
          {message}
        </FieldError>
      )}
    </ErrorMessage>
  );
  const isExpired = searchStatus === 'linkExpired';
  const content = getContent(isSubmitted, isExpired);
  const subtitle = {
    ...description,
    label: content.body,
  };
  const isMobile = useIsMobile();

  return (
    <Formik
      validateOnChange={false}
      initialValues={{
        last_name: '',
        email: '',
        zip_code: '',
        date_of_birth: '',
      }}
      onSubmit={onFormSubmit}
      validationSchema={Schema}
    >
      {({ handleSubmit, isSubmitting }) => (
        <StepForm onSubmit={handleSubmit}>
          <div className={isSubmitted ? styles.info : undefined}>
            <InfoSection
              config={{
                ...configDefault,
                title_text: content.title,
                step_descriptions: [subtitle],
              }}
            />
          </div>
          {!isSubmitted && (
            <Content config={configDefault}>
              <PiiIgnore>
                <div className={styles.field}>
                  <Input name="last_name" label="Last Name" />
                  {renderError('last_name')}
                </div>
                <div className={styles.field}>
                  <Input name="email" label="Email Address" />
                  {renderError('email')}
                </div>
                <div className={styles.field}>
                  <Input
                    name="zip_code"
                    label="Zip Code"
                    normalize={(value) => value.replace(/[^\d]/g, '')}
                  />
                  {renderError('zip_code')}
                </div>
                <div className={styles.field}>
                  <Input
                    name="date_of_birth"
                    label="Date of Birth"
                    placeholder="mm/dd/yyyy"
                    normalize={monthDateYearNormalizer}
                  />
                  {renderError('date_of_birth')}
                </div>
              </PiiIgnore>
            </Content>
          )}
          <Button
            type={isSubmitted ? 'button' : 'submit'}
            data-testid={isSubmitted ? 'start-quote' : undefined}
            width={isMobile ? 'fullWidth' : 'block'}
            onClick={isSubmitted ? onNewQuoteClick : undefined}
            isSubmitting={isSubmitted ? isCreatingQuote : isSubmitting}
          >
            {content.button}
          </Button>
          <div className={styles.generate}>
            <Button
              data-testid={isSubmitted ? undefined : 'start-quote'}
              variant="link"
              onClick={
                isSubmitted
                  ? () => window.location.replace('/search')
                  : onNewQuoteClick
              }
              isSubmitting={isSubmitted ? undefined : isCreatingQuote}
            >
              {content.second_option}
            </Button>
            {(env.nodeEnv === 'development' ||
              env.clearcoverEnv === 'ephemeral' ||
              env.clearcoverEnv === 'staging') && (
              <Button
                data-testid="generate-quote"
                variant="link"
                onClick={() => window.location.replace('/quote')}
                isSubmitting={false}
              >
                Generate Quote
              </Button>
            )}
          </div>
        </StepForm>
      )}
    </Formik>
  );
};
