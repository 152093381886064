import React, { ChangeEvent } from 'react';
import { Field } from 'formik';
import styles from './styles.module.css';
import { FieldRenderProps } from '../types';
import { RadioType } from './types';

export const Radio: React.FC<RadioType> = ({
  name,
  options,
  variant,
  onChange,
  ...inputProps
}) => {
  return (
    <div
      className={variant === 'flat' ? styles.flat : styles.main}
      data-testid="radio"
    >
      <Field name={name}>
        {({ field }: FieldRenderProps) => (
          <>
            {options.map((option, i) => (
              <div
                className={styles.radio}
                key={i}
                data-testid={`${name}_${option.value}`}
              >
                <input
                  {...field}
                  {...inputProps}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    // optional onChange prop
                    if (onChange) {
                      onChange(event);
                    }

                    // formik onChange
                    field.onChange(event);
                  }}
                  data-testid="input"
                  id={`${name}_${option.value}`}
                  className={styles.input}
                  value={`${option.value}`}
                  type="radio"
                  checked={field.value === option.value}
                />
                <label
                  className={styles.label}
                  htmlFor={`${name}_${option.value}`}
                  data-testid="label"
                >
                  <div className={styles.box} />
                  <div>
                    {option.label}
                    {option.description && (
                      <div className={styles.description}>
                        {option.description}
                      </div>
                    )}
                  </div>
                </label>
              </div>
            ))}
          </>
        )}
      </Field>
    </div>
  );
};
