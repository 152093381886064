import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { Button } from 'V2/Shared/Button';
import { Disclaimer } from 'V2/Shared/Disclaimer';
import { Lock } from 'V2/Shared/Icons/Lock';
import { buildFieldDataPayload } from 'V2/Step/Helpers';
import { FieldDataType } from 'V2/Types';
import { useApi } from 'V2/Api';
import { useApplyProvider, useSubmit } from 'V2/Apply';

import { useProcessOneScripts } from '../hooks';
import { PaymentContext } from '../PaymentContext';
import {
  ProcessOneCreditCardSubmitType,
  ProcessOneACHSubmitType,
  ProcessOnePropsType,
} from '../types';

declare global {
  interface Window {
    $: any;
  }
}

const processOneContainerId = 'portalOneContainer';
const processOneContainerSelector = `#${processOneContainerId}`;

const processOnePaymentMethodMap: Record<string, string> = {
  credit_card: 'CreditCard',
  bank_account: 'ECheck',
};

export const ProcessOne: FC<ProcessOnePropsType> = ({
  isValid,
  paymentMethodChosen,
  paymentConfig,
  setErrors,
}) => {
  const { scriptLoaded } = useProcessOneScripts();
  const [saveComplete, setSaveComplete] = useState<boolean>(false);
  const [hadApiError, setHadApiError] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const { fields, isDeactivating, documents, isSubmitting, setIsSubmitting } =
    useContext(PaymentContext);

  const {
    app: {
      status: { current_step_submit_uri: submitUri },
    },
  } = useApplyProvider();

  const { submit, setStep: setStepSubmit } = useSubmit();
  const { postResource, error: apiError } = useApi();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setStep = useCallback(setStepSubmit, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const submitForm = useCallback(submit, []);
  const submitToken = useCallback(
    (data) => postResource({ url: paymentConfig.submit_uri, data }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [paymentConfig]
  );

  useEffect(() => {
    // Ensure that the checkout button continues to be submitting until navigation or error
    if (!modalOpen && !saveComplete) {
      setIsSubmitting(false);
    }
  }, [modalOpen, setIsSubmitting, saveComplete]);

  useEffect(() => {
    // the ErrorBanner sets the apiError to null after 30s
    // however this button should still be disabled if there was an error generating the payment config
    if (apiError) {
      setHadApiError(true);
    }
  }, [apiError]);

  const openProcessOne = async () => {
    if (!paymentConfig) return;

    try {
      window
        .$(processOneContainerSelector)
        .on('portalOne.load', () => setModalOpen(true));

      window
        .$(processOneContainerSelector)
        .on('portalOne.unload', () => setModalOpen(false));

      window
        .$(processOneContainerSelector)
        .portalOne()
        .data('portalOne')
        .savePaymentMethod({
          paymentCategory: processOnePaymentMethodMap[paymentMethodChosen],
          confirmationDisplay: 'false',
          sessionId: paymentConfig.session_key,
        });

      window
        .$(processOneContainerSelector)
        .on('portalOne.saveComplete', async function (_e: Event, data: any) {
          setSaveComplete(true);
          const commonSubmitFields: ProcessOneACHSubmitType = {
            number_last_four: data.lastFourDigits,
            one_inc_payment_token: data.tokenId,
          };

          const submitFields:
            | ProcessOneCreditCardSubmitType
            | ProcessOneACHSubmitType =
            paymentMethodChosen === 'bank_account'
              ? { ...commonSubmitFields }
              : {
                  ...commonSubmitFields,
                  card_expiration_month: data.cardExpirationMonth,
                  card_expiration_year: data.cardExpirationYear,
                  card_holder_name: data.customerName,
                  card_holder_zip: data.holderZip,
                  credit_card_type: data.cardType,
                };

          const nonSignatureFields = fields.filter(
            (f) => f.entity_type.id !== 'signature'
          );

          const documentFields = documents.flatMap((doc) => doc.field_data);

          const fieldData = [...nonSignatureFields, ...documentFields];

          const tokenPayload = {
            field_data: paymentConfig.field_data.map((f: FieldDataType) => ({
              ...f,
              value: Object.keys(submitFields).includes(f.field_id)
                ? submitFields[f.field_id]
                : f.value,
            })),
          };

          submitToken(tokenPayload)
            .then(
              (tokenResponse: { data: { field_data: FieldDataType[] } }) => {
                const paymentToken = tokenResponse.data.field_data.find(
                  (f) => f.field_id === 'payment_token'
                );

                const submitPayload = buildFieldDataPayload(
                  fieldData.map((f) => {
                    if (f.field_id === 'payment_token') {
                      return {
                        ...f,
                        value: paymentToken!.value,
                      };
                    } else {
                      return f;
                    }
                  })
                );

                if (paymentToken && paymentToken.errors.length > 0) {
                  setErrors(paymentToken.errors);
                  setIsSubmitting(false);
                } else {
                  submitForm(submitUri, submitPayload)
                    .then(setStep)
                    .catch(() => {
                      setIsSubmitting(false);
                    });
                }
              }
            )
            .catch(() => {
              setIsSubmitting(false);
            });
        });
    } catch {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Button
        data-testid="make-process-one-payment"
        disabled={isDeactivating || !scriptLoaded || hadApiError}
        width="fullWidth"
        size="lg"
        type="button"
        isSubmitting={isSubmitting || (!paymentConfig && !hadApiError)}
        onClick={() => {
          setIsSubmitting(true);
          if (isValid) {
            openProcessOne();
          } else {
            // show error on signature checkbox
            setTimeout(() => setIsSubmitting(false), 0);
          }
        }}
      >
        <span>
          <Lock />
          {'  '}
          Check Out Securely
        </span>
      </Button>
      <span id={processOneContainerId} />
      <Disclaimer>
        <>
          We partner with{' '}
          <a href="https://www.oneinc.com/" target="_blank" rel="noreferrer">
            One Inc.
          </a>
          , a leading digital insurance payment network.
        </>
      </Disclaimer>
    </>
  );
};
