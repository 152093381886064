import React, { useState, createContext } from 'react';
import { StatusType } from 'V2/Types';
import { slideLeft, AnimationConfigType } from 'V2/Shared/Animations';
import { contextDefaults } from 'V2/Step';
import { EditContextType, EditStepType, SetEditStepType } from './types';

export const useEdit = (): [EditStepType, SetEditStepType] => {
  const [step, setStep] = useState<EditStepType>({
    status: contextDefaults.status,
    config: contextDefaults.config,
    fields: [],
  });

  return [step, setStep];
};

export const EditContext = createContext<EditContextType>({
  animation: {},
  statuses: [],
  setStatuses: () => {},
  setAnimation: () => {},
  step: {
    status: contextDefaults.status,
    config: contextDefaults.config,
    fields: [],
  },
  setStep: () => {},
});

export const EditProvider: React.FC = ({ children }) => {
  const [statuses, setStatuses] = useState<StatusType[]>([]);
  const [animation, setAnimation] = useState<AnimationConfigType>(slideLeft);
  const [step, setStep] = useEdit();

  return (
    <EditContext.Provider
      value={{
        step,
        statuses,
        animation,
        setStatuses,
        setAnimation,
        setStep,
      }}
    >
      {children}
    </EditContext.Provider>
  );
};
