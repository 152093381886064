export const summaryDefaults = {
  commands: [],
  entities: [
    {
      id: '',
      type: {
        id: 'driver',
        sub_type: 'primary',
      },
      label: 'Driver 1',
      description: null,
      detail_uri: null,
      commands: [],
      parent_entity_id: '',
      editable: false,
    },
    {
      id: '',
      type: {
        id: 'vehicle',
        sub_type: 'passenger',
      },
      label: 'Vehicle 1',
      description: null,
      detail_uri: null,
      commands: [],
      parent_entity_id: '',
      editable: false,
    },
    {
      id: '',
      type: {
        id: 'coverage',
        sub_type: null,
      },
      label: 'Coverages',
      description: null,
      detail_uri: null,
      commands: [],
      parent_entity_id: '',
      editable: false,
    },
  ],
};

export const pollingSteps = [
  'heal_coverages',
  'payment_plans_splash',
  'quote_load_enhanced',
  'drivers_data_enhancement',
  'checkout_pending',
  'choice_quote_load',
];

// If the step should fade in instead
// of slide in: put it here, however,
// if a "fade-in" step should require
// the blue bar (header), be sure to
// also put it in headerRequiredSteps.
export const fadeInSteps = [
  'lead_splash',
  'driver_splash',
  'vehicle_splash',
  'out_of_market',
  'out_of_market_thank_you',
  'confirm_quote',
  'heal_coverages',
  'quote_load_enhanced',
  'quote_load_splash',
  'coverages',
  'primary_driver_specific_partner',
  'checkout_pending',
  'congrats',
  'payment_plans_splash',
  'selected_payment_plan',
  'document_signatures',
  'payment_details',
  'confirm_rate_change',
  'stopped',
  'agent_unauthorized',
  'choice_quote_load',
];

// Explicit list of steps where the header
// is required regardless of any other settings.
export const headerRequiredSteps = ['selected_payment_plan'];

// Some steps are not fade in steps, but should not have the header.
// Payment Details is one example.
export const stepsWithoutHeader = [
  ...fadeInSteps.filter((step) => !headerRequiredSteps.includes(step)),
  'coverage_packages',
  'payment_details',
  'choice_panel',
  'choice_after_consumer_splash',
  'choice_no_quote',
];

export const stepsWithoutToyotaFooter = ['choice_quote_load'];
