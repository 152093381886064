import React from 'react';

export const CircleChevronDown = ({
  alt = 'arrow',
  className,
}: {
  alt?: string;
  className?: string;
}) => (
  <svg
    className={className}
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    aria-labelledby="circleChevronDownId"
  >
    <title id="circleChevronDownId">{alt}</title>
    <g filter="url(#filter0_d)">
      <circle className="fill-brand-01" cx="17" cy="16" r="11" />
    </g>
    <path
      className="fill-brand-03"
      d="M12.1406 13.913C12.5015 13.548 13.0909 13.548 13.4518 13.913L17 17.5024L20.5483 13.913C20.9091 13.548 21.4986 13.548 21.8594 13.913C22.2144 14.2721 22.2144 14.8499 21.8594 15.2091L17.7112 19.4054C17.3198 19.8014 16.6803 19.8014 16.2889 19.4054L12.1406 15.2091C11.7857 14.8499 11.7857 14.2721 12.1406 13.913Z"
    />
    <mask
      id="mask0"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="11"
      y="13"
      width="12"
      height="7"
    >
      <path
        d="M12.1406 13.913C12.5015 13.548 13.0909 13.548 13.4518 13.913L17 17.5024L20.5483 13.913C20.9091 13.548 21.4986 13.548 21.8594 13.913C22.2144 14.2721 22.2144 14.8499 21.8594 15.2091L17.7112 19.4054C17.3198 19.8014 16.6803 19.8014 16.2889 19.4054L12.1406 15.2091C11.7857 14.8499 11.7857 14.2721 12.1406 13.913Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0)">
      <rect
        className="fill-brand-03"
        x="-56"
        y="-56"
        width="147"
        height="147"
      />
    </g>
    <defs>
      <filter
        id="filter0_d"
        x="0"
        y="0"
        width="34"
        height="34"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="3" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.196643 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
