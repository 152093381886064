import styled, {
  css,
  FlattenSimpleInterpolation,
} from 'styled-components/macro';
import { Size, Variant, Width } from './Button';
import { ButtonColorDefinition } from './colorDefinitions';

export const common = css`
  background-image: none;
  font-weight: var(--button-font-weight);
  cursor: pointer;
  display: block;
  font-size: 14px;
  line-height: 16px;
  margin: 0;
  //this can be removed after rebrand ... there are still some styles that are overriding based on .asdf button type styling
  padding: 0 !important;
  position: relative;
  font-family: var(--button-font-family);
  text-align: center;
  text-decoration: none;
  text-transform: var(--button-text-transform);
  touch-action: manipulation;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  width: auto;
  border-radius: 6px;
`;

const mapSizeToStyles: {
  [key in Size]: any;
} = {
  inline: css`
    padding: 2px;
    font-size: var(--font-size-12);
    line-height: 1;
  `,
  xs: css`
    padding: 6px 12px;
    font-size: var(--font-size-12);
    line-height: 1;
  `,
  sm: css`
    padding: 8px 16px;
    font-size: var(--font-size-14);
    line-height: 1;
  `,
  md: css`
    // OnUpgrade: 16px font
    padding: 14px 16px;
    font-size: var(--font-size-14);
    line-height: 1;
  `,
  lg: css`
    // OnUpgrade: 16px font
    font-size: var(--font-size-14);
    line-height: 1;
    padding: 16px 16px;
  `,
  xl: css`
    // OnUpgrade: 16px font
    font-size: var(--font-size-16);
    line-height: 1;
    padding: 18px 16px;
  `,
};

const mapWidthToStyles: {
  [key in Width]: FlattenSimpleInterpolation;
} = {
  fullWidth: css`
    width: 100%;
  `,
  block: css`
    width: 320px;
  `,
  normal: css`
    width: auto;
  `,
};

export type StyledButtonType = {
  size: Size;
  width: Width;
  definition: any;
  isDarkBackground: boolean;
};

/**
 * explicitly adding transitions because we don't want random props transitioning
 */
export const transitionEaseIn = css`
  transition: border 100ms ease-in, box-shadow 100ms ease-in,
    background 100ms ease-in, transform 90ms ease-in, color 100ms ease-in;
`;

export const transitionEaseOut = css`
  transition: border 100ms ease-out, box-shadow 100ms ease-out,
    background 100ms ease-out, transform 90ms ease-out, color 100ms ease-out;
`;

export type StyledButtonProps = { isDarkBackground: boolean };

export const StyledButton = styled.button<StyledButtonProps>``;

export const getButtonStyles = (
  colorDefinition: ButtonColorDefinition,
  variant: Variant,
  width: Width,
  size: Size,
  cssOverride?: FlattenSimpleInterpolation
) => {
  return css`
    ${common};
    transform: translateY(1px);

    ${mapWidthToStyles[width]};
    background: ${colorDefinition.shadowColor};
    color: ${colorDefinition.color};
    ${transitionEaseIn};
    border: none;
    box-shadow: 0 0 0 2px ${colorDefinition.screenColor};

    & > * {
      ${mapSizeToStyles[size]};
      display: block;
      width: 100%;
      height: 100%;
      background: ${colorDefinition.backgroundColor};
      border-radius: 6px;
      border: 1px solid ${colorDefinition.borderColor};
      transform: translateY(-1px);
      ${transitionEaseIn};
      will-change: transform;
    }

    &.grouped:first-child > * {
      border-color: ${colorDefinition.borderColor};
      border-style: solid none solid solid;
      border-width: 1px;
      border-radius: 0px;

      &:hover {
        border-style: solid;
      }
    }

    &.grouped:not(:first-child):not(:last-child) > * {
      border-color: ${colorDefinition.borderColor};
      border-style: solid;
      border-width: 1px;
      border-radius: 0px;
    }

    &.grouped:last-child > * {
      border-color: ${colorDefinition.borderColor};
      border-style: solid solid solid none;
      border-width: 1px;
      border-radius: 0px;

      &:hover {
        border-style: solid;
      }
    }

    &:hover {
      background: ${colorDefinition.interactive.shadowColor
        ? colorDefinition.interactive.shadowColor
        : colorDefinition.shadowColor};
      & > * {
        background: ${colorDefinition.interactive.backgroundColor};
        border: 1px solid ${colorDefinition.interactive.borderColor};
      }
    }

    &:focus {
      z-index: 1;
      background: ${colorDefinition.interactive.shadowColor
        ? colorDefinition.interactive.shadowColor
        : colorDefinition.shadowColor};
      ${transitionEaseOut};
      & > * {
        ${transitionEaseOut};
        background: ${colorDefinition.interactive.backgroundColor};
        border: 1px solid ${colorDefinition.interactive.borderColor};
      }
    }

    &:focus:not(:focus-visible) {
      z-index: 1;
      background: ${colorDefinition.interactive.shadowColor
        ? colorDefinition.interactive.shadowColor
        : colorDefinition.shadowColor};
      box-shadow: none;
      & > * {
        ${transitionEaseOut};
        background: ${colorDefinition.backgroundColor};
        border: 1px solid ${colorDefinition.borderColor};
        box-shadow: none;
      }
    }
    &:active {
      z-index: 1;
      background: ${colorDefinition.interactive.shadowColor
        ? colorDefinition.interactive.shadowColor
        : colorDefinition.shadowColor};
      & > * {
        ${transitionEaseOut};
        transform: translateY(0px);
      }
    }

    &:disabled[data-submitting='false'] {
      background: ${colorDefinition.disabled.backgroundColor};
      border: 1px solid ${colorDefinition.disabled.backgroundColor};
      box-shadow: 0 0 0 2px ${colorDefinition.screenColor};

      & > * {
        background: ${colorDefinition.disabled.backgroundColor};
        color: ${colorDefinition.disabled.color};
        border: 1px solid ${colorDefinition.disabled.borderColor};
        transform: translateY(0px);
      }
    }
    &:disabled[data-submitting='true'] {
      background: ${colorDefinition.shadowColor};
      color: ${colorDefinition.color};
      ${transitionEaseIn};
      border: none;
      box-shadow: 0 0 0 2px ${colorDefinition.screenColor};

      & > * {
        background: ${colorDefinition.backgroundColor};
        border: 1px solid ${colorDefinition.borderColor};
        transform: translateY(-1px);
      }
    }
    ${cssOverride}
  `;
};
