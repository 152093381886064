import React from 'react';
import { Spinner } from '../Spinner';
import { AnimatePresence, motion } from 'framer-motion';
import styled, { FlattenSimpleInterpolation } from 'styled-components/macro';
import { getButtonStyles, StyledButton } from './styles';
import { getButtonColors } from './colorDefinitions';
export type Variant = 'contained' | 'outlined' | 'link';
export type Color = 'primary' | 'secondary' | 'danger';
export type Size = 'inline' | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
export type Width = 'normal' | 'block' | 'fullWidth';

export type ButtonTypes = {
  variant?: Variant;
  size?: Size;
  color?: Color;
  width?: Width;
  isOnDarkBackground?: boolean;
  disabled?: boolean;
  isSubmitting?: boolean;
  onClick?: () => void;
  type?: 'button' | 'submit';
  'data-testid'?: string;
  cssOverride?: FlattenSimpleInterpolation;
  className?: string;
};

const PositionalSpan = styled(motion.span)`
  position: relative;
  display: inline-block;
`;

const SpinnerContainer = styled(motion.div)`
  position: absolute;
  margin: 0 auto;
  left: 0;
  right: 0;
`;

export const Button: React.FC<ButtonTypes> = ({
  variant = 'contained',
  size = 'lg',
  color = 'primary',
  isSubmitting = false,
  disabled = false,
  width = 'normal',
  type = 'button',
  onClick,
  children,
  isOnDarkBackground = false,
  'data-testid': dataTestid,
  cssOverride,
  className,
}) => {
  const buttonColors: any = getButtonColors(color, variant);
  const buttonStyles: any = getButtonStyles(
    buttonColors,
    variant,
    width,
    size,
    cssOverride
  );

  return (
    <StyledButton
      type={type}
      data-testid={dataTestid ? dataTestid : 'button'}
      data-submitting={isSubmitting}
      onClick={onClick}
      disabled={disabled || isSubmitting}
      isDarkBackground={isOnDarkBackground}
      css={buttonStyles}
      className={
        className
          ? `cc-button ${className} cc-button__${variant} cc-button__${color}`
          : `cc-button cc-button__${variant} cc-button__${color}`
      }
    >
      <div>
        <AnimatePresence>
          {isSubmitting && (
            <SpinnerContainer
              initial={{ opacity: 0 }}
              animate={{ opacity: [0, 0, 1] }}
              exit={{ opacity: [1, 0, 0] }}
              transition={{ type: 'spring', duration: 0.4 }}
              key={'loader'}
              data-testid="spinner"
            >
              <Spinner color={buttonColors.color} />
            </SpinnerContainer>
          )}
        </AnimatePresence>
        <PositionalSpan
          initial={{ opacity: 1 }}
          animate={{ opacity: isSubmitting ? [1, 0, 0] : 1 }}
          transition={{ type: 'spring', duration: 0.4 }}
        >
          {children}
        </PositionalSpan>
      </div>
    </StyledButton>
  );
};
