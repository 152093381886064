import React from 'react';

export const Print = () => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="82"
    height="82"
    viewBox="0 0 193 192"
    role="img"
    aria-labelledby="printTitle"
  >
    <title id="printTitle">print</title>
    <path
      className="fill-brand-01"
      d="M84 78c0 3.3.3 4 2 4 1.3 0 2-.7 2-2 0-1.8.7-2 8-2s8 .2 8 2c0 1.3.7 2 2 2 1.7 0 2-.7 2-4v-4H84v4zM82.5 84.9c-4.5 2-6 4.9-6.4 13.2l-.3 7.9H84v8h24v-8h8.2l-.4-8c-.3-7.1-.6-8.3-3.1-10.7-2.7-2.7-3-2.8-15.5-3-7-.2-13.6.1-14.7.6zM110 90c1.5 1.5 2 3.3 2 7 0 4.3-.3 5-2 5s-2-.7-2-4v-4H84v4c0 3.3-.3 4-2 4s-2-.7-2-5c0-8.2 1.5-9 16-9 10.7 0 12.2.2 14 2zm-6 14v6H88V98h16v6z"
    />
  </svg>
);
