import React, { useEffect } from 'react';
import styles from './styles.module.css';
import { useApi } from 'V2/Api';

export type ErrorBannerPropsType = {
  title: string;
  body: string;
};

export const ErrorBanner: React.FC<ErrorBannerPropsType> = ({
  body,
  title,
}) => {
  const { setError } = useApi();

  useEffect(() => {
    const timeout = setTimeout(() => setError(null), 30000);
    return () => clearTimeout(timeout);
  }, [setError]);

  return (
    <div className={styles.container} data-testid="error-banner">
      <div className={styles.title}>{title}</div>
      <div className={styles.content}>{body}</div>
      <div className={styles.control}>
        <button
          type="button"
          onClick={() => window.location.reload()}
          className={styles.cta}
          data-testid="button"
        >
          Refresh
        </button>
      </div>
    </div>
  );
};
