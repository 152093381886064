import React, { Suspense, useEffect, useState } from 'react';
import { FullPageLoading } from 'V2/Shared/FullPageLoading';
import { StepType } from 'V2/Apply';
import { Nullable } from 'V2/Types';

export const convertComponentName = (stepName: string) =>
  stepName
    .replace('primary_', '')
    .split('_')
    .map((word: string) => `${word.charAt(0).toUpperCase()}${word.slice(1)}`)
    .join('');

export const DynamicStepLoader: React.FC<StepType> = (props) => {
  const {
    status: { id: stepName },
  } = props;

  const [LazyStep, SetLazyStep] = useState<Nullable<React.FC<StepType>>>(null);
  useEffect(() => {
    SetLazyStep(
      React.lazy(() =>
        import(`V2/Steps/${convertComponentName(stepName)}`).catch(
          (e: string) => {
            // We had a problem where when the environment (such as production)
            // is updated while a user is on the app, the user would run into an
            // error when attempting to access the next step. This is due to the
            // fact that the build chunks are renamed during this process and so
            // since we are lazy-loading our steps.. the browser would look for a
            // chunk name that no longer exists. So this fixes that problem by
            // refreshing the browser for the user when this occurs. Visit this
            // url for an outside example:
            // https://medium.com/@kamrankhatti/angular-lazy-routes-loading-chunk-failed-42b16c22a377
            const chunkError = /Loading [" "a-zA-Zchunk]+ [\d]+ failed/.test(
              `${e}`
            );
            if (chunkError) {
              window.location.reload();
            } else {
              return import('V2/Steps/Default');
            }
          }
        )
      )
    );
  }, [stepName]);

  return (
    <Suspense fallback={<FullPageLoading />}>
      {LazyStep && (
        <>
          <LazyStep {...props} />
          {props.children}
        </>
      )}
    </Suspense>
  );
};
