import React from 'react';
import cx from 'classnames';
import { motion, AnimatePresence } from 'framer-motion';
import styles from './styles.module.css';

export const BrandModal: React.FC<{
  showModal: boolean;
  className?: string;
}> = ({ showModal, className, children }) => (
  <AnimatePresence>
    {showModal && (
      <motion.div
        key="modal-animation"
        className={styles.main}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <div className={styles.overlay} />
        <div className={cx(styles.container, className)}>{children}</div>
      </motion.div>
    )}
  </AnimatePresence>
);
