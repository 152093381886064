import { GetCommandsType, GetEntitiesType, FindEntitiesType } from './types';

/*
provides a quick interface to get commands from summary;
there are many instances where we know the command exists and we
just want to grab it; for now its easier to just get rate
and not have to call summary.find().. then check existence of the command;

input...

[{ id: 'rate', uri: 'uri' }]

output...

{
  rate: {
    id: 'rate',
    uri: 'uri',
  },
}

*/
export const getCommands: GetCommandsType = (commands) =>
  commands.reduce(
    (commands, command) => ({
      ...commands,
      [command.id]: command,
    }),
    {}
  );

/*
this reduces summary entities from an array to a hash; this does NOT
take into account the fact that there can be N number of entities; in this case
we are only working with 1 quote, 1 rate, 1 payment_plan, so this is a quick
way to GET the one entity we know we want; future optimization is to find a proper
contract for this using parent_entity_id, etc.

input...

[
  { type: { id: 'rate' }, label: 'Rate' },
  { type: { id: 'quote' }, label: 'Quote' }
]

output...

{
  rate: {
    label: 'Rate',
    type: {
      id: 'rate',
    },
  },
  quote: {
    label: 'Quote',
    type: {
      id: 'quote',
    },
  },
}
*/
export const getEntities: GetEntitiesType = (entities) =>
  entities.reduce((entities, entity) => {
    return {
      ...entities,
      [entity.type.id]: entity,
    };
  }, {});

/*
this is similar to the getEntities, but it returns N number of entities for
a given entity; for example, we know drivers/vehicles return multiple entities
that we know we want to work upon; if you know you want all entities, use FIND;
again, not ideal but this allows us to move quickly and re-iterate upon.

input...

[
  { type: { id: 'driver' }, label: 'Driver 1' },
  { type: { id: 'driver' }, label: 'Driver 2' },
  { type: { id: 'vehicle' }, label: 'Vehicle 1' },
  { type: { id: 'vehicle' }, label: 'Vehicle 2' },
]

output...

{
  driver: [
    { type: { id: 'driver' }, label: 'Driver 1' },
    { type: { id: 'driver' }, label: 'Driver 2' },
  ],
  vehicle: [
    { type: { id: 'vehicle' }, label: 'Vehicle 1' },
    { type: { id: 'vehicle' }, label: 'Vehicle 2' },
  ]
}
*/
export const findEntities: FindEntitiesType = (entities, ids) =>
  ids.reduce((allEntities, entity) => {
    return {
      ...allEntities,
      [entity]: entities.filter((e) => e.type.id === entity),
    };
  }, {});
