import React, {
  createContext,
  useState,
  useCallback,
  useContext,
  useEffect,
} from 'react';
import { useApplyProvider } from 'V2/Apply';
import { PaymentProviderType, PaymentHandler } from './types';
import { FieldDataType, DocumentSignatureType } from 'V2/Types';

export const PaymentContext: React.Context<PaymentProviderType> = createContext(
  {} as PaymentProviderType
);

export const PaymentProvider: React.FC = ({ children }) => {
  const [formLoaded, setFormLoaded] = useState(false);
  const [adyen, setAdyen] = useState<PaymentHandler>({ submit: () => {} });
  const [ach, setAch] = useState<PaymentHandler>({ submit: () => {} });
  const [formValid, setFormValid] = useState(false);
  const [isDeactivating, setIsDeactivating] = useState(false);
  const [documents, setDocuments] = useState<DocumentSignatureType[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [ccLegacy, setCcLegacy] = useState<PaymentHandler>({
    submit: () => {},
  });

  const [fields, setFields] = useState<FieldDataType[]>([]);
  const [paymentPlanId, setPaymentPlanId] = useState<string>('');
  const [paymentMethod, setPaymentMethod] = useState<string>('credit_card');

  const {
    app: {
      status: { id: stepName },
    },
  } = useApplyProvider();

  const deactivate = useCallback(() => {
    setAch({ submit: () => {} });
    setAdyen({ submit: () => {} });
    setCcLegacy({ submit: () => {} });
    setIsSubmitting(false);
    setFormValid(false);
  }, []);

  useEffect(() => {
    if (stepName !== 'payment_details') {
      deactivate();
    }
  }, [stepName, deactivate]);

  return (
    <PaymentContext.Provider
      value={{
        adyen,
        setAdyen,
        ach,
        setAch,
        ccLegacy,
        setCcLegacy,
        formLoaded,
        setFormLoaded,
        formValid,
        setFormValid,
        deactivate,
        fields,
        setFields,
        isDeactivating,
        setIsDeactivating,
        documents,
        setDocuments,
        isSubmitting,
        setIsSubmitting,
        paymentPlanId,
        setPaymentPlanId,
        paymentMethod,
        setPaymentMethod,
      }}
    >
      {children}
    </PaymentContext.Provider>
  );
};

export const usePaymentProvider = () => useContext(PaymentContext);
