import { env } from 'constants/env';
import { useEffect, useState } from 'react';

export const useProcessOneScripts = () => {
  const [scriptLoaded, setScriptLoaded] = useState(false);
  useEffect(() => {
    const script = document.createElement('script');
    const jQueryScript = document.createElement('script');
    jQueryScript.src = `https://code.jquery.com/jquery-2.2.1.min.js`;
    jQueryScript.async = true;
    jQueryScript.type = 'text/javascript';
    jQueryScript.onload = () => {
      script.src = `${env.processOneBaseUrl}/GenericModal/Cdn/PortalOne.js`;
      script.async = true;
      script.type = 'text/javascript';
      script.onload = () => setScriptLoaded(true);
      document.body.appendChild(script);
    };
    document.body.appendChild(jQueryScript);

    return () => {
      jQueryScript.remove();
      script.remove();
    };
  }, []);

  return { scriptLoaded };
};
