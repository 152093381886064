import React, { ReactNode } from 'react';

import { TextHighlighterPropsType } from './types';

const isEmptyString = (str: string) => str.trim() === '';

const isEqualLowerCased = (str1: string, str2: string) =>
  str1.toLowerCase() === str2.toLowerCase();

// Component that highlights matching string within the given text
export const TextHighlighter: React.FC<TextHighlighterPropsType> = (props) => {
  const {
    fromText,
    hightlight,
    renderHighlightedText,
    renderNotHighlightedText,
  } = props;

  const regex = new RegExp(`(${hightlight})`, 'gi');

  const defaultRenderHighlightedText = (part: string) => <b>{part}</b>;

  const defaultRenderNotHighlightedText = (part: string) => <span>{part}</span>;

  const renderTextSegment = (part: string): ReactNode => {
    if (isEmptyString(part)) {
      return null;
    }

    if (isEqualLowerCased(part, hightlight)) {
      return (renderHighlightedText || defaultRenderHighlightedText)(part);
    } else {
      return (renderNotHighlightedText || defaultRenderNotHighlightedText)(
        part
      );
    }
  };

  const textSegments = fromText.split(regex).reduce((acc, part) => {
    const view = renderTextSegment(part);

    if (view) {
      acc.push(view);
    }

    return acc;
  }, [] as ReactNode[]);

  return (
    <>
      {textSegments.map((segment, idx) => {
        if (React.isValidElement(segment)) {
          return React.cloneElement(segment, { key: `${idx}` });
        }

        return <>{segment}</>;
      })}
    </>
  );
};
