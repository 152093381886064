import React, { useMemo } from 'react';
import { configDefault } from 'V2/Step';
import { StepConfigType } from 'V2/Types';
import { DisclaimerFactory } from 'V2/Shared/Disclaimer';
import cx from 'classnames';
import styles from './styles.module.css';

export const Content: React.FC<{
  config?: StepConfigType;
  className?: string;
}> = ({ config = configDefault, children, className }) => {
  const { step_descriptions: descriptions } = config;

  const disclaimers = useMemo(() => {
    return descriptions.filter((d) => d.type.id === 'disclaimer');
  }, [descriptions]);

  const helpers = useMemo(() => {
    return descriptions.filter((d) => d.type.id === 'helper');
  }, [descriptions]);

  return (
    <div className={cx(styles.content, className)}>
      {children}
      {[...disclaimers, ...helpers].map(
        (disclaimer, i) =>
          disclaimer.label && (
            <DisclaimerFactory
              key={`disclaimer-${i}`}
              type={disclaimer.type.id}
              subtype={disclaimer.type.sub_type}
            >
              {disclaimer.label}
            </DisclaimerFactory>
          )
      )}
    </div>
  );
};
