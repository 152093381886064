import { StorageHelperInputType } from '.';

export const copySessionValuesToLocalStorage = (
  items: StorageHelperInputType[]
) => {
  items.forEach((item) => {
    const value = sessionStorage.getItem(item.key);
    if (value) {
      localStorage.setItem(item.name || item.key, value);
    }
  });
};

export const removeSessionValuesFromLocalStorage = (keys: string[]) => {
  keys.forEach((key) => {
    if (localStorage.getItem(key)) {
      localStorage.removeItem(key);
    }
  });
};

export const copyLocalStorageValuesToSession = (
  items: StorageHelperInputType[]
) => {
  items.forEach((item) => {
    const value = localStorage.getItem(item.key);
    if (value) {
      sessionStorage.setItem(item.name || item.key, value);
    }
  });
};
