import {
  vehicle_vin_match,
  tx_complete_lead_no_choice_quotes,
  la_uw_stop,
} from 'constants/quotePayloads';

type SectionType = {
  label: string;
  apiUrl: string;
  buttons: {
    label: string;
    payload: any;
  }[];
};

export const sections: SectionType[] = [
  {
    label: 'QAPI - Incomplete Data',
    apiUrl: '/v1/quote',
    buttons: [
      {
        label: 'Vehicle Vin Match',
        payload: vehicle_vin_match,
      },
      {
        label: 'LA - UW Stop',
        payload: la_uw_stop,
      },
    ],
  },
  {
    label: 'LAPI - Misc. Scenarios',
    apiUrl: '/v1/lead/attribution_link',
    buttons: [
      {
        label: 'TX - No choice quotes',
        payload: tx_complete_lead_no_choice_quotes,
      },
      {
        label: 'Empty',
        payload: {},
      },
    ],
  },
];
