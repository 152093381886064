import React, { useEffect } from 'react';
import { Heap } from 'V2/Analytics';
import styles from './styles.module.css';

export const FieldError: React.FC<{ field: string; message: string }> = ({
  field,
  message,
  children,
}) => {
  useEffect(() => {
    Heap.track('validation_error', {
      fieldName: field,
      errorMessage: message,
    });
  }, [field, message]);

  return <div className={styles.error}>{children}</div>;
};
