import React from 'react';
import styles from './styles.module.css';
import alert from 'V2/Shared/Assets/alert.svg';

export const ErrorBannerRed: React.FC = ({ children }) => {
  return (
    <div className={styles.errorBanner}>
      <div className={styles.alert}>
        <img src={alert} alt="alert" />
      </div>
      <div className={styles.message}>{children}</div>
    </div>
  );
};
