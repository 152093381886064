// External
import React, { ChangeEvent, useState } from 'react';
import axios from 'axios';
// Components
import { Button } from '../Button';
import { ProgressBar } from '../ProgressBar';
import { Page, Logo, Title } from 'V2/Shared/SplashPageAssets';
// CSS
import styles from './styles.module.css';
// JS
import { sections } from './sections';
import { env } from 'constants/env';
import { states } from './addresses';
//Types
import { Nullable } from '../../Types';
import { generatePayload } from './utils';

type NestedJWTType = {
  partner_id: string;
  jwt_token: string;
  inactive_at: Nullable<string>;
};

type GetPartnerTokenResponseType = {
  jwts: {
    name: string;
    id: string;
    jwts: NestedJWTType[];
  }[];
};

type StartQuoteResponseType = {
  return_url?: string;
  clearcover_application_id?: string;
  clearcover_quote_id?: string;
  quote_number?: string;
  lead_metadata?: {
    campaign_medium?: string | null;
    campaign_name?: string | null;
    lead_identifier?: string | null;
    lead_name?: string | null;
    partner_unstructured_metadata?: string | null;
    attribution?: string | null;
  };
  validation_errors?: Array<any>;
  agent_return_url?: string;
};

type UserAppSessionResponseType = {
  insurance_application_id: string;
  authorization_token: string;
  attribution_link_click_id: string;
};

const partners = [
  'clearcover',
  'Bankie',
  'GoodCover',
  'Toyota',
  'Credit Karma',
  'Insurify',
  'The Zebra',
];

const apis = {
  qapi: '/v1/quote',
  lapi: '/v1/lead/attribution_link',
};

const ephemeralPartnerToken =
  'eyJhbGciOiJIUzI1NiJ9.eyJ0b2tlbiI6IjZjNGQ0OWY1LWRkMWEtNGJiMy1iNzIzLWVhZjdiZDkzNTY0MCJ9.uoAdr8FtdtrZ0R0-Ix8ykcqEDqzUe2QLKKPDN3ZTJNw';
const stagingPartnerToken =
  'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbiI6IjE5MTU5YjI5LTVkYWUtNGMxZC05YTJlLTcyMjE2NWMzOTgwYSJ9.sHq0EvM5M7aDdo9j1tjjB5q4GZZRhkoYcsX7saTXVWs';
const getClearcoverPartnerAuthToken = () => {
  if (env.clearcoverEnv === 'ephemeral') return ephemeralPartnerToken;
  if (env.clearcoverEnv === 'staging') return stagingPartnerToken;

  return env.partnerAuthToken;
};
const ClearcoverPartnerAuthToken = getClearcoverPartnerAuthToken();

type MenuType = {
  label: string;
  defaultValue?: string;
  onSelect: (e: string) => void;
  options: string[];
};

const SelectMenu = ({ menu }: { menu: MenuType }) => (
  <div className="px-12">
    <label className={styles.label} data-testid="label">
      {menu.label}
    </label>
    <select
      className={styles.select}
      defaultValue={menu.defaultValue}
      onChange={(event: ChangeEvent<HTMLSelectElement>) =>
        menu.onSelect(event.target.value)
      }
    >
      <optgroup label={menu.label}>
        {menu.options.map((option: string, idx: number) => {
          return (
            <option value={option} key={idx}>
              {option}
            </option>
          );
        })}
      </optgroup>
    </select>
  </div>
);

export const QuoteScreen = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [state, setState] = useState<keyof typeof states>('TX');
  const [api, setAPI] = useState<keyof typeof apis>('lapi');
  const [partnerToken, setPartnerToken] = React.useState<string>(
    ClearcoverPartnerAuthToken || ''
  );

  const getPartnerToken = async (partnerName: string): Promise<void> => {
    const apiUrl = `${env.apiHost}/clearcover_admin/partner_tokens?name=${partnerName}`;
    const response: GetPartnerTokenResponseType = await axios
      .get(apiUrl, {
        headers: {
          'Content-Type': 'application/json',
          'Clearcover-Partner-Authorization-Token':
            ClearcoverPartnerAuthToken || '',
        },
      })
      .then((res) => res.data)
      .catch((e) => {
        throw new Error('Unable to fetch partner token.');
      });
    setPartnerToken(response.jwts[0].jwts[0].jwt_token);
  };

  const selectParnterProps: MenuType = {
    label: 'Select Partner',
    onSelect: (partner: string) => {
      getPartnerToken(partner);
    },
    options: partners,
    defaultValue: 'clearcover',
  };

  const selectMenusForGenerate: MenuType[] = [
    {
      label: 'Select State',
      onSelect: (state: string) => setState(state as keyof typeof states),
      options: Object.keys(states),
      defaultValue: 'TX',
    },
    {
      label: 'Select API',
      onSelect: (api: string) => setAPI(api as keyof typeof apis),
      options: Object.keys(apis),
      defaultValue: 'lapi',
    },
  ];

  const axiosApi = axios.create({
    baseURL: `${env.apiHost}/api`,
    headers: {
      'Content-Type': 'application/json',
      'Clearcover-Partner-Authorization-Token': partnerToken || '',
    },
  });

  const initQuote = async (
    quoteData: { [k: string]: any },
    apiUrl: string
  ): Promise<void> => {
    setLoading(true);
    const response: StartQuoteResponseType = await axiosApi({
      url: apiUrl,
      method: 'POST',
      data: quoteData,
    })
      .then((d) => d.data)
      .catch((e) => {
        setLoading(false);
        throw new Error('Unable to generate quote.');
      });

    if (response && response.return_url) {
      await axiosApi({
        url: '/v1/user_app_session',
        method: 'POST',
        data: {
          insurance_application_id: response.clearcover_application_id,
        },
      })
        .then((d) => {
          const userAppSession: UserAppSessionResponseType = d.data;
          sessionStorage.setItem(
            'appId',
            userAppSession.insurance_application_id
          );
          sessionStorage.setItem(
            'authToken',
            userAppSession.authorization_token
          );
          sessionStorage.setItem(
            'clickId',
            userAppSession.attribution_link_click_id
          );
          window.location.replace(window.location.origin);
        })
        .catch((e) => {
          setLoading(false);
          throw new Error('Failed fetching user app session');
        });
    } else {
      setLoading(false);
      throw new Error('Invalid quote response');
    }
  };

  return (
    <Page>
      <Logo />
      {loading ? (
        <ProgressBar title="Generating quote..." />
      ) : (
        <>
          <Title>Generate Quote</Title>
          <SelectMenu menu={selectParnterProps} />
          <div className="flex-row">
            <div>
              {sections.map((section, index) => (
                <div key={index} className={styles.sections}>
                  <div className={styles.sectionLabel}>{section.label}</div>
                  <div className={styles.buttons}>
                    {section.buttons.map((button, index) => (
                      <Button
                        key={index}
                        onClick={() =>
                          initQuote(button.payload, section.apiUrl)
                        }
                      >
                        {button.label}
                      </Button>
                    ))}
                  </div>
                </div>
              ))}
            </div>
            <div className={`${styles.sections} ml-32`}>
              <div className={`${styles.sectionLabel} mb-16`}>
                Generate by state
              </div>
              <div className="flex-row mb-20">
                {selectMenusForGenerate.map((menu) => (
                  <SelectMenu menu={menu} />
                ))}
              </div>
              <Button
                onClick={() => {
                  initQuote(generatePayload(state, api), apis[api]);
                }}
              >
                Generate Quote
              </Button>
            </div>
          </div>

          <Button
            variant="link"
            onClick={() => window.location.replace('/search')}
            isSubmitting={false}
          >
            Return to Search
          </Button>
        </>
      )}
    </Page>
  );
};
