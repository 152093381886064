import { http } from '../http';

export type ApplicationSearchType = {
  email: string;
  last_name: string;
  date_of_birth: string;
  zip_code: string;
};

export type ApplicationSearchResponseType = {
  data: {
    insurance_application_id: string;
    link: {
      apply_url: string;
      id: string;
    };
  };
};

export const postApplicationSearch = (payload: ApplicationSearchType) =>
  http.post({
    url: `/api/v1/insurance_applications/v2/search`,
    data: payload,
  });
