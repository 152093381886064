import React from 'react';
import pencil from 'V2/Shared/Assets/pencil-gray.svg';
import styles from './styles.module.css';

export const Loading: React.FC = () => (
  <>
    {Array(4)
      .fill(null)
      .map((item, i) => (
        <div className={styles.field} key={i}>
          <div className={styles.displayField}>
            <div className={styles.fieldContainer}>
              <div className={styles.display}>
                <div className={styles.labelLoader} />
              </div>
              <div className={styles.value}>
                <div className={styles.descriptionLoader} />
              </div>
            </div>
            <button className={styles.editIcon} disabled>
              <img src={pencil} alt="edit" />
            </button>
          </div>
        </div>
      ))}
  </>
);
