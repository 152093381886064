import { ACHFormType, CreditCardLegacyFormDisplayType } from './types';
import { Optional } from 'V2/Types';

// Define validation rules.
const REQUIRED = (value: string): boolean =>
  value && value.trim() ? true : false;
const LENGTH = (value: string, length: number): boolean =>
  value.length === length;
const MAX = (value: string, length: number): boolean => value.length <= length;
const MIN = (value: string, length: number): boolean => value.length >= length;
const EQUAL = (value1: string, value2: string) => value1 === value2;

// Returns an array of error messages.
const setErrors = (validations: [boolean, string][]): string[] => {
  return validations
    .filter((v: [boolean, string]) => {
      return v[0] === false;
    })
    .map((v: [boolean, string]) => {
      return v[1];
    });
};

// Takes a form field key/name and returns a function that will return
// either an error message or undefined. Undefined = no errors.
// The error message returned will always be the first item in
// the array of errors, and the order reflects the same order of the
// array that is passed into setErrors.
export const validations: {
  [key: string]: (value: string, value2?: string) => Optional<string>;
} = {
  ownerName: (value: string) =>
    setErrors([[REQUIRED(value), 'Required']])[0] || undefined,

  bankAccountNumber: (value: string) =>
    setErrors([
      [REQUIRED(value), 'Required'],
      [MAX(value, 19), 'Must be 19 digits or less'],
    ])[0] || undefined,

  bankLocationId: (value: string) =>
    setErrors([
      [REQUIRED(value), 'Required'],
      [LENGTH(value, 9), 'Must be 9 digits'],
    ])[0] || undefined,

  account_holder_name: (value: string) =>
    setErrors([[REQUIRED(value), 'Required']])[0] || undefined,

  account_number: (value: string) =>
    setErrors([
      [REQUIRED(value), 'Required'],
      [MAX(value, 19), 'Must be 19 digits or less'],
    ])[0] || undefined,

  routing_number: (value: string) =>
    setErrors([
      [REQUIRED(value), 'Required'],
      [LENGTH(value, 9), 'Must be 9 digits'],
    ])[0] || undefined,

  verifyAccountNumber: (value: string, value2?: string) =>
    setErrors([
      [REQUIRED(value), 'Required'],
      [EQUAL(value, value2!), 'Must match account number'],
    ])[0] || undefined,

  cvv: (value: string) =>
    setErrors([
      [REQUIRED(value), 'Required'],
      [MIN(value, 3), 'Minimum 3 digits'],
      [MAX(value, 4), 'Maximum 4 digits'],
    ])[0] || undefined,

  expiration: (value: string) =>
    setErrors([
      [REQUIRED(value), 'Required'],
      [LENGTH(value, 5), 'Invalid format'],
    ])[0] || undefined,

  address_zip: (value: string) =>
    setErrors([
      [REQUIRED(value), 'Required'],
      [LENGTH(value, 5), 'Must be 5 digits'],
    ])[0] || undefined,

  card_holder_name: (value: string) =>
    setErrors([[REQUIRED(value), 'Required']])[0] || undefined,

  card_number: (value: string) =>
    setErrors([
      [REQUIRED(value), 'Required'],
      [MIN(value.split(' ').join(''), 13), 'Minimum 13 digits'],
      [MAX(value.split(' ').join(''), 23), 'Maximum 23 digits'],
    ])[0] || undefined,
};

// This function is specific to the ACH payment form.
// Takes in the payment form and the validate account
// number field. Runs the values through the validations.
export const achFormValid = (
  paymentForm: ACHFormType,
  isLegacy: boolean
): boolean => {
  const { payment_information: form } = paymentForm;

  const legacyKeys = [
    'account_number',
    'routing_number',
    'account_holder_name',
    'verifyAccountNumber',
  ];
  const coreKeys = [
    'bankLocationId',
    'bankAccountNumber',
    'ownerName',
    'verifyAccountNumber',
  ];

  // Ensure the paymentForm has all necessary keys for validation.
  // Tests will ensure that we don't forget to update these.
  if (isLegacy) {
    if (!legacyKeys.every((key) => form.hasOwnProperty(key))) {
      return false;
    }
  } else {
    if (!coreKeys.every((key) => form.hasOwnProperty(key))) {
      return false;
    }
  }

  // This is checked within the ACH component itself as
  // well, however this provides another layer of protection.
  if (
    form.verifyAccountNumber !== (form.bankAccountNumber || form.account_number)
  ) {
    return false;
  }

  return Object.keys(form)
    .map((key: string) => [key, form[key]])
    .filter((field: string[]) => field[0] !== 'verifyAccountNumber')
    .filter((field: string[]) => field[0] !== 'type')
    .every((field: string[]) => !validations[field[0]](field[1]));
};

export const ccLegacyFormValid = (
  paymentForm: CreditCardLegacyFormDisplayType
): boolean => {
  return Object.keys(paymentForm)
    .map((key: string) => [key, paymentForm[key]])
    .every((field: string[]) => !validations[field[0]](field[1]));
};
