import React, { ChangeEvent } from 'react';
import classNames from 'classnames';
import { Field } from 'formik';
import { OptionType } from 'V2/Types';
import { Errors } from '../Errors';
import { FieldRenderProps } from '../types';
import { SelectInputType } from './types';
import styles from './styles.module.css';

export const SelectInput: React.FC<SelectInputType> = ({
  onChange,
  label,
  options,
  name,
  disabled: disabledField,
  variant,
  ...inputProps
}) => (
  <Field name={name}>
    {({ field, form, meta }: FieldRenderProps) => (
      <>
        <div className={styles.group} data-testid={name}>
          <select
            {...field}
            {...inputProps}
            onChange={(event: ChangeEvent<HTMLSelectElement>) => {
              field.onChange(event);

              if (onChange) {
                onChange(event);
              }
            }}
            className={classNames(styles.select, {
              [styles.selected]: field.value !== null && field.value !== '',
              [styles.flat]: variant === 'flat',
            })}
            disabled={disabledField}
            data-testid="select"
            id={field.name}
          >
            <option disabled hidden className={styles.defaultOption} />
            {options.map((option: OptionType, i: number) =>
              option.options && option.options.length > 0 ? (
                <optgroup label={option.label} key={i}>
                  {option.options.map((group: OptionType, i: number) => (
                    <option value={`${group.value}`} key={i}>
                      {group.label}
                    </option>
                  ))}
                </optgroup>
              ) : (
                <option value={`${option.value}`} key={i}>
                  {option.label}
                </option>
              )
            )}
          </select>
          <label
            className={styles.label}
            data-testid="label"
            htmlFor={field.name}
          >
            {label}
          </label>
        </div>
        <Errors fieldName={name} formStatus={form.status} />
      </>
    )}
  </Field>
);
