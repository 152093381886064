import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
  useCallback,
} from 'react';
import { useHistory } from 'react-router-dom';
import { getQueryParams } from 'V2/Utilities/GetQueryParams';
import { WhiteLabelStatusData } from 'V2/Types';

interface IThemeProvider {
  children: ReactNode;
}

interface IThemeContext {
  themeIsEnabled: boolean;
  setTheme: (whiteLabel?: WhiteLabelStatusData) => void;
}

const ThemeContext = createContext<IThemeContext>({
  themeIsEnabled: false,
  setTheme: () => {},
});

const faviconLink = document.querySelector(
  "link[rel='shortcut icon']"
) as HTMLLinkElement;

const alternateFaviconLink = document.querySelector(
  "link[rel='alternate icon']"
) as HTMLLinkElement;

const handleTitleAndFavicon = (whiteLabel?: WhiteLabelStatusData) => {
  document.title = whiteLabel?.pageTitle || 'Clearcover';
  faviconLink.href = whiteLabel?.favicon || '/favicon.svg';
  alternateFaviconLink.href = whiteLabel?.favicon || '/favicon.png';
};

export const useTheme = () => useContext<IThemeContext>(ThemeContext);

export const ThemeProvider = ({ children }: IThemeProvider) => {
  const history = useHistory();
  const params = getQueryParams(history.location.search);
  const [themeIsEnabled, setThemeIsEnabled] = useState<boolean>(false);
  const [theme, setTheme] = useState<string | undefined>(params.theme);

  const onSetTheme = useCallback((whiteLabel?: WhiteLabelStatusData) => {
    handleTitleAndFavicon(whiteLabel);
    setTheme(whiteLabel?.theme);
  }, []);

  useEffect(() => {
    const whiteLabel = JSON.parse(
      sessionStorage.getItem('white_label') ?? '{}'
    ) as WhiteLabelStatusData;
    onSetTheme(whiteLabel);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (theme) {
      document
        .getElementsByTagName('html')[0]
        .setAttribute('data-theme', theme);
      setThemeIsEnabled(true);
    }
  }, [history, theme]);

  return (
    <ThemeContext.Provider value={{ themeIsEnabled, setTheme: onSetTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
