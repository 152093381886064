import { env } from 'constants/env';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useApi } from 'V2/Api';
import { useQuery } from 'V2/Hooks';
import { FullPageLoading } from 'V2/Shared/FullPageLoading';
import { ResourceStatusType } from 'V2/Steps/PaymentDetails';
import { TIMEOUT_ERROR } from 'V2/Utilities/Constants';
import { runWithPolling } from 'V2/Utilities/RunWithPolling';
import { copyLocalStorageValuesToSession } from 'V2/Utilities/StorageHelpers';

export const DocumentRender: React.FC = () => {
  const query = useQuery();
  const { getResource } = useApi();
  const history = useHistory();
  const [documentLink, setDocumentLink] = useState<string | undefined>();
  const pollingUri = query.get('resource');

  copyLocalStorageValuesToSession([
    { key: 'consumerToken', name: 'authToken' },
    { key: 'clickId' },
    { key: 'appId' },
  ]);

  if (!pollingUri) {
    // If there is no URL for documents route
    window.location.pathname = '/search';
  }

  useEffect(() => {
    if (pollingUri) {
      if (!documentLink) {
        runWithPolling(
          () => getResource({ url: `${env.apiHost}${pollingUri}` }),
          (response: { data: ResourceStatusType }) =>
            response.data.status === 'success',
          (response: { data: ResourceStatusType }) =>
            response.data.status === 'error'
        )
          .then((resource: { data: ResourceStatusType }) => {
            if (resource.data.status === 'success' && resource.data.href) {
              setDocumentLink(resource.data.href);
            }
          })
          .catch((err) => {
            if (err === TIMEOUT_ERROR) {
              history.push('/error?errorType=DocumentRenderTimeout');
            } else {
              history.push('/error?errorType=DocumentRenderServerError');
            }
          });
      } else {
        // Set page to the PDF document that was returned from status success
        window.location.href = `${env.apiHost}${documentLink}`;
      }
    }
  }, [documentLink, getResource, pollingUri, history]);

  return <FullPageLoading loadingText="Loading Document..." />;
};
