import React from 'react';

export const ArrowLeft = ({ className }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="16"
    viewBox="0 0 19 16"
    role="img"
    aria-labelledby="arrowLeftId"
  >
    <title id="arrowLeftId">back</title>
    <path
      className="fill-brand-01"
      d="M18 7H3.83l4.88-4.88c.39-.39.39-1.03 0-1.42S7.69.31 7.3.7L.71 7.29a.996.996 0 0 0 0 1.41l6.59 6.59a.996.996 0 1 0 1.41-1.41L3.83 9H18c.55 0 1-.45 1-1s-.45-1-1-1z"
    />
  </svg>
);
