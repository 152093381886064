import React from 'react';
import { configDefault } from 'V2/Step';
import { StepForm, InfoSection } from 'V2/Shared/StepForm';
import { StepConfigType } from '../Types';
import styles from './styles.module.css';
import { Button } from 'V2/Shared/Button';

export type NotFoundType = {
  onNewQuote: () => void;
  isCreatingQuote: boolean;
  onNewSearch: () => void;
};

const notFoundConfig: StepConfigType = {
  ...configDefault,
  title_text: 'Quote Not Found',
  step_descriptions: [
    {
      label:
        'Unfortunately we cannot find a quote for you or a previous quote has expired.' +
        ' Please start over with a new quote below.',
      description: null,
      type: {
        id: 'info',
        sub_type: null,
      },
      value: null,
      ctas: [],
      group_id: '',
    },
  ],
};

export const NotFound: React.FC<NotFoundType> = ({
  onNewQuote,
  isCreatingQuote,
  onNewSearch,
}) => (
  <StepForm>
    <InfoSection config={notFoundConfig} />
    <div className={styles.notFoundButtonContainer}>
      <Button onClick={onNewQuote} width="block" isSubmitting={isCreatingQuote}>
        Start a New Quote
      </Button>
      <div className={styles.tryAgain}>
        <Button variant="link" onClick={onNewSearch}>
          Try a New Search
        </Button>
      </div>
    </div>
  </StepForm>
);
