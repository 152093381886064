import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.css';
import lightbulbIcon from 'V2/Shared/Assets/icon-lightbulb.svg';
import notificationIcon from 'V2/Shared/Assets/icon-notification-important.svg';
import { Nullable } from 'V2/Types';

type DisclaimerPropsType = {
  children: string | JSX.Element;
  type?: string;
  subtype?: Nullable<string>; // {{icon_name}}_{{disclaimer_subtype}}
  className?: string;
};

type BaseDisclaimerProps = {
  children: string | JSX.Element;
  className?: string;
};

const DISCLAIMER_ICON_NAMES = {
  notification: notificationIcon,
  lightbulb: lightbulbIcon,
};
type DisclaimerIconName = keyof typeof DISCLAIMER_ICON_NAMES;

const isValidIconName = (iconName: string): iconName is DisclaimerIconName =>
  iconName in DISCLAIMER_ICON_NAMES;
const isDisclaimerStyled = (subtype?: Nullable<string>): boolean => !!subtype;

const getDisclaimerIcon = (disclaimerSubtype?: Nullable<string>) => {
  if (!disclaimerSubtype) return undefined;

  const [iconName] = disclaimerSubtype.split('_');

  if (!isValidIconName(iconName)) return undefined;

  return DISCLAIMER_ICON_NAMES[iconName];
};

export const DisclaimerFactory: React.FC<DisclaimerPropsType> = (props) => {
  const { type, subtype } = props;
  if (type === 'disclaimer' && isDisclaimerStyled(subtype)) {
    return <DisclaimerStyled {...props} />;
  }
  return <Disclaimer {...props} />;
};

export const DisclaimerStyled: React.FC<
  BaseDisclaimerProps & {
    subtype?: Nullable<string>;
  }
> = ({ children, subtype, className }) => (
  <div className={classNames(styles.main, styles.warning, className)}>
    <img
      src={getDisclaimerIcon(subtype)}
      alt="Disclaimer"
      data-testid="disclaimer"
    />
    <div>{children}</div>
  </div>
);

export const Disclaimer: React.FC<
  BaseDisclaimerProps & {
    type?: string;
  }
> = ({ children, type, className }) => (
  <div
    className={classNames(styles.main, className, {
      [styles.center]: type === 'disclaimer',
    })}
  >
    {type !== 'disclaimer' && (
      <img src={lightbulbIcon} alt="Disclaimer" data-testid="disclaimer" />
    )}
    <div>{children}</div>
  </div>
);
