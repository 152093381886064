import React from 'react';
import { useHistory } from 'react-router-dom';
import { Checkbox } from 'V2/Shared/Form';
import { useQuery } from 'V2/Hooks';
import { DocumentEntityType, SignatureSummaryType } from 'V2/Types';
import { FullScreenModal } from 'V2/Shared/FullScreenModal';
import { DocumentViewer } from 'V2/Shared/DocumentViewer';
import { parseReactElements } from 'V2/Utilities/ParseReactFromJson';
import classNames from 'classnames';
import styles from './styles.module.css';

type DocumentSummariesAndTemplatesType = {
  summaries: SignatureSummaryType[];
  templates: DocumentEntityType[];
  isInvalid?: boolean;
  showCheckbox?: boolean;
};

export const DocumentSummariesAndTemplates: React.FC<
  DocumentSummariesAndTemplatesType
> = ({ summaries, templates, isInvalid, showCheckbox }) => {
  const history = useHistory();
  const query = useQuery();

  const closeDocumentModal = () => {
    query.delete('document');
    history.push({
      ...history.location,
      search: query.toString(),
    });
  };

  const policyClass = classNames(styles.policy, {
    [styles.invalid]: isInvalid,
  });

  const documentQuery = query.get('document');

  const modalData = templates.find((d) => d.id === documentQuery);

  return (
    <>
      {summaries.map((document) => (
        <React.Fragment key={document.id}>
          {showCheckbox ? (
            <div className={policyClass}>
              <div className={styles.signature}>
                <div className={styles.checkbox}>
                  <Checkbox name={document.group_id} />
                </div>
                <div className={styles.description}>
                  {parseReactElements(document.content)}
                </div>
              </div>
            </div>
          ) : (
            <div className={styles.content}>
              {parseReactElements(document.content)}
            </div>
          )}
          <div className={styles.modal}>
            <FullScreenModal showModal={!!documentQuery}>
              <DocumentViewer
                showProgress={false}
                onCloseClick={closeDocumentModal}
              >
                {modalData && parseReactElements(modalData.content)}
              </DocumentViewer>
            </FullScreenModal>
          </div>
        </React.Fragment>
      ))}
    </>
  );
};
