import React from 'react';
import { useApi } from 'V2/Api';
import { AnimatePresence, motion } from 'framer-motion';
import { ErrorBanner } from '../ErrorBanner';
import styled from 'styled-components/macro';

const ErrorBannerContainer = styled(motion.div)`
  bottom: 42px;
  position: fixed;
  z-index: 999999 !important;
  left: 50%;
`;

export const ErrorRouting = () => {
  const { error } = useApi();

  return (
    <AnimatePresence>
      {error && (
        <ErrorBannerContainer
          key="errorBanner"
          initial={{ y: 20, x: '-50%', opacity: 0 }}
          animate={{ y: 0, x: '-50%', opacity: 1 }}
          exit={{ y: 20, x: '-50%', opacity: 0 }}
          transition={{ duration: 0.25 }}
        >
          <ErrorBanner
            body="Your application is not lost. Just refresh this page to continue this quote or call us at (855) 444-1875."
            title="Something went wrong - but don't worry."
          />
        </ErrorBannerContainer>
      )}
    </AnimatePresence>
  );
};
