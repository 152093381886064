import React from 'react';

export const Phone = ({ fillClassName = 'fill-brand-01' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    role="img"
    aria-labelledby="phoneTitle"
  >
    <title id="phoneTitle">Call us</title>
    <path
      className={fillClassName}
      d="M2.816 6.059a11.742 11.742 0 0 0 5.125 5.125l1.711-1.71a.78.78 0 0 1 .794-.195 8.836 8.836 0 0 0 2.776.443.78.78 0 0 1 .778.778v2.722a.78.78 0 0 1-.778.778C5.92 14 0 8.081 0 .778A.78.78 0 0 1 .778 0H3.5a.78.78 0 0 1 .778.778c0 .972.155 1.905.443 2.776a.78.78 0 0 1-.194.794l-1.711 1.71z"
    />
  </svg>
);
