import React from 'react';
import { Button } from '../Button';
import styles from './styles.module.css';

export const Bailout: React.FC<{
  onClick: () => void;
  isSubmitting: boolean;
}> = ({ onClick, isSubmitting, children }) => (
  <div className={styles.commands} data-testid="bailout">
    <Button variant="link" onClick={onClick} isSubmitting={isSubmitting}>
      {children}
    </Button>
  </div>
);
