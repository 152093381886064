import { useEffect, useState } from 'react';

export const isMobile: boolean = window.innerWidth <= 768;

// This is adapted from react-use
// find more about it here: https://github.com/streamich/react-use/blob/master/src/useWindowSize.ts
export const useIsMobile = (): boolean => {
  const [state, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    window.addEventListener('resize', () =>
      setIsMobile(window.innerWidth <= 768)
    );

    return () => {
      window.removeEventListener('resize', () =>
        setIsMobile(window.innerWidth <= 768)
      );
    };
  }, []);

  return state;
};
