import { useLocation } from 'react-router-dom';

type UseEditModeType = {
  isEditMode: boolean;
};

export const useEditMode = (): UseEditModeType => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const isEditMode =
    query.get('edit') === 'true' && query.get('entityId') ? true : false;

  return {
    isEditMode,
  };
};
