import { useEffect, useState } from 'react';

export const useScrollProgress = () => {
  const [scrollProgress, setScrollProgress] = useState(0);
  useEffect(() => {
    const onScroll = () => {
      const { clientHeight, scrollHeight, scrollTop } =
        document.documentElement;
      const winHeightPx = scrollHeight - clientHeight;

      setScrollProgress((scrollTop / winHeightPx) * 100);
    };
    if (
      document.documentElement.scrollHeight ===
      document.documentElement.clientHeight
    ) {
      setScrollProgress(100);
    }
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return {
    scrollProgress,
  };
};
