import React, { useEffect, useState } from 'react';
import styles from './styles.module.css';
import { Page } from 'V2/Shared/SplashPageAssets';

interface IFullPageLoadingProps {
  loadingText?: string;
  delay?: number;
}

export const FullPageLoading: React.FC<IFullPageLoadingProps> = ({
  loadingText,
  delay,
}) => {
  const [isVisible, setIsVisible] = useState(!delay);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsVisible(true);
    }, delay);

    return () => clearTimeout(timeout);
  }, [delay, setIsVisible]);

  return (
    <Page data-testid="loading">
      {isVisible && (
        <>
          <div className={styles.spinner}>
            <div className={styles.bounceOne} />
            <div className={styles.bounceTwo} />
          </div>
          {loadingText && (
            <div className={styles.loadingText}>{loadingText}</div>
          )}
        </>
      )}
    </Page>
  );
};
