import * as Yup from 'yup';
import { FieldDataType, SchemaType } from 'V2/Types';

export const buildSchema = (
  fields: FieldDataType[],
  schema: SchemaType
): Yup.ObjectSchema<{}> =>
  Yup.object().shape(
    // TODO: fix this any type for values; values returns
    // { [key: string]: ObjectSchema from Yup }
    fields.reduce((values: any, field) => {
      const entity = values[field.entity_id];

      return {
        ...values,
        [field.entity_id]: Yup.object().shape({
          ...(entity ? entity.fields : {}),
          [field.field_id]: schema[field.field_id],
        }),
      };
    }, {})
  );
