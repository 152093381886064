import React, { useEffect, useMemo, useState } from 'react';
import {
  DocumentEntityType,
  DocumentSignatureType,
  SignatureSummaryType,
  StepConfigType,
} from 'V2/Types';
import { StepFieldType } from 'V2/Step';
import { usePaymentProvider } from '../PaymentContext';
import { useApi } from 'V2/Api';
import { DocumentSummariesAndTemplates } from 'V2/Shared/DocumentSummariesAndTemplates';

export type TermsAndConditionsType = {
  fields: StepFieldType[];
  config: StepConfigType;
  valid: boolean;
  paymentPlanSignatureSummaries?: SignatureSummaryType[];
  signatureIds?: string[];
};

export const TermsAndConditions: React.FC<TermsAndConditionsType> = ({
  fields,
  config,
  valid,
  paymentPlanSignatureSummaries,
  signatureIds,
}) => {
  const signatureSummaries = useMemo(() => {
    let summaries =
      paymentPlanSignatureSummaries ??
      (config.features_by_key.signature_summaries as SignatureSummaryType[]);
    return (summaries || []).sort((a) =>
      a.id === 'general_terms' ? 0 : -1
    ) as SignatureSummaryType[];
  }, [config.features_by_key, paymentPlanSignatureSummaries]);

  const signatureTemplates = useMemo(
    () => config.features_by_key.signature_templates as DocumentEntityType[],
    [config.features_by_key]
  );

  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const { setDocuments, isSubmitting } = usePaymentProvider();

  useEffect(() => {
    if (isSubmitting) {
      setIsInitialLoad(false);
    }
  }, [isSubmitting]);

  const { options_uris: optionsUris } = config;

  const { getResource } = useApi();

  useEffect(() => {
    const documentData = fields
      .filter((f) => f.entity_type.id === 'signature')
      .filter((f) => {
        // do not filter without experiment signatureIds
        if (!signatureIds) {
          return true;
        }

        // strip out ending _id
        const fieldId = f.field_id.replace(/_id$/, '');

        return signatureIds.includes(fieldId);
      })
      .map((field) => {
        const option = optionsUris.find((o) => o.field_id === field.field_id);

        if (option) {
          return getResource({ url: option.uri }).then(
            (response: { data: DocumentSignatureType }) => {
              return response.data;
            }
          );
        } else {
          return Promise.reject();
        }
      });

    Promise.all(documentData).then((documents) => {
      setDocuments(documents);
    });
  }, [fields, getResource, optionsUris, setDocuments, signatureIds]);

  return (
    <DocumentSummariesAndTemplates
      showCheckbox
      summaries={signatureSummaries}
      templates={signatureTemplates}
      isInvalid={!valid && !isInitialLoad && !isSubmitting}
    />
  );
};
