import { env } from 'constants/env';

type AdyenConfig = {
  paymentMethod: string;
  environment: string;
  // allow more form data in the future for use on credit card and/or ach
  formData: {
    holderName: string;
  };
};

type AdyenDropin = {
  submit(): void;
  events: {
    isValid(): boolean;
  };
};

export type AdyenCheckout = {
  create(
    type: string,
    config?: { onReady?(): void }
  ): {
    mount(htmlId: string): AdyenDropin;
  };
};

function cardConfiguration(principalInsuredName: string) {
  const config = {
    hasHolderName: true,
    holderNameRequired: true,
    billingAddressRequired: env.adyenCardAvsMode !== 'none',
    billingAddressMode: env.adyenCardAvsMode,
    data: {
      holderName: principalInsuredName,
    },
  };

  if (env.adyenCardAvsMode !== 'full') return config;

  return {
    ...config,
    billingAddressAllowedCountries: ['US'],
    data: {
      ...config.data,
      billingAddress: { country: 'US' },
    },
  };
}

export const getAdyenConfig = ({
  paymentMethod,
  environment,
  formData,
}: AdyenConfig) => ({
  locale: 'en-US',
  environment,
  clientKey: env.adyenClientKey,
  setStatusAutomatically: false,
  paymentMethodsConfiguration: {
    card: cardConfiguration(formData.holderName),
  },
  paymentMethodsResponse:
    paymentMethod === 'credit_card'
      ? {
          paymentMethods: [
            {
              brands: ['visa', 'mc', 'amex', 'discover', 'diners'],
              details: [
                {
                  key: 'holderName',
                  type: 'text',
                },
                {
                  key: 'number',
                  type: 'text',
                },
                {
                  key: 'expiryMonth',
                  type: 'text',
                },
                {
                  key: 'expiryYear',
                  type: 'text',
                },
                {
                  key: 'cvc',
                  type: 'text',
                },
              ],
              name: 'Credit Card',
              supportsRecurring: true,
              type: 'scheme',
              holderNameRequired: true,
              hasHolderName: true,
              data: {
                holderName: formData.holderName,
              },
            },
          ],
          groups: [
            {
              name: 'Credit Card',
              types: ['visa', 'mc', 'amex', 'discover', 'diners'],
            },
          ],
        }
      : {
          paymentMethods: [
            {
              details: [
                {
                  key: 'ownerName',
                  type: 'text',
                },
                {
                  key: 'bankLocationId',
                  type: 'text',
                },
                {
                  key: 'bankAccountNumber',
                  type: 'text',
                },
              ],
              name: 'ACH Direct Debit',
              supportsRecurring: true,
              type: 'ach',
            },
          ],
        },
  amount: {
    currency: 'USD',
  },
  channel: 'web',
  showPayButton: false, // False because we want to use our own pay button
});
