import React from 'react';
import { Pencil } from 'V2/Shared/Icons/Pencil';
import { PiiIgnore } from 'V2/Shared/PiiIgnore';
import styles from './styles.module.css';

type EditableItemType = {
  label: string;
  disabled: boolean;
  onClick: () => void;
};

export const EditableItem: React.FC<EditableItemType> = ({
  label,
  disabled,
  onClick,
}) => (
  <button
    data-testid="edit-item"
    className={styles.buttonContainer}
    onClick={onClick}
    disabled={disabled}
  >
    <div className={styles.editItem}>
      <div className={styles.editItemHeading}>
        <PiiIgnore>{label}</PiiIgnore>
      </div>
      <div className={styles.editIcon}>
        <Pencil />
      </div>
    </div>
  </button>
);
