import React from 'react';
import styles from './styles.module.css';
import { SetFocus } from '../../SetFocus';

export const Controls: React.FC = ({ children }) => (
  <div className={styles.controls} data-testid="controls">
    <SetFocus />
    {children}
  </div>
);
