import { StepConfigType, FieldDataType } from 'V2/Types';
import { StepFieldType } from '../../types';

export const buildFields = (
  fieldData: FieldDataType[],
  config: StepConfigType
): StepFieldType[] =>
  fieldData.map((field) => ({
    ...field,
    info: config.field_descriptions
      .filter((d) => d.group_id === field.field_id)
      .filter((d) => d.type.id === 'info'),
    disclaimers: config.field_descriptions
      .filter((d) => d.group_id === field.field_id)
      .filter((d) => d.type.id === 'disclaimer'),
    helpers: config.field_descriptions
      .filter((d) => d.group_id === field.field_id)
      .filter((d) => d.type.id === 'helper'),
    options: config.options[field.field_id] || [],
    optionsUris: config.options_uris.filter(
      (d) => d.field_id === field.field_id
    ),
    optionSets: config.option_sets[field.field_id] || [],
    warnings: config.field_descriptions
      .filter((d) => d.group_id === field.field_id)
      .filter((d) => d.type.id === 'warning'),
  }));
