import React from 'react';
import { Link } from 'react-router-dom';
import { ParseReactElementsType } from '.';
import { NewTabLink } from '../../Shared/NewTabLink';

/**
 * Loop through the array of content or children and parse
 *
 * @param elements Content from document to parse -- array of object: { tag, text, children, attributes, type }
 * @param options Extra options for parser -- defaults to { convertAnchorsToRouterLinks: true }
 * @returns Array of React Nodes
 */
export const parseReactElements: ParseReactElementsType = (
  elements,
  options = { convertAnchorsToRouterLinks: true }
) =>
  elements.map((element, idx) => {
    const { tag, text, children, attributes, type } = element;
    const childValue = !!children && parseReactElements(children, options);

    if (!!text) return text;

    if (options.convertAnchorsToRouterLinks && tag === 'a') {
      if (attributes) {
        const { href, ...rest } = attributes;
        const urlQuery = new URLSearchParams(href as string);
        const documentId = urlQuery.get('document');

        if (type === 'pdf') {
          const testId = childValue
            .toString()
            .toLowerCase()
            .replace(' ', '-')
            .concat('-link');
          // Route to new link in new tab
          return (
            <NewTabLink
              key={`${tag}-${idx}`}
              to={href}
              id={testId}
              rest={rest}
              linkText={childValue.toString()}
            />
          );
        }

        return (
          <Link
            to={href}
            key={`${tag}-${idx}`}
            data-testid={documentId}
            {...rest}
          >
            {childValue}
          </Link>
        );
      }
    }

    return (
      !!tag &&
      React.createElement(
        tag,
        { ...attributes, key: `${tag}-${idx}` },
        childValue
      )
    );
  });
