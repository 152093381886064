import { FormValueType, FormValuesType } from 'V2/Apply';
import { FieldDataType } from 'V2/Types';
import { ParseValueType, FieldDataPayloadType } from 'V2/Step';

type BuildFieldDataPayload = (
  fields: FieldDataType[],
  values?: FormValuesType
) => FieldDataPayloadType[];

const parseValue = (value: FormValueType): ParseValueType => {
  switch (value) {
    case 'true': {
      return true;
    }
    case 'false': {
      return false;
    }
    case '': {
      return null;
    }
    default: {
      return value;
    }
  }
};

export const buildFieldDataPayload: BuildFieldDataPayload = (fields, values) =>
  fields.map((field) => ({
    entity_type: field.entity_type,
    errors: field.errors,
    field_label: field.field_label,
    group_id: field.group_id,
    value: values
      ? parseValue(values[field.entity_id][field.field_id])
      : field.value,
    entity_id: field.entity_id,
    field_id: field.field_id,
  }));
