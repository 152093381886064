import React from 'react';

export const ChevronDown = ({
  alt = 'arrow down',
  className,
}: {
  alt?: string;
  className?: string;
}) => (
  <svg
    className={className}
    viewBox="1 0 16 14"
    height="14"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    aria-labelledby="chevronDownId"
    preserveAspectRatio="none"
  >
    <title id="chevronDownId">{alt}</title>
    <path
      className="fill-black"
      d="M3.2925 5.7075C2.9025 6.0975 2.9025 6.7275 3.2925 7.1175L7.8825 11.7075C8.2725 12.0975 8.9025 12.0975 9.2925 11.7075L13.8825 7.1175C14.2725 6.7275 14.2725 6.0975 13.8825 5.7075C13.4925 5.3175 12.8625 5.3175 12.4725 5.7075L8.5825 9.5875L4.7025 5.7075C4.3125 5.3175 3.6725 5.3275 3.2925 5.7075Z"
    />
  </svg>
);
