import React from 'react';
import styles from './styles.module.css';

// This component is used to set the focus before a
// specific element. Once the user hits TAB, the
// element AFTER this one will be focused. This
// helps when the focus is reset on screen transitions
// and you have a specific area you want to have
// usability tabbing on. Clever is you ask me ¯\_(ツ)_/¯

export const SetFocus: React.FC = () => (
  <button autoFocus className={styles.input} />
);
