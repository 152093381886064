import React from 'react';
import styles from './styles.module.css';
import { useIsMobile } from '../Helpers';
import { Logo as CommonLogo } from '../Logo';

export const Logo: React.FC = () => {
  const isMobile = useIsMobile();

  return (
    <div className={styles.logo}>
      <CommonLogo height={isMobile ? 16 : 25.7} width={isMobile ? 112 : 180} />
    </div>
  );
};
