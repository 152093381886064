import * as Yup from 'yup';
import { differenceInYears, isBefore, parse } from 'date-fns';

export const Schema = Yup.object().shape({
  last_name: Yup.string().required('Required'),
  email: Yup.string().required('Required').email('Must be a valid email'),
  zip_code: Yup.string()
    .required('Required')
    .length(5, 'Must be 5 digits in length')
    .trim()
    .matches(/^\d+$/, { message: 'Must only contain numbers' }),
  date_of_birth: Yup.string()
    .required('Required')
    .min(10, 'Must be a valid date')
    .test('minimum-age', 'Please enter a valid age', (value) =>
      value ? differenceInYears(new Date(), value) > 15 : false
    )
    .test('valid-age', 'Please enter a valid birth year', (value) =>
      value ? !isBefore(parse(value), new Date(1900, 1, 1)) : false
    ),
});
