import React, { useEffect, useMemo, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FormikHelpers } from 'formik';
import cx from 'classnames';
import { env } from 'constants/env';
import { slideLeft, TransitionDuration } from 'V2/Shared/Animations/Presets';
import { FullPageLoading } from 'V2/Shared/FullPageLoading';
import { StepHeader } from 'V2/Shared/StepHeader';
import { DynamicStepLoader } from 'V2/Shared/DynamicStepLoader';
import { buildFieldDataPayload, buildFieldErrors } from 'V2/Step';
import { useApplyProvider } from './ApplyProvider';
import { useSubmit, useFeatures } from './ApplyHooks';
import {
  stepsWithoutHeader,
  fadeInSteps,
  headerRequiredSteps,
  stepsWithoutToyotaFooter,
} from './Defaults';
import styles from './styles.module.css';
import { OnSubmitStepType, FormValuesType } from './types';
import { ValidationModal } from 'V2/Shared/ValidationModal';
import { ConsentDisclosure } from 'V2/Shared/ConsentDisclosure';
import { ConsentDisclosureSummariesType } from 'V2/Types';
import { ToyotaFooter } from 'V2/Shared/ToyotaFooter';
import { Formotiv } from 'V2/Analytics';

export const Apply: React.FC = () => {
  const {
    app: { status, config, fields, summary },
    isAnimating,
    setIsAnimating,
  } = useApplyProvider();

  const { validate, submit, setStep, validation, resetValidation } =
    useSubmit();

  const [formActions, setFormActions] = useState<
    FormikHelpers<FormValuesType> | undefined
  >();

  const { tmx_profiling: profiling } = useFeatures(config.features, [
    'tmx_profiling',
  ]);

  useEffect(() => {
    if (profiling && profiling.value) {
      const head = document.getElementsByTagName('head').item(0);
      const script = document.createElement('script');
      const existingScript = document.getElementById('tmx-script');

      if (existingScript && existingScript.parentNode) {
        existingScript.parentNode.removeChild(existingScript);
      }

      script.setAttribute('type', 'text/javascript');
      script.setAttribute('id', 'tmx-script');
      script.setAttribute(
        'src',
        `https://${env.tmProfilingDomain}/fp/tags.js?org_id=${env.tmOrgId}&session_id=${profiling.value}&page_id=1&allow_reprofile=1`
      );

      if (head) {
        head.appendChild(script);
      }
    }
  }, [profiling]);

  const { showHeader, shouldFadeIn } = useMemo(() => {
    return {
      showHeader: !stepsWithoutHeader.includes(status.id),
      shouldFadeIn: fadeInSteps.includes(status.id),
      headerRequired: headerRequiredSteps.includes(status.id),
    };
  }, [status.id]);

  const onSubmitStep: OnSubmitStepType = (values, formActions) => {
    const {
      validation_uri: validationUri,
      current_step_submit_uri: submitUri,
      field_data: fieldData,
    } = status;
    const payload = buildFieldDataPayload(fieldData, values);

    setFormActions(formActions);

    validate(validationUri, payload, formActions).then(() => {
      submit(submitUri, payload, formActions).then((response) => {
        Formotiv.submit();
        if (response.status.id === status.id) {
          setIsAnimating(false);
          formActions &&
            formActions.setStatus({
              apiErrors: {
                errors: buildFieldErrors(response.status.field_data),
              },
            });

          setStep(response);
        } else {
          setStep(response);
        }
      });
    });
  };

  const {
    features_by_key: {
      consent_disclosures: consentDisclosures,
      choice_features,
    },
  } = config;

  // PLEASE never do this again
  const showToyotaFooter = useMemo(() => {
    return (
      choice_features?.toyota_footer &&
      !stepsWithoutToyotaFooter.includes(status.id)
    );
  }, [status.id]);

  const step = (
    <>
      <DynamicStepLoader
        onSubmitStep={onSubmitStep}
        status={status}
        config={config}
        fields={fields}
        summary={summary}
        isSubmitting={isAnimating}
        hasConsentDisclosure={!!consentDisclosures}
      >
        {!!consentDisclosures &&
          Object.entries(consentDisclosures).map(([key, value]) => (
            <ConsentDisclosure
              key={`${key}`}
              className={`${key}`}
              disclosure={value as ConsentDisclosureSummariesType[]}
            />
          ))}
      </DynamicStepLoader>
    </>
  );

  const stepClass =
    cx({
      [styles[`${status.id}`]]: styles[`${status.id}`],
    }) || undefined;

  // Dont begin animation until shouldFadeIn is set.
  return status.id && typeof shouldFadeIn === 'boolean' ? (
    <div className={styles.container} data-testid={`apply-${status.id}`}>
      <div className={styles.main}>
        {
          // If the step fades in and also the header
          // is required, we will want to make sure that
          // the header fades in-sync with the step itself.
          <AnimatePresence exitBeforeEnter initial={false}>
            {headerRequiredSteps && shouldFadeIn ? (
              <motion.div
                key="fadeOutOnly"
                exit={{ opacity: 0 }}
                transition={{ duration: TransitionDuration.seconds }}
              >
                {showHeader && <StepHeader />}
              </motion.div>
            ) : (
              <motion.div
                key={showHeader ? 'static' : 'fade'}
                exit={{ opacity: 0 }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: TransitionDuration.seconds }}
              >
                {showHeader && <StepHeader />}
              </motion.div>
            )}
          </AnimatePresence>
        }
        <AnimatePresence exitBeforeEnter initial={false}>
          {shouldFadeIn ? (
            <motion.div
              className={stepClass}
              key={`${status.id}-${config.application_focus.path.identifier}`}
              exit={{ opacity: 0 }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{
                duration: TransitionDuration.seconds,
                ease: [0.83, 0, 0.17, 1],
              }}
            >
              {step}
            </motion.div>
          ) : (
            <motion.div
              className={stepClass}
              key={`${status.id}-${config.application_focus.path.identifier}`}
              exit={slideLeft.exit}
              initial={slideLeft.initial}
              animate={slideLeft.animate}
              transition={{
                duration: TransitionDuration.seconds,
                ease: [0.83, 0, 0.17, 1],
              }}
            >
              {step}
            </motion.div>
          )}
        </AnimatePresence>
        <ValidationModal
          validation={validation}
          resetValidation={resetValidation}
          handleClick={async (action) => {
            setIsAnimating(true);
            if (action.label === 'Close') {
              return setIsAnimating(false);
            }
            return submit(
              status.current_step_submit_uri,
              action.field_data
            ).then((step) => {
              // If all of the field_data values are null (a.k.a. no user input)
              // then we want to manually reset the form via formActions because
              // formik will not do so automatically when the values do not change.
              if (
                action.field_data.every((f) => f.value === null) &&
                formActions
              ) {
                formActions.resetForm();
                // If we are on the same step that we already were
                // then we want to set animating to false and re-validate
                // the form so that the button enables/disables.
                if (step.status.id === status.id) {
                  setIsAnimating(false);
                  formActions.validateForm();
                }
              }
              setStep(step);
            });
          }}
        />
      </div>
      {showToyotaFooter && <ToyotaFooter />}
    </div>
  ) : (
    <motion.div
      key="loading"
      exit={{ opacity: 0 }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: TransitionDuration.seconds }}
    >
      <FullPageLoading />
    </motion.div>
  );
};
