import React, { useMemo } from 'react';
import { useQuery } from 'V2/Hooks';
import { StepConfigType } from 'V2/Types';
import {
  useApplyProvider,
  useFocusEntity,
  useUpdateEntityStatus,
} from 'V2/Apply';
import { configDefault } from 'V2/Step';
import { Bailout } from 'V2/Shared/Bailout';
import styles from './styles.module.css';
import { DocumentSummariesAndTemplates } from '../DocumentSummariesAndTemplates';

type StepFormType = {
  onSubmit?: () => void;
  config?: StepConfigType;
};

const BAILOUT_IDS = ['remove_driver', 'remove_vehicle', 'reactivate_driver'];

export const StepForm: React.FC<StepFormType> = ({
  onSubmit = () => {},
  config = configDefault,
  children,
}) => {
  const isEditMode = useQuery().get('edit') === 'true';
  const {
    app: { summary },
  } = useApplyProvider();
  const { update, isUpdating } = useUpdateEntityStatus();
  const entity = useFocusEntity(summary, config);

  const stepDisclosureSummaries = useMemo(
    () => config.features_by_key.step_disclosure_summaries,
    [config.features_by_key.step_disclosure_summaries]
  );
  const stepDisclosureTemplates = useMemo(
    () => config.features_by_key.step_disclosure_templates,
    [config.features_by_key.step_disclosure_templates]
  );

  return (
    <form onSubmit={onSubmit} className={styles.main} noValidate>
      {children}
      {/*
          driver/vehicle bailout is used for a number of steps
          so we are adding that logic here; if there are any other steps
          that rely on a flavor of bailout (accidents/violations), that
          logic is done in those components; this adheres to the "95%" rule;
          we don't want to include driver/vehicle bailout in edit

          by default, isUpdating is null; only pass label when it is null to
          avoid showing the label for another command after update starts
        */}
      {!isEditMode &&
        entity &&
        entity.commands
          .filter((c) => BAILOUT_IDS.includes(c.id))
          .map((command, i) => (
            <Bailout
              key={i}
              onClick={() => update(command)}
              isSubmitting={command.id === isUpdating}
            >
              {isUpdating === null && command.label}
            </Bailout>
          ))}
      {stepDisclosureSummaries && stepDisclosureTemplates && (
        <DocumentSummariesAndTemplates
          summaries={stepDisclosureSummaries}
          templates={stepDisclosureTemplates}
        />
      )}
    </form>
  );
};
