import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.css';
import styled from 'styled-components/macro';

type SpinnerTypes = {
  variant?: 'blue' | 'large' | 'white' | 'gray' | 'yellow';
  color?: any;
};

const StyledSpinner = styled.div<{ color: any }>`
  &:before {
    border-top-color: ${({ color }) => color} !important;
  }
`;

export const Spinner: React.FC<SpinnerTypes> = ({ variant, color }) => {
  if (color) {
    return <StyledSpinner color={color} className={styles.spinner} />;
  }

  const spinnerClass = classNames(styles.spinner, variant && styles[variant]);
  return <div className={spinnerClass} />;
};
