import React from 'react';

export const UserPlaceholder: React.FC = () => (
  <svg height="30" viewBox="0 0 30 30" width="30">
    <g
      transform="translate(1 1)"
      fill="none"
      fillRule="evenodd"
      stroke="#adb5bd"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    >
      <path d="M4.9 24.5c3.267-1.4 4.767-2.567 5.7-3.5 1.4-1.4-2.2-1.4-2.2-8.4 0-4.667 1.867-7 5.6-7 3.733 0 5.6 2.333 5.6 7 0 7-3.5 7-2.1 8.4.933.933 2.333 2.1 5.6 3.5" />
      <circle cx="14" cy="14" r="14" />
    </g>
  </svg>
);
