import React from 'react';
import { Phone } from '../Icons/Phone';
import { ToyotaInsurance } from '../Icons/ToyotaInsurance';
import { Choice } from '../Icons/Choice';

const ExternalLink = ({ label, href }: { label: string; href: string }) => (
  <a
    className="font-size-14 font-bold mb-16 color-black ml-md-32"
    href={href}
    target="_blank"
    rel="noreferrer noopener"
  >
    {label}
  </a>
);

const Links = ({ className = '' }: { className: string }) => (
  <div
    className={`external-links display-flex flex-column flex-row-md ${className}`}
  >
    <ExternalLink href="https://toyotaims.com/contact" label="Contact Us" />
    <ExternalLink
      href="https://toyotaims.com/terms.php"
      label="Terms & Conditions"
    />
    <ExternalLink
      href="https://toyotaims.com/privacy.php"
      label="Privacy Policy"
    />
  </div>
);

export const ToyotaFooter = () => {
  return (
    <div className="toyota-footer p-32 bg-grey-05 caption font-size-12">
      <div className="flex-row-md justify-between mb-12 mb-md-24">
        <ToyotaInsurance />
        <Links className="hidden-sm" />
      </div>
      <div className="font-size-14 font-bold mb-8 flex-row align-center">
        <Phone fillClassName="fill-black" />
        <a className="color-black ml-8">(866) 474-8855</a>
      </div>
      <div className="toyota-hours mb-24">
        <div>Mon-Fri: 7:30am - 8:00pm (Central Time)</div>
        <div>Sat: 8:00am - 5:00pm (Central Time)</div>
      </div>
      <div className="toyota-disclosure mb-24 mb-md-8">
        Toyota Insurance is a brand name of Toyota Insurance Management
        Solutions USA, LLC (TIMS). TIMS is a licensed insurance agency in all
        states and the District of Columbia. TIMS is a Delaware-domiciled
        limited liability company with its principal place of business at 7600
        Windrose Avenue, Suite G240 Plano, TX 75024, USA. In California, TIMS
        operates under the name Toyota Insurance Management Solutions, LLC and
        license number 0L46875. In New York, TIMS operates under the name Toyota
        Insurance Management Solutions Agency and license number 1494‍294. The
        issuance of a license is not an endorsement or sponsorship, actual or
        implied, by any state. Products underwritten by TIMS’ 3rd party
        insurance carriers. Product availability varies by state. Coverages,
        benefits, and discounts may vary by state and are subject to terms and
        conditions of the policy.
      </div>
      <Links className="hidden-md" />
      <div className="mb-16 mb-md-32">
        © {new Date().getFullYear()} Toyota Insurance Management Solutions USA,
        LLC
      </div>
      <div className="flex-row align-center">
        <span>Powered By</span>
        <Choice />
      </div>
    </div>
  );
};
