import { SERVER_ERROR, TIMEOUT_ERROR } from '../Constants';

export const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const POLL_MAX_ATTEMPTS = 20;
const POLL_DELAY = 3000;

/**
 * Common use case: polling an api endpoint until a status reaches a completion state
 *
 * @param callback Promise that will be run until it resolves. A reject causes a retry until maxAttempts is reached.
 * @param maxAttempts Reject once maxAttempts is reached
 * @param delay Pause between attempts
 */
export const runWithPolling = async function <T>(
  callback: () => Promise<T>,
  shouldResolve: (response: T) => boolean,
  shouldFail: (response: T) => boolean,
  maxAttempts: number = POLL_MAX_ATTEMPTS,
  delay: number = POLL_DELAY
): Promise<T> {
  return new Promise<T>((resolve, reject) => {
    callback()
      .then(async (result) => {
        if (shouldResolve(result)) {
          resolve(result);
        } else if (maxAttempts === 1) {
          reject(TIMEOUT_ERROR);
        } else if (shouldFail(result)) {
          reject(SERVER_ERROR);
        } else {
          await sleep(delay);
          runWithPolling(
            callback,
            shouldResolve,
            shouldFail,
            maxAttempts - 1,
            delay
          )
            .then(resolve)
            .catch(reject);
        }
      })
      .catch((err) => reject(err));
  });
};
