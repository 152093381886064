import createAuth0Client from '@auth0/auth0-spa-js';
import { env } from 'constants/env';

type OnRedirectCallbackType = (appState?: { targetUrl?: string }) => void;

type Auth0ConfigType = {
  domain: string;
  client_id: string;
  redirect_uri: string;
  audience: string;
  leeway: number;
  onRedirectCallback: OnRedirectCallbackType;
};

type AgentAuthType = () => Promise<string>;

export const agentAuth: AgentAuthType = () =>
  new Promise((resolve, reject) => {
    const onRedirectCallback: OnRedirectCallbackType = (appState) => {
      window.history.replaceState(
        {},
        document.title,
        appState && appState.targetUrl
          ? appState.targetUrl
          : window.location.pathname
      );
    };

    const auth0Config: Auth0ConfigType = {
      domain: env.auth0Domain,
      client_id: env.auth0ClientId,
      redirect_uri: env.auth0RedirectUrl,
      audience: env.auth0Audience,
      leeway: 120,
      onRedirectCallback,
    };

    const initAuth0 = async () => {
      try {
        const auth0FromHook = await createAuth0Client(auth0Config);

        if (window.location.search.includes('code=')) {
          const { appState } = await auth0FromHook.handleRedirectCallback();
          onRedirectCallback(appState);
        }

        const isAuthenticated = await auth0FromHook.isAuthenticated();

        if (isAuthenticated) {
          const token = await auth0FromHook.getTokenSilently();
          resolve(token);
        } else {
          await auth0FromHook.loginWithRedirect();
        }
      } catch (error) {
        reject(error);
      }
    };

    initAuth0();
  });
