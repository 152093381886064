import React, { useState, ChangeEvent, forwardRef, Ref } from 'react';
import { Field } from 'formik';
import { Errors } from '../Errors';
import styles from './styles.module.css';
import { TextInputProps } from './types';
import { FieldRenderProps } from '../types';

export const Input: React.FC<TextInputProps & { ref?: Ref<HTMLInputElement> }> =
  forwardRef(
    (
      {
        label,
        name,
        autoFocus,
        placeholder,
        pattern,
        normalize,
        validate,
        type = 'text',
        autoComplete = 'on',
        onChange,
        onFocus,
        onBlur,
        disabled,
        ...inputProps
      },
      ref
    ) => {
      const [isFocused, setIsFocused] = useState(false);

      return (
        <Field name={name} validate={validate}>
          {({ field, form }: FieldRenderProps) => (
            <div className={styles.group}>
              <div className={styles.inputWrapper} data-testid={name}>
                <input
                  {...field}
                  {...inputProps}
                  id={name}
                  data-testid="input"
                  disabled={disabled}
                  type={type}
                  autoComplete={autoComplete}
                  value={normalize ? normalize(field.value) : field.value}
                  onBlur={() => {
                    setIsFocused(false);
                    form.validateField(field.name);
                    if (onBlur) {
                      onBlur();
                    }
                  }}
                  onFocus={(e) => {
                    if (onFocus) {
                      onFocus(e);
                    }

                    setIsFocused(true);
                  }}
                  className={styles.input}
                  pattern={pattern}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    field.onChange(e);

                    if (onChange) {
                      onChange(e);
                    }
                  }}
                  placeholder={isFocused ? placeholder : ' '}
                  autoFocus={autoFocus}
                  ref={ref}
                />
                <label htmlFor={name} data-testid="label">
                  {label}
                </label>
              </div>
              <Errors fieldName={name} formStatus={form.status} />
            </div>
          )}
        </Field>
      );
    }
  );
