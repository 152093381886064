import { env } from 'constants/env';
import { Heap, FullStory } from 'V2/Analytics';

import { ConfigType, HttpType } from './types';
import { createAxiosInstance } from './AxiosConfig';
import {
  createRequestInterceptor,
  createResponseInterceptor,
} from './AxiosConfig/interceptors';

declare global {
  interface Window {
    AwsWafIntegration: any;
  }
}

const axiosInstance = createAxiosInstance({
  baseURL: env.apiHost,
  onRequestFulfilled: createRequestInterceptor(async (request) => {
    try {
      const awsWafToken: string =
        window.AwsWafIntegration && (await window.AwsWafIntegration.getToken());

      if (!!awsWafToken) {
        request.headers.set('X-Aws-Waf-Token', awsWafToken);
      }
    } catch (error: any) {
      Heap.track('aws_waf_error', {
        message: error,
      });
    }
  }),
  onResponseFulfilled: createResponseInterceptor(async (response) => {
    if (
      response.status === 201 &&
      response.config.url &&
      response.config.url.includes('collected_steps') &&
      !response.config.url.includes('edit')
    ) {
      Heap.track('step_complete');
    }

    if (
      response.status === 200 &&
      response.config.url &&
      response.config.url.includes('/v2/status')
    ) {
      // at this point when step_start is called, the Path
      // is still the previous step; we send a step_id attribute
      // to tell the event which step is actually started
      Heap.track('step_start', {
        step_id: response.data.id,
      });
    }
  }),
});

const getDefaultHeaders = () => ({
  'Clearcover-Heap-UserId': window.heap?.userId || '',
  'Clearcover-Heap-Identity': window.heap?.identity || '',
  'Clearcover-Fullstory-Identity': FullStory.getCurrentSession() || '',
  ...(localStorage.getItem('authToken')
    ? {
        Authorization: `Bearer ${localStorage.getItem('authToken')}`,
      }
    : {
        'Clearcover-Applicant-Authorization-Token':
          sessionStorage.getItem('authToken') || '',
      }),
  'Clearcover-ClickId': sessionStorage.getItem('clickId') || '',
});

export const http: HttpType = {
  get: (config: ConfigType) =>
    axiosInstance.request({
      ...config,
      method: 'get',
      responseType: 'json',
      url: config.url,
      baseURL: env.apiHost,
      headers: {
        ...getDefaultHeaders(),
        ...config.headers,
      },
    }),
  post: (config: ConfigType) =>
    axiosInstance.request({
      ...config,
      method: 'post',
      responseType: 'json',
      url: config.url,
      baseURL: env.apiHost,
      headers: {
        ...getDefaultHeaders(),
        ...config.headers,
      },
    }),
};
