import React from 'react';
import cx from 'classnames';
import { ConsentDisclosureSummariesType } from 'V2/Types';
import { parseReactElements } from 'V2/Utilities/ParseReactFromJson';
import styles from './styles.module.css';

export type ConsentDisclosureType = {
  disclosure: ConsentDisclosureSummariesType[];
  className?: string;
};

export const ConsentDisclosure: React.FC<ConsentDisclosureType> = ({
  disclosure,
  className,
}) => {
  const disclosureClass = cx(
    {
      [styles[`${className}`]]: className && styles[`${className}`],
    },
    styles.consentDisclosure,
    'consentDisclosureContainer'
  );

  if (!disclosure || !disclosure.length) return null;

  return (
    <div className={disclosureClass} data-testid="consent-disclosure">
      {disclosure.map((summaries) =>
        parseReactElements(summaries.content, {
          convertAnchorsToRouterLinks: false,
        })
      )}
    </div>
  );
};
