import React, { useEffect, useCallback } from 'react';
import classNames from 'classnames';
import { DocumentViewerPropsType } from './types';
import { useScrollProgress } from './hooks';
import { Spinner } from 'V2/Shared/Spinner';
import styles from './styles.module.css';
import { Logo } from '../Logo';
import { Close } from '../Icons/Close';
import { Print } from '../Icons/Print';

export const DocumentViewer: React.FC<DocumentViewerPropsType> = ({
  headingText,
  onCloseClick,
  showProgress,
  onProgressEnd,
  isSubmitting,
  children,
}) => {
  const { scrollProgress } = useScrollProgress();

  const progressEnd = useCallback(() => {
    if (scrollProgress >= 85) {
      return onProgressEnd && onProgressEnd();
    }
  }, [onProgressEnd, scrollProgress]);

  useEffect(() => progressEnd(), [progressEnd]);

  const buttonClassName = classNames(styles.button, {
    [styles.isSubmitting]: isSubmitting,
  });

  return (
    <div className={styles.documentViewer}>
      <div className={styles.header}>
        <div className={styles.headerInner}>
          <Logo className={styles.logo} useImageTag />
          {headingText && (
            <div className={styles.headingText}>{headingText}</div>
          )}
          {onCloseClick && (
            <button
              type="button"
              className={buttonClassName}
              onClick={onCloseClick}
              data-testid="document-close"
              disabled={isSubmitting}
            >
              <div className={styles.close}>Close</div>
              {isSubmitting ? (
                <span className={styles.spinnerClose} data-testid="spinner">
                  <Spinner variant={'gray'} />
                </span>
              ) : (
                <div className={styles.icon}>
                  <Close />
                </div>
              )}
            </button>
          )}
        </div>
        {headingText && (
          <div className={styles.headingTextMobile}>{headingText}</div>
        )}
        {showProgress && (
          <div className={styles.progressWrapper}>
            <div
              role="progressbar"
              className={styles.progressBar}
              style={{ width: `${scrollProgress}%` }}
            />
          </div>
        )}
      </div>
      <div className={styles.documentContentOuter}>
        <div className={styles.documentContentInner}>{children}</div>
      </div>
      <div className={styles.formActionsPrint}>
        <button
          className={styles.printButton}
          onClick={(e) => {
            e.preventDefault();
            window.print();
            onCloseClick && onCloseClick();
          }}
          data-testid="document-print"
        >
          <span className={`${styles.icon} ${styles.printIcon}`}>
            <Print />
          </span>
        </button>
      </div>
      <div className={styles.print}>{children}</div>
    </div>
  );
};
