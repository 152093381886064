export type GetQueryParamsType = {
  [key: string]: string;
};

export const getQueryParams = (queryParams: string): GetQueryParamsType => {
  const queries: GetQueryParamsType = {};
  const params = queryParams.substring(1).split('&');

  params.forEach((param) => {
    const i = param.split('=');
    if (!i[1]) return;
    queries[decodeURIComponent(i[0])] = decodeURIComponent(i[1]);
  });

  return queries;
};
