export const monthDateYearNormalizer = (value: string): string => {
  if (!value) return value;

  const digits = Array.from(value).filter((char) => /\d/.test(char));

  return [digits.slice(0, 2), digits.slice(2, 4), digits.slice(4, 8)]
    .filter((chunk) => chunk.length > 0)
    .map((chunk) => chunk.join(''))
    .join('/');
};

export const monthDateYearNormalizerWithCursor = (
  inputString: string,
  cursorPoint: number
): { formattedText: string; newCursorPoint: number } => {
  if (!inputString) return { formattedText: inputString, newCursorPoint: 0 };

  const MONTH_LENGTH = 2;
  const DAY_LENGTH = 2;
  const YEAR_LENGTH = 4;

  const chunkLengths = [MONTH_LENGTH, DAY_LENGTH, YEAR_LENGTH];

  const length = inputString.length;

  let chunkNumber = 0, // current chunk number
    chunkLength = 0; // current length of current chunk
  let formattedText = ''; // formattedText that we're going to return
  let newCursorPoint = cursorPoint, // new cursor point after formatting
    i = 0;

  // Move to the next chunk & update some variables
  // when afterNumber is true, that means it moves to the next chunk as the length of the current chunk is reached
  // when afterNumber is false, that means it moves to the next chunk as '/' is found
  const moveToNextChunk = (afterNumber = true) => {
    ++chunkNumber;
    // Add '/' after each chunk except the last one
    if (chunkNumber < 3) {
      formattedText += '/';
      // Increase the cursor point as / is added before the cursor
      if (i < cursorPoint && afterNumber) {
        ++newCursorPoint;
      }
    }
    chunkLength = 0;
  };

  // Iterate over each Date-input character and get the formattedText and newCursorPoint
  for (i = 0; i < length; ++i) {
    const char = inputString[i];

    // If the character is a number
    if (/\d/.test(char)) {
      // If the cursor is at the end of the current chunk, move it to the next chunk
      if (chunkLength === chunkLengths[chunkNumber]) {
        moveToNextChunk();
        // If the chunk number is 3, that means we have already formatted the date(MM/DD/YYYY)
        if (chunkNumber === 3) {
          break;
        }
      }
      // Add the character to the formattedText
      formattedText += char;
      ++chunkLength;
      continue;
    }

    // If the character is '/', move to the next chunk
    if (char === '/') {
      moveToNextChunk(false);
      if (chunkNumber === 3) {
        break;
      }
      continue;
    }

    // If the character is not a number or '/', decrease the cursor point as we deleted it
    if (i < cursorPoint) {
      --newCursorPoint;
    }
  }

  return { formattedText, newCursorPoint };
};
