import { css } from 'styled-components/macro';
import { Color, Variant } from './Button';
import { StyledButtonProps } from './styles';

const gray10 = css`#F2F3F8`;
const gray20 = css`#DDE0E6`;
const gray30 = css`#C1C6CD`;

const dangerLightest = css`#FADBDC`;
const dangerLight = css`#F3A5A8`;
const danger = css`#da1e28`;
const dangerDark = css`#B11018`;

export const brand1Lightest = css`var(--brand-01-lightest)`;
export const brand1Light = css`var(--brand-01-light)`;
export const brand1 = css`var(--brand-01)`;
export const brand1Dark = css`var(--brand-01-shade)`;

export const brand2 = css`var(--brand-02)`;

export const brand3 = css`var(--brand-03)`;
export const brand3Light = css`var(--brand-03-light)`;

export type ButtonColorDefinition = {
  color: any;
  screenColor: any;
  backgroundColor: any;
  borderColor: any;
  shadowColor: any;
  interactive: {
    backgroundColor: any;
    borderColor: any;
    outlineColor: any;
    // made this option because it needs to be set for primary outlined since the shadow on hover needs to change
    // maybe make it universal and explicit if this pattern is used someonewhere else
    shadowColor?: any;
  };
  disabled: {
    backgroundColor: any;
    borderColor: any;
    color: any;
  };
};

export const primaryContainedColors: ButtonColorDefinition = {
  color: brand2,
  screenColor: brand2,
  backgroundColor: brand1,
  borderColor: brand1,
  shadowColor: brand1Dark,
  interactive: {
    backgroundColor: brand1Dark,
    borderColor: brand1Dark,
    outlineColor: brand1Light,
  },
  disabled: {
    backgroundColor: gray10,
    borderColor: gray10,
    color: gray30,
  },
};

export const primaryOutlinedColors: ButtonColorDefinition = {
  color: brand1,
  screenColor: css<StyledButtonProps>`
    ${({ isDarkBackground }) => (isDarkBackground ? brand1 : brand2)}
  `,
  backgroundColor: brand2,
  borderColor: gray20,
  shadowColor: gray20,
  interactive: {
    backgroundColor: brand1Lightest,
    borderColor: brand1,
    outlineColor: brand1Light,
    shadowColor: brand1,
  },
  disabled: {
    backgroundColor: gray10,
    borderColor: gray10,
    color: gray30,
  },
};

export const primaryLinkColors: ButtonColorDefinition = {
  color: brand1,
  screenColor: brand2,
  backgroundColor: brand2,
  borderColor: brand2,
  shadowColor: brand2,
  interactive: {
    backgroundColor: brand1Lightest,
    borderColor: brand2,
    outlineColor: brand1Light,
  },
  disabled: {
    backgroundColor: gray10,
    borderColor: gray10,
    color: gray30,
  },
};

export const secondaryContainedColors: ButtonColorDefinition = {
  color: brand1,
  screenColor: css<StyledButtonProps>`
    ${({ isDarkBackground }) => (isDarkBackground ? brand1 : brand2)}
  `,
  backgroundColor: brand3,
  borderColor: css<StyledButtonProps>`
    ${({ isDarkBackground }) => (isDarkBackground ? brand3 : brand1)}
  `,
  shadowColor: brand1Dark,
  interactive: {
    backgroundColor: brand3Light,
    borderColor: css<StyledButtonProps>`
      ${({ isDarkBackground }) => (isDarkBackground ? brand3 : brand1)}
    `,
    outlineColor: brand1Light,
  },
  disabled: {
    backgroundColor: brand3Light,
    borderColor: css<StyledButtonProps>`
      ${({ isDarkBackground }) =>
        isDarkBackground ? brand3Light : brand1Light}
    `,
    color: brand1Light,
  },
};

export const secondaryOutlinedColors: ButtonColorDefinition = {
  color: brand3,
  screenColor: css<StyledButtonProps>`
    ${({ isDarkBackground }) => (isDarkBackground ? brand1 : brand2)}
  `,
  backgroundColor: brand1,
  borderColor: css<StyledButtonProps>`
    ${({ isDarkBackground }) => (isDarkBackground ? brand3 : brand1)}
  `,
  shadowColor: brand3,
  interactive: {
    backgroundColor: brand1Dark,
    borderColor: css<StyledButtonProps>`
      ${({ isDarkBackground }) => (isDarkBackground ? brand3 : brand1)}
    `,
    outlineColor: brand1Light,
  },
  disabled: {
    backgroundColor: brand1,
    borderColor: brand3Light,
    color: brand3Light,
  },
};

export const secondaryLinkColors: ButtonColorDefinition = {
  color: brand2,
  screenColor: brand1,
  backgroundColor: brand1,
  borderColor: brand1,
  shadowColor: brand1,
  interactive: {
    backgroundColor: brand1Dark,
    borderColor: brand1,
    outlineColor: brand2,
  },
  disabled: {
    backgroundColor: gray10,
    borderColor: gray10,
    color: gray30,
  },
};

export const dangerContainedColors: ButtonColorDefinition = {
  color: brand2,
  screenColor: brand2,
  backgroundColor: danger,
  borderColor: danger,
  shadowColor: dangerDark,
  interactive: {
    backgroundColor: dangerDark,
    borderColor: dangerDark,
    outlineColor: dangerLight,
  },
  disabled: {
    backgroundColor: gray10,
    borderColor: gray10,
    color: gray30,
  },
};
export const dangerOutlinedColors: ButtonColorDefinition = {
  color: danger,
  screenColor: brand2,
  backgroundColor: brand2,
  borderColor: danger,
  shadowColor: dangerDark,
  interactive: {
    backgroundColor: dangerLightest,
    borderColor: danger,
    outlineColor: dangerLight,
  },
  disabled: {
    backgroundColor: gray10,
    borderColor: gray10,
    color: gray30,
  },
};

export const dangerLinkColors: ButtonColorDefinition = {
  color: danger,
  screenColor: brand2,
  backgroundColor: brand2,
  borderColor: brand2,
  shadowColor: brand2,
  interactive: {
    backgroundColor: dangerLightest,
    borderColor: dangerLightest,
    outlineColor: dangerLight,
  },
  disabled: {
    backgroundColor: gray10,
    borderColor: gray10,
    color: gray30,
  },
};

export const getButtonColors = (
  color: Color,
  variant: Variant
): ButtonColorDefinition => {
  const definitions: any = {
    'primary-contained': primaryContainedColors,
    'primary-outlined': primaryOutlinedColors,
    'primary-link': primaryLinkColors,
    'secondary-contained': secondaryContainedColors,
    'secondary-outlined': secondaryOutlinedColors,
    'secondary-link': secondaryLinkColors,
    'danger-contained': dangerContainedColors,
    'danger-outlined': dangerOutlinedColors,
    'danger-link': dangerLinkColors,
  };

  const key = `${color}-${variant}`;

  // current prettier config doesn't work with advanced typescript features... need to explore this later
  if (definitions[key]) {
    return definitions[key];
  }

  throw new Error(
    `Button definition not defined for color:${color} and variant:${variant}.
       If this is a new definition add it to buttonStyleDefinition`
  );
};
