import React from 'react';
import { getIn } from 'formik';
import { ErrorsType } from './types';
import { FieldError } from 'V2/Shared/FieldError';

export const Errors: React.FC<ErrorsType> = ({ formStatus, fieldName }) => {
  if (!formStatus || !formStatus.apiErrors) return null;
  const error = getIn(formStatus.apiErrors.errors, fieldName);
  if (!error) return null;
  return (
    <FieldError field={fieldName} message={error}>
      {error}
    </FieldError>
  );
};
