import { states } from './addresses';
import { baseDriver } from './drivers';
import { baseVehicle, commute, pleasure } from './vehicles';

export const generatePayload = (state: keyof typeof states, apiKey: string) => {
  return {
    policy: {
      term_in_months: 6,
      mailing_address: states[state],
    },
    ...additionalFields(apiKey),
    vehicles: [baseVehicle, pleasure, commute],
    drivers: [baseDriver(state)],
  };
};

const additionalFields = (api: string) => {
  return api === 'qapi'
    ? {
        current_policy: {
          carrier_name: 'Allstate',
          state: 'CO',
          policy_start_date: '2020-09-01',
          policy_end_date: '2021-05-01',
          term_in_months: 6,
          customer_loyalty_in_months: 24,
          policy: {
            coverages: {
              bodily_injury: {
                limit_enum: 'thirty_sixty',
              },
            },
          },
          insurance_status: {
            is_currently_insured: true,
          },
        },
        quote_metadata: {
          campaign_medium: 'Lorem ad exercitation',
          campaign_name: 'Spring campaign',
          quote_identifier: 'dolore',
          quote_name: 'Basic',
          partner_unstructured_metadata: {
            cc_acceptance_tests: true,
          },
          test_scenario: {
            base_case: 'quotable',
          },
        },
      }
    : {};
};
