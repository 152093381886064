import React from 'react';

export const CircleChevronUp = ({ alt = 'arrow' }: { alt?: string }) => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    aria-labelledby="circleChevronUpId"
  >
    <title id="circleChevronUpId">{alt}</title>
    <g filter="url(#filter0_d)">
      <circle
        className="fill-brand-01"
        cx="17"
        cy="16"
        r="11"
        transform="rotate(-180 17 16)"
      />
    </g>
    <path
      className="fill-brand-03"
      d="M21.8594 18.087C21.4985 18.452 20.9091 18.452 20.5482 18.087L17 14.4976L13.4517 18.087C13.0909 18.452 12.5014 18.452 12.1406 18.087C11.7856 17.7279 11.7856 17.1501 12.1406 16.7909L16.2888 12.5946C16.6802 12.1986 17.3197 12.1986 17.7111 12.5946L21.8594 16.7909C22.2143 17.1501 22.2143 17.7279 21.8594 18.087Z"
    />
    <mask
      id="mask0"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="11"
      y="12"
      width="12"
      height="7"
    >
      <path
        d="M21.8594 18.087C21.4985 18.452 20.9091 18.452 20.5482 18.087L17 14.4976L13.4517 18.087C13.0909 18.452 12.5014 18.452 12.1406 18.087C11.7856 17.7279 11.7856 17.1501 12.1406 16.7909L16.2888 12.5946C16.6802 12.1986 17.3197 12.1986 17.7111 12.5946L21.8594 16.7909C22.2143 17.1501 22.2143 17.7279 21.8594 18.087Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0)">
      <rect
        className="fill-brand-03"
        x="90"
        y="88"
        width="147"
        height="147"
        transform="rotate(-180 90 88)"
      />
    </g>
    <defs>
      <filter
        id="filter0_d"
        x="0"
        y="0"
        width="34"
        height="34"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="3" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.196643 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
