import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { EntityType } from 'V2/Types';
import { useApplyProvider } from 'V2/Apply';
import { CircleChevronDown } from 'V2/Shared/Icons';
import { CircleChevronUp } from 'V2/Shared/Icons';

import { findEntities } from 'V2/Utilities/SummaryHelpers';
import { EditableItem } from './EditableItem';
import styles from './styles.module.css';
import { Logo } from 'V2/Shared/Logo';

type ItemType = {
  title: string;
  items: EntityType[];
  getSearch: (id: string) => string;
};

export const StepHeader: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();

  const {
    app: {
      status: {
        progress_data: progressData,
        insurance_application_id: insuranceAppId,
      },
      summary,
    },
  } = useApplyProvider();

  const {
    driver: drivers,
    vehicle: vehicles,
    coverage: coverages,
  } = findEntities(summary.entities, ['driver', 'vehicle', 'coverage']);

  const headerProgress =
    progressData &&
    progressData.find(
      (p) => p.type.id === 'insurance_application' && p.id === insuranceAppId
    );

  const entities: ItemType[] = [
    {
      title: 'Drivers',
      items: drivers,
      getSearch: (id: string) => `?edit=true&entityId=${id}`,
    },
    {
      title: 'Vehicles',
      items: vehicles,
      getSearch: (id: string) => `?edit=true&entityId=${id}`,
    },
    {
      title: 'Coverages',
      items: coverages,
      getSearch: (id: string) => `?edit=true&entityId=${id}&stepName=coverages`,
    },
  ];

  return (
    <div data-testid="step-header" className={styles.container}>
      <div className={styles.heading}>
        <div className={styles.logoContainer}>
          <Logo className={styles.stepHeaderLogo} useMarkOnMobile />
        </div>
        <div data-testid="progress" className={styles.progress}>
          {!isOpen && (
            <div className={styles.header}>
              <div className={styles.name}>
                {headerProgress ? headerProgress.label : 'Customer Info'}
              </div>
              <div className={styles.step}>
                {headerProgress ? headerProgress.description : 'Step 1 of 1'}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={styles.content}>
        <div
          className={isOpen ? styles.expand : styles.collapse}
          data-testid="inner-content"
        >
          <div className={styles.editItems}>
            {entities.map((entity, i) => (
              <div
                className={styles.editEntity}
                key={i}
                data-testid={`entity-${i}`}
              >
                <div className={styles.editEntityHeader}>{entity.title}</div>
                <div className={styles.editEntityItems}>
                  {entity.items
                    // filter out any drivers without detail_uri - undisclosed additional drivers have a null detail_uri
                    .filter((item) =>
                      item.type.id === 'driver' ? item.detail_uri : true
                    )
                    .map((item, i) => (
                      <EditableItem
                        key={i}
                        disabled={!item.detail_uri || !item.editable}
                        label={item.label}
                        onClick={() => {
                          history.push({
                            search: entity.getSearch(item.id),
                          });
                        }}
                      />
                    ))}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.drawer}>
          <div>
            <button
              className="cc-step-header__toggle"
              onClick={() => setIsOpen(!isOpen)}
              data-testid="step-header-toggle"
            >
              {isOpen ? (
                <CircleChevronUp alt="menu arrow" />
              ) : (
                <CircleChevronDown alt="menu arrow" />
              )}
            </button>
          </div>
        </div>
        <div className={styles.bar}>
          <div className={styles.progressContainer}>
            <div
              data-testid="progress-bar"
              className={styles.progressInner}
              style={{
                width: `${
                  headerProgress && headerProgress.value
                    ? headerProgress.value
                    : 0
                }%`,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
