import React from 'react';

export const Pencil = () => (
  <svg
    width="15"
    height="14"
    viewBox="0 0 15 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    aria-labelledby="pencilId"
  >
    <title id="pencilId">edit</title>
    <path
      className="fill-brand-01"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.3013 2.04498C14.6054 2.34827 14.6054 2.84059 14.3013 3.14475L12.8764 4.5696L9.95977 1.65296L11.3846 0.228116C11.6888 -0.0760386 12.1811 -0.0760386 12.4844 0.228116L14.3013 2.04498ZM0.529297 14V11.0834L9.13534 2.47739L12.052 5.39402L3.44593 14H0.529297Z"
    />
  </svg>
);
