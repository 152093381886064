import React from 'react';

export const Help = ({
  alt = 'help',
  className = '',
  testId = 'help-icon',
  fillClassName = 'fill-brand-01',
}: {
  alt?: string;
  className?: string;
  testId?: string;
  fillClassName?: string;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    role="img"
    aria-labelledby="helpTitle"
    data-testId={testId}
    className={className}
  >
    <title id="helpTitle">{alt}</title>
    <path
      className={fillClassName}
      d="M8 0a8 8 0 0 0-8 8 8 8 0 0 0 8 8 8 8 0 0 0 8-8 8 8 0 0 0-8-8zm.8 13.6H7.2V12h1.6v1.6zm1.653-6.197l-.716.734C9.158 8.717 8.8 9.2 8.8 10.4H7.2V10c0-.883.358-1.683.937-2.262l.994-1.007A1.6 1.6 0 1 0 6.4 5.6H4.8a3.2 3.2 0 0 1 6.4 0c0 .704-.285 1.342-.747 1.803z"
    />
  </svg>
);
