import React, { FC } from 'react';
import styles from './styles.module.css';
import classNames from 'classnames';

export type CTAType = 'default' | 'splash';

export type MobileSubmitButtonWrapperType = {
  variant?: CTAType;
  'data-testid'?: string;
  className?: string;
};

export const MobileSubmitButtonWrapper: FC<MobileSubmitButtonWrapperType> = ({
  children,
  className,
  variant = 'default',
  'data-testid': dataTestid = 'step-submit',
}) => (
  <div
    data-testid={dataTestid}
    className={classNames(styles.ctaMobile, styles[variant], className)}
  >
    {children}
  </div>
);
