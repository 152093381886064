import React from 'react';

export const CircleInfo = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_4159_163292)">
      <path
        d="M7.99998 1.33325C4.31998 1.33325 1.33331 4.31992 1.33331 7.99992C1.33331 11.6799 4.31998 14.6666 7.99998 14.6666C11.68 14.6666 14.6666 11.6799 14.6666 7.99992C14.6666 4.31992 11.68 1.33325 7.99998 1.33325ZM7.99998 11.3333C7.63331 11.3333 7.33331 11.0333 7.33331 10.6666V7.99992C7.33331 7.63325 7.63331 7.33325 7.99998 7.33325C8.36665 7.33325 8.66665 7.63325 8.66665 7.99992V10.6666C8.66665 11.0333 8.36665 11.3333 7.99998 11.3333ZM8.66665 5.99992H7.33331V4.66659H8.66665V5.99992Z"
        fill="#4024D3"
      />
    </g>
    <defs>
      <clipPath id="clip0_4159_163292">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
