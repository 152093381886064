import { FormValuesType } from 'V2/Apply';
import { FieldDataType } from 'V2/Types';

export const buildFormValues = (fields: FieldDataType[]): FormValuesType =>
  fields.reduce(
    (values, field) => ({
      ...values,
      [field.entity_id]: fields.reduce(
        (values, f) => ({
          ...values,
          ...(field.entity_id === f.entity_id
            ? {
                [f.field_id]: f.value === null ? '' : f.value,
              }
            : {}),
        }),
        {}
      ),
    }),
    {} as FormValuesType
  );
