import React from 'react';
import { Link } from 'react-router-dom';
import { OpenNewTabType } from '.';
import { copySessionValuesToLocalStorage } from '../../Utilities/StorageHelpers';

export const NewTabLink: React.FC<OpenNewTabType> = ({
  to,
  id,
  rest,
  linkText,
}) => {
  // To keep the session alive in a new tab
  copySessionValuesToLocalStorage([
    { key: 'authToken', name: 'consumerToken' },
    { key: 'clickId' },
    { key: 'appId' },
  ]);

  return (
    <Link
      to={`/documents?resource=${to}`}
      target="_blank"
      data-testid={id}
      rel="noopener noreferrer"
      {...rest}
    >
      {linkText}
    </Link>
  );
};
