import React from 'react';

export const ToyotaInsurance = () => (
  <svg
    width="103"
    height="32"
    viewBox="0 0 103 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M47.0668 5.43942H44.5868V13.3327H42.7734V5.43942H40.2668V3.86609H47.0668V5.43942Z"
      fill="black"
    />
    <path
      d="M50.4137 13.4664C49.7026 13.4664 49.0671 13.3154 48.5071 13.0131C47.9559 12.7109 47.5248 12.2931 47.2137 11.7598C46.9026 11.2264 46.7471 10.6264 46.7471 9.95978C46.7471 9.29311 46.9026 8.69311 47.2137 8.15978C47.5248 7.62644 47.9559 7.20868 48.5071 6.90646C49.0671 6.60423 49.7026 6.45312 50.4137 6.45312C51.1248 6.45312 51.7559 6.60423 52.3071 6.90646C52.8671 7.20868 53.2982 7.62644 53.6004 8.15978C53.9115 8.69311 54.0671 9.29311 54.0671 9.95978C54.0671 10.6264 53.9115 11.2264 53.6004 11.7598C53.2982 12.2931 52.8671 12.7109 52.3071 13.0131C51.7559 13.3154 51.1248 13.4664 50.4137 13.4664ZM50.4137 11.9331C50.9915 11.9331 51.4582 11.7509 51.8137 11.3864C52.1692 11.0131 52.3471 10.5331 52.3471 9.94644C52.3471 9.36869 52.1648 8.8976 51.8004 8.53311C51.4448 8.16869 50.9826 7.98644 50.4137 7.98644C49.8359 7.98644 49.3648 8.16869 49.0004 8.53311C48.6448 8.8976 48.4671 9.37311 48.4671 9.95978C48.4671 10.5464 48.6448 11.022 49.0004 11.3864C49.3648 11.7509 49.8359 11.9331 50.4137 11.9331Z"
      fill="black"
    />
    <path
      d="M61.4841 6.58594L58.5375 14.1726C58.2796 14.8482 57.9463 15.3459 57.5375 15.6659C57.1285 15.9948 56.5996 16.1593 55.9508 16.1593C55.5241 16.1593 55.1508 16.0971 54.8308 15.9726L55.0308 14.5459C55.2263 14.6171 55.4308 14.6526 55.6441 14.6526C55.9641 14.6526 56.2352 14.5771 56.4574 14.4259C56.6885 14.2838 56.8796 14.0348 57.0308 13.6793L57.2841 13.0659L54.5508 6.58594H56.3775L58.1508 11.1726L59.7508 6.58594H61.4841Z"
      fill="black"
    />
    <path
      d="M65.5505 13.4664C64.8393 13.4664 64.2038 13.3154 63.6438 13.0131C63.0926 12.7109 62.6615 12.2931 62.3505 11.7598C62.0393 11.2264 61.8838 10.6264 61.8838 9.95978C61.8838 9.29311 62.0393 8.69311 62.3505 8.15978C62.6615 7.62644 63.0926 7.20868 63.6438 6.90646C64.2038 6.60423 64.8393 6.45312 65.5505 6.45312C66.2615 6.45312 66.8926 6.60423 67.4438 6.90646C68.0038 7.20868 68.4349 7.62644 68.7371 8.15978C69.0482 8.69311 69.2038 9.29311 69.2038 9.95978C69.2038 10.6264 69.0482 11.2264 68.7371 11.7598C68.4349 12.2931 68.0038 12.7109 67.4438 13.0131C66.8926 13.3154 66.2615 13.4664 65.5505 13.4664ZM65.5505 11.9331C66.1282 11.9331 66.5949 11.7509 66.9505 11.3864C67.306 11.0131 67.4838 10.5331 67.4838 9.94644C67.4838 9.36869 67.3015 8.8976 66.9371 8.53311C66.5815 8.16869 66.1193 7.98644 65.5505 7.98644C64.9726 7.98644 64.5015 8.16869 64.1371 8.53311C63.7815 8.8976 63.6038 9.37311 63.6038 9.95978C63.6038 10.5464 63.7815 11.022 64.1371 11.3864C64.5015 11.7509 64.9726 11.9331 65.5505 11.9331Z"
      fill="black"
    />
    <path
      d="M73.2179 13.466C72.5601 13.466 72.049 13.2838 71.6846 12.9194C71.329 12.546 71.1512 11.9905 71.1512 11.2527V7.98602H70.1112V6.58604H71.1512V4.6927L72.8179 4.47937V6.58604H74.6446V7.98602H72.8179V11.026C72.8179 11.6483 73.0846 11.9594 73.6179 11.9594C73.9734 11.9594 74.3601 11.8394 74.7779 11.5994L75.0446 12.946C74.8223 13.0972 74.5512 13.2216 74.2312 13.3194C73.9112 13.4172 73.5734 13.466 73.2179 13.466Z"
      fill="black"
    />
    <path
      d="M79.0651 6.45312C79.9626 6.45312 80.6651 6.6709 81.1718 7.10646C81.6784 7.53313 81.9314 8.19094 81.9314 9.07978V13.3331H80.278V12.3998C80.0563 12.7198 79.7447 12.982 79.3447 13.1864C78.9447 13.382 78.4959 13.4798 77.9984 13.4798C77.3318 13.4798 76.7982 13.302 76.3982 12.9464C76.007 12.582 75.8115 12.0887 75.8115 11.4664C75.8115 10.942 75.967 10.5243 76.2782 10.2131C76.5982 9.89311 77.0826 9.66202 77.7318 9.51978C78.3801 9.36869 79.2251 9.28427 80.2651 9.26644V9.13311C80.2651 8.72427 80.1401 8.40427 79.8918 8.17311C79.6426 7.94202 79.2693 7.82644 78.7718 7.82644C78.0601 7.82644 77.3584 8.06202 76.6649 8.53311L76.2782 7.21312C76.6337 6.9909 77.0514 6.80868 77.5314 6.66646C78.0114 6.52423 78.5226 6.45312 79.0651 6.45312ZM78.598 12.2398C79.0514 12.2398 79.443 12.1154 79.7714 11.8664C80.1005 11.6087 80.2651 11.2354 80.2651 10.7464V10.2931C79.2601 10.3109 78.5538 10.4043 78.1447 10.5731C77.7447 10.7331 77.5451 10.9998 77.5451 11.3731C77.5451 11.6398 77.6335 11.8531 77.8118 12.0131C77.9984 12.1643 78.2605 12.2398 78.598 12.2398Z"
      fill="black"
    />
    <path d="M40.9326 18.3418H42.7325V27.8085H40.9326V18.3418Z" fill="black" />
    <path
      d="M48.9942 20.916C49.7767 20.916 50.3671 21.1427 50.7671 21.596C51.1763 22.0405 51.3805 22.6849 51.3805 23.5293V27.8093H49.7142V24.1693C49.7142 23.5738 49.6029 23.1338 49.3805 22.8493C49.1588 22.5649 48.8075 22.4227 48.3275 22.4227C47.8117 22.4227 47.4033 22.5827 47.1008 22.9027C46.7984 23.2227 46.6475 23.6583 46.6475 24.2093V27.8093H44.9805V21.0627H46.6475V22.1693C46.8875 21.7693 47.2121 21.4627 47.6205 21.2493C48.0296 21.0272 48.4875 20.916 48.9942 20.916Z"
      fill="black"
    />
    <path
      d="M55.5822 27.9427C55.1113 27.9427 54.6443 27.8805 54.1825 27.756C53.7201 27.6316 53.3422 27.4627 53.0488 27.2493L53.4488 25.916C53.7422 26.1472 54.0888 26.3338 54.4888 26.476C54.8888 26.6183 55.2804 26.6894 55.6622 26.6894C55.9738 26.6894 56.2176 26.6316 56.3959 26.516C56.5825 26.4005 56.6755 26.2272 56.6755 25.996C56.6755 25.7738 56.5734 25.6049 56.3692 25.4893C56.1643 25.3738 55.7825 25.2272 55.2225 25.0493C54.7867 24.9072 54.4309 24.7649 54.1559 24.6227C53.8892 24.4716 53.6667 24.2716 53.4892 24.0227C53.3201 23.7738 53.2355 23.4538 53.2355 23.0627C53.2355 22.396 53.4709 21.8716 53.9425 21.4893C54.4225 21.1072 55.058 20.916 55.8488 20.916C56.2488 20.916 56.6405 20.9693 57.0222 21.076C57.4138 21.1827 57.7559 21.3205 58.0492 21.4893L57.7422 22.7693C57.4671 22.6005 57.1555 22.4583 56.8088 22.3427C56.4713 22.2272 56.1422 22.1693 55.8222 22.1693C55.538 22.1693 55.3071 22.2272 55.1288 22.3427C54.9513 22.4493 54.8622 22.6049 54.8622 22.8093C54.8622 23.0227 54.9643 23.1872 55.1692 23.3027C55.3734 23.4183 55.6888 23.5338 56.1155 23.6493C56.8538 23.836 57.4001 24.0849 57.7559 24.396C58.1201 24.6983 58.3022 25.1338 58.3022 25.7027C58.3022 26.3872 58.0622 26.9338 57.5822 27.3427C57.1022 27.7427 56.4355 27.9427 55.5822 27.9427Z"
      fill="black"
    />
    <path
      d="M66.3597 21.0625V27.8092H64.6927V26.7292C64.4527 27.1292 64.1243 27.4358 63.706 27.6492C63.2976 27.8537 62.8306 27.9558 62.3064 27.9558C61.5506 27.9558 60.973 27.7337 60.573 27.2892C60.173 26.8447 59.9727 26.1958 59.9727 25.3425V21.0625H61.6397V24.7025C61.6397 25.307 61.7464 25.7514 61.9597 26.0358C62.1822 26.3203 62.5288 26.4625 62.9997 26.4625C63.5155 26.4625 63.9239 26.3025 64.2264 25.9825C64.5372 25.6537 64.6927 25.2137 64.6927 24.6625V21.0625H66.3597Z"
      fill="black"
    />
    <path
      d="M71.7481 20.916C72.0323 20.916 72.321 20.9693 72.6144 21.076L72.3477 22.676C72.081 22.5872 71.8456 22.5427 71.6414 22.5427C70.6098 22.5427 70.0947 23.236 70.0947 24.6227V27.8093H68.4414V21.0627H70.0947V22.1427C70.4498 21.3249 71.0014 20.916 71.7481 20.916Z"
      fill="black"
    />
    <path
      d="M76.35 20.9287C77.2483 20.9287 77.95 21.1465 78.4567 21.582C78.9633 22.0087 79.2171 22.6665 79.2171 23.5554V27.8087H77.5637V26.8754C77.3412 27.1954 77.0304 27.4576 76.6304 27.662C76.2304 27.8576 75.7809 27.9554 75.2833 27.9554C74.6167 27.9554 74.0833 27.7776 73.6833 27.422C73.2925 27.0576 73.0967 26.5643 73.0967 25.942C73.0967 25.4176 73.2521 24.9999 73.5637 24.6887C73.8837 24.3687 74.3683 24.1376 75.0167 23.9954C75.6658 23.8443 76.51 23.7599 77.55 23.742V23.6087C77.55 23.1999 77.4258 22.8799 77.1767 22.6487C76.9275 22.4176 76.5542 22.302 76.0567 22.302C75.3458 22.302 74.6433 22.5376 73.95 23.0087L73.5637 21.6887C73.9188 21.4665 74.3371 21.2843 74.8171 21.142C75.2971 20.9999 75.8083 20.9287 76.35 20.9287ZM75.8837 26.7154C76.3371 26.7154 76.7279 26.591 77.0571 26.342C77.3854 26.0843 77.55 25.711 77.55 25.222V24.7687C76.5458 24.7865 75.8388 24.8799 75.4304 25.0487C75.0304 25.2087 74.83 25.4754 74.83 25.8487C74.83 26.1154 74.9192 26.3287 75.0967 26.4887C75.2833 26.6399 75.5454 26.7154 75.8837 26.7154Z"
      fill="black"
    />
    <path
      d="M85.2598 20.916C86.0415 20.916 86.6328 21.1427 87.0328 21.596C87.4419 22.0405 87.6461 22.6849 87.6461 23.5293V27.8093H85.9798V24.1693C85.9798 23.5738 85.8686 23.1338 85.6461 22.8493C85.4236 22.5649 85.0731 22.4227 84.5931 22.4227C84.0773 22.4227 83.6682 22.5827 83.3665 22.9027C83.064 23.2227 82.9131 23.6583 82.9131 24.2093V27.8093H81.2461V21.0627H82.9131V22.1693C83.1531 21.7693 83.4769 21.4627 83.8861 21.2493C84.2952 21.0272 84.7531 20.916 85.2598 20.916Z"
      fill="black"
    />
    <path
      d="M92.7407 27.9423C92.0656 27.9423 91.4607 27.7956 90.9273 27.5023C90.394 27.209 89.9765 26.8001 89.674 26.2756C89.3807 25.7423 89.2344 25.1379 89.2344 24.4623C89.2344 23.769 89.3852 23.1556 89.6877 22.6223C89.9986 22.089 90.4207 21.6756 90.954 21.3823C91.4965 21.0801 92.1052 20.929 92.781 20.929C93.6519 20.929 94.4123 21.1468 95.0607 21.5823L94.5677 22.9956C94.0519 22.6223 93.4965 22.4356 92.9007 22.4356C92.3323 22.4356 91.8698 22.6223 91.514 22.9956C91.1589 23.3601 90.9807 23.8401 90.9807 24.4356C90.9807 25.0312 91.1544 25.5156 91.501 25.889C91.8477 26.2534 92.3094 26.4356 92.8877 26.4356C93.1894 26.4356 93.501 26.3823 93.821 26.2756C94.141 26.169 94.4428 26.0268 94.7277 25.849L95.1407 27.2356C94.8298 27.449 94.4694 27.6223 94.061 27.7556C93.6519 27.8801 93.2123 27.9423 92.7407 27.9423Z"
      fill="black"
    />
    <path
      d="M102.609 24.7956H97.6625C97.7158 25.3556 97.92 25.7868 98.2758 26.089C98.64 26.3912 99.12 26.5423 99.7158 26.5423C100.133 26.5423 100.529 26.4801 100.902 26.3556C101.285 26.2223 101.614 26.049 101.889 25.8356L102.302 27.1423C101.973 27.3823 101.573 27.5779 101.102 27.729C100.632 27.8712 100.111 27.9423 99.5421 27.9423C98.8404 27.9423 98.2179 27.7912 97.6754 27.489C97.1337 27.1868 96.7116 26.7734 96.4091 26.249C96.1158 25.7156 95.9688 25.1156 95.9688 24.449C95.9688 23.7645 96.1158 23.1556 96.4091 22.6223C96.7116 22.089 97.12 21.6756 97.6358 21.3823C98.1516 21.0801 98.7291 20.929 99.3691 20.929C100.009 20.929 100.574 21.0801 101.062 21.3823C101.551 21.6756 101.929 22.0979 102.196 22.649C102.472 23.1912 102.609 23.8223 102.609 24.5423V24.7956ZM100.916 23.6623C100.853 23.2356 100.68 22.889 100.395 22.6223C100.12 22.3556 99.7783 22.2223 99.3691 22.2223C98.96 22.2223 98.6049 22.3556 98.3025 22.6223C98.0091 22.8801 97.8133 23.2268 97.7158 23.6623H100.916Z"
      fill="black"
    />
    <path d="M0 0H31.6952V31.6952H0V0Z" fill="#EB0A1E" />
    <mask
      id="mask0_1527_227"
      maskUnits="userSpaceOnUse"
      x="3"
      y="7"
      width="26"
      height="17"
    >
      <path d="M28.0626 7.61914H3.6416V23.6191H28.0626V7.61914Z" fill="white" />
    </mask>
    <g mask="url(#mask0_1527_227)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.0883 22.8048C13.8052 22.0007 12.8632 19.2197 12.8632 15.9059C12.8632 15.7881 12.8632 15.6652 12.8632 15.5474C9.29874 14.9841 6.75279 13.4425 6.66114 11.6191C5.79503 12.6544 5.31116 13.959 5.2914 15.3119C5.2914 19.2606 9.60427 22.4822 15.068 22.7792H15.0934L15.0883 22.8048ZM3.6416 15.6191C3.6416 11.2043 9.1104 7.61914 15.8623 7.61914C22.6143 7.61914 28.0831 11.2043 28.0831 15.6191C28.0831 20.034 22.5939 23.6191 15.8623 23.6191C9.13074 23.6191 3.6416 20.034 3.6416 15.6191ZM15.8623 8.77152C17.1659 8.77152 18.2708 10.7587 18.6833 13.5295C21.2038 13.13 22.9911 12.2286 22.9911 11.1531C22.9911 9.72413 19.7984 8.56665 15.8623 8.56665C11.9262 8.56665 8.73356 9.72413 8.73356 11.1531C8.73356 12.2184 10.5106 13.13 13.0414 13.5295C13.4538 10.7587 14.5689 8.77152 15.8623 8.77152ZM16.6516 22.7997C22.1152 22.518 26.4281 19.2811 26.4281 15.3324C26.3998 13.9756 25.905 12.6707 25.0278 11.6396C24.9617 13.463 22.3902 15.0045 18.8258 15.5679C18.8258 15.6857 18.8258 15.8086 18.8258 15.9264C18.8258 19.235 17.8838 22.0212 16.6006 22.8253H16.6261L16.6516 22.7997ZM15.8623 13.7651C16.4478 13.7651 17.0131 13.7651 17.5528 13.6882C17.2728 11.8906 16.6159 10.6358 15.8623 10.6358C15.1087 10.6358 14.4417 11.8906 14.1718 13.6626C14.7115 13.7139 15.2767 13.7395 15.8623 13.7395V13.7651ZM15.8623 20.3413C16.84 20.3413 17.6445 18.2927 17.6853 15.7318C17.0946 15.783 16.4835 15.8137 15.8572 15.8137C15.2309 15.8137 14.6199 15.783 14.0292 15.7318C14.075 18.2927 14.8694 20.3413 15.8521 20.3413H15.8623Z"
        fill="white"
      />
    </g>
  </svg>
);
